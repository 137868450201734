import React, { useState } from "react";

import Logo from "../assets/images/logo/touchEnOnePass.png";
import ArrowRight from "../assets/images/svg/arrow-right.svg";

import { ModalAppDownload } from "./ModalAppDownload";

export const StoreArea = ({ openSubmitAlert, isMobile }) => {
  const [isShowAppDownload, setIsShowAppDownload] = useState(false);
  const [isStoreType, setIsStoreType] = useState("");
  const storeButtonList = [
    {
      name: "Google Play",
    },
    {
      name: "App Store",
    },
  ];

  const [modalTabs, setModalTabs] = useState([
    {
      name: "Google Play",
      active: true,
      qr: "/img/qr/QR-android.jpg",
    },
    {
      name: "App Store",
      active: false,
      qr: "/img/qr/QR-iOS.jpeg",
    },
  ]);

  const showAppDownloadModal = (name) => {
    console.log("name", name);
    if (isMobile) {
      if (name === 'App Store') {
        window.open('https://apps.apple.com/us/app/onepassfido/id6499198391');
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.dtn.onepassfido.trial&pcampaignid=web_share');
      }
    } else {
      setIsStoreType(name);
      setIsShowAppDownload(true);

      const updatedTabsChange = modalTabs.map((btn) => {
        if (btn.name === name) {
          return { ...btn, active: true };
        } else {
          return { ...btn, active: false };
        }
      });
      setModalTabs(updatedTabsChange);
    }
  };
  const closeAppDownloadModal = () => {
    setIsShowAppDownload(false);
  };
  return (
    <>
      <div id="StoreArea">
        <div className="container">
          <div className="logo">
            <img src={Logo} alt="로고" />
          </div>

          <div className="button-group">
            {storeButtonList.map((btn) => (
              <button
                className="btn-md btn-primary-line"
                onClick={() => showAppDownloadModal(btn.name)}
              >
                {btn.name}
                <i>
                  <img src={ArrowRight} alt="ArrowRight" />
                </i>
              </button>
            ))}
          </div>
        </div>
      </div>

      <ModalAppDownload
        modalTabs={modalTabs}
        setModalTabs={setModalTabs}
        show={isShowAppDownload}
        close={closeAppDownloadModal}
        type={isStoreType}
        openSubmitAlert={openSubmitAlert}
      />
    </>
  );
};
