import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, API } from "../config";
import { ModalDefaultAlert } from "../components/ModalDefaultAlert";

export const ChangePassword = () => {
  const [siteId, setSiteId] = useState('');
  const [svcId, setSvcId] = useState('');
  const [userId, setUserId] = useState('');
  const [requestId, setRequestId] = useState('');

  useEffect(() => {
    const url = window.location.href;
    const queryString = url.split('?')[1];
    const queryParams = new URLSearchParams(queryString);

    setSiteId(queryParams.get('siteId'));
    setSvcId(queryParams.get('svcId'));
    setUserId(queryParams.get('userId'));
    setRequestId(queryParams.get('requestId'));
  }, []);


  const navigate = useNavigate();

  const [inputData, setInputData] = useState({
    newPassword: "",
    reEnterPassword: "",
  });

  const [alertState, setAlertState] = useState({
    isOpen: false,
    title: '',
    content: ''
  });
  const handleOpenModalDefaultAlert = (title, content) => {
    setAlertState({
      isOpen: true,
      title: title,
      content: content
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputData({
      ...inputData,
      [name]: value
    })
    // 입력 값이 비어 있는 경우 해당 필드에 대한 에러 메시지를 설정
    if (value.trim() === '') {
      e.target.closest(".input-item").classList.add("error");
    } else {
      // 입력 값이 비어 있지 않은 경우 해당 필드에 대한 에러 메시지를 제거
      e.target.closest(".input-item").classList.remove("error");
    }
  };
  // 비밀번호 비교 
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const handlePasswordMatch = () => {
    const { newPassword, reEnterPassword } = inputData;
    const match = newPassword === reEnterPassword;
    setPasswordsMatch(match);

    if (!match) {
      document.querySelectorAll(".input-item").forEach((item) => {
        item.classList.add("error");
      });
    } else {
      console.log("Passwords match");
    }
    return match;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestData = {
      siteId: siteId,
      svcId: svcId,
      userId: userId,
      requestId: requestId,
      newPassword: inputData.newPassword
    };

    const passwordsMatch = handlePasswordMatch();
    if (passwordsMatch) {
      fetch(`${API.PASSWORD_RESET}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      })
        .then(response => response.json())
        .then(data => {
          if (data.resultCode == "000") {
            handleOpenModalDefaultAlert("SUCCESS", "your password is successfully changed");
            navigate("/login");
          } else {
            console.log("Result is not successful : " + data.resultCode);
            handleOpenModalDefaultAlert("FAIL", "[" + data.resultCode + "] " + data.resultMsg);
          }
        })
    } else {
      console.log("Passwords do not match");
    }
  }

  return (
    <>
    <main>
      <div id="ChangePassword">
        <div className="container">
          <div className="title">
            <h4>TouchEn OnePass Free Trial LOGIN</h4>
          </div>

          <div className="login-step input-group">
            <h5>Change Your Password</h5>

            <div className="input-item">
              <div className="input">
                <label htmlFor="">New password</label>
                <input type="password" placeholder="Enter your new password" name="newPassword" value={inputData.newPassword} onChange={handleInputChange} />
                <span className="focus-border"></span>
              </div>
            </div>

            <div className="input-item">
              <div className="input">
                <input
                  type="password"
                  placeholder="Re-enter your new password"
                  name="reEnterPassword"
                  value={inputData.reEnterPassword}
                  onChange={handleInputChange}
                />
                <span className="focus-border"></span>
              </div>
              <span className="error-message">Passwords do not match</span>
            </div>
            <div className="button-group flex-col">
              <button className="btn-lg btn-primary-line" onClick={handleSubmit}
              disabled={Object.values(inputData).some(val => val.trim() === '')}
              >
                Change Password
              </button>
            </div>
            <Link to="/login">Other account</Link>
          </div>
        </div>
      </div>
    </main>
    <ModalDefaultAlert
      show={alertState.isOpen}
      close={() => setAlertState({...alertState, isOpen:false})}
      title={alertState.title}
      content={alertState.content}
    />
  </>
  );
};
