import OnepassFido2Logger from "./OnepassFido2Logger";
import {OnepassFido2Util} from "./OnepassFido2Util";
import {ONEPASS_FIDO2_LOG_LEVEL,ONEPASS_FIDO2_SERVER_ADDR,
    REQUEST_REG_REQ_URL,REQUEST_REG_RESP_URL,REQUEST_AUTH_REQ_URL,REQUEST_AUTH_RESP_URL,
    ONEPASS_FIDO_SUCCESS_CODE,ONEPASS_ERROR_INTERNAL,REQUEST_DEREG_REQ_URL,REQUEST_DEREG_RESP_URL,
    ONEPASS_BIZ_REQ_TYPE_REG,ONEPASS_BIZ_REQ_TYPE_AUTH,ONEPASS_BIZ_REQ_TYPE_DEREG,ONEPASS_LANGUAGE_CODE,
    ONEPASS_PROTOCOL_FAMILY_TYPE_UAF,ONEPASS_PROTOCOL_FAMILY_TYPE_FIDO2,ONEPASS_SUCCESS_CODE
} from "./OnepassFido2Const"
import OnepassFido2ErrorCode from "./OnepassFido2ErrorCode";
import $ from "jquery";
import { BASE_URL } from "../config";

export var OnepassFido2 = (() => {
	var onepassServerAddr = ONEPASS_FIDO2_SERVER_ADDR;
	var onepassFIdo2ClientVersion = "1.0.7";
	
	const init = (onepassServerUrl, langCode) => {
		// log level setting
		if( OnepassFido2Util.isEmpty( ONEPASS_FIDO2_LOG_LEVEL ) ) {
			OnepassFido2Logger.init("debug");
		} else {
			OnepassFido2Logger.init(ONEPASS_FIDO2_LOG_LEVEL);
		}
		
		if( OnepassFido2Util.isEmpty( langCode ) ) {
			OnepassFido2ErrorCode.setLangCode(ONEPASS_LANGUAGE_CODE);
		} else {
			OnepassFido2ErrorCode.setLangCode(langCode);
		}
		if( !OnepassFido2Util.isEmpty( onepassServerUrl ) ) {
			onepassServerAddr = onepassServerUrl;
		}
	}
	
	const getOnepassServerAddr = () => {
		return onepassServerAddr;
	}

	const setOnepassServerAddr = (onepassServerUrl) => {
		onepassServerAddr = onepassServerUrl;
	}

	const getOnepassFIdo2ClientVersion = () => {
		return onepassFIdo2ClientVersion;
	}


	// request service - Reg, Auth, Dereg
	const requestService = async(requestParams, resultCallbackFunc) => {
		let reqServiceResult = null;

		OnepassFido2Logger.debug("OnepassFido2", "requestService", "requestParams", requestParams);

		// get params
		let reqOp = requestParams.op.toLowerCase();
		let reqSiteId = requestParams.siteId;
		let reqSvcId = requestParams.svcId;
		let reqUserNm = requestParams.userNm;
		let credentialAlias = requestParams.credentialAlias;
		let svcTrId = OnepassFido2Util.getSvcTrId();

		// let reqUserDisplayNm = requestParams.userDisplayNm;
		// let reqUserIconUrl = requestParams.userIconUrl;

		let reqRpId = OnepassFido2Util.getRpId(document.domain);
		//let reqRpId = "onepassdev.raonsecure.co.kr";

		// let reqRpNm = OnepassFido2Util.isEmpty(requestParams.rpNm) ? "" : requestParams.rpNm;
		let reqProtocolType = "1";

		//인증장치 구성정보 (advanced settings)
		let reqAuthnrAttachment = requestParams.authnrAttachment;

		// params 유효성 체크
		if( OnepassFido2Util.isEmpty(reqOp) || OnepassFido2Util.isEmpty(reqSiteId) || OnepassFido2Util.isEmpty(reqSvcId) ) {
			OnepassFido2Logger.error("OnepassFido2", "requestService", "requestParams empty error");
			let errorObject = OnepassFido2ErrorCode.getError("InvalidParams");
			
			reqServiceResult = {};
			reqServiceResult.statusCode = errorObject.onepassErrCd;
			reqServiceResult.description = errorObject.onepassErrMsg;
			
			resultCallbackFunc(reqServiceResult);
			return;
		}

		// 서비스 요청 params 기본 설정
		let reqOrigin = window.location.origin;
		
		
		let reqUserInfo = {};
		let reqRpInfo = {};
		
		reqUserInfo.name = reqUserNm;
		// reqUserInfo.displayName = reqUserDisplayNm;
		// reqUserInfo.icon = reqUserIconUrl;
		
		// reqRpInfo.name = reqRpNm;
		reqRpInfo.id = reqRpId;

		// operation 수행
		if( reqOp == ONEPASS_BIZ_REQ_TYPE_REG) {
			if( OnepassFido2Util.isEmpty(reqUserNm) ) {
				OnepassFido2Logger.error("OnepassFido2", "requestService", "requestParams empty error");
				let errorObject = OnepassFido2ErrorCode.getError("InvalidParams");
				
				reqServiceResult = {};
				reqServiceResult.statusCode = errorObject.onepassErrCd;
				reqServiceResult.description = errorObject.onepassErrMsg;
				
				resultCallbackFunc(reqServiceResult);
				return;
			}

			reqServiceResult = await requestRegistration(reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo
				, reqAuthnrAttachment, credentialAlias,reqProtocolType, svcTrId);		
		} else if( reqOp == ONEPASS_BIZ_REQ_TYPE_AUTH) {
			reqServiceResult = await requestAuthentication(reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo
				, reqAuthnrAttachment, reqProtocolType, svcTrId);		
		} else if( reqOp == ONEPASS_BIZ_REQ_TYPE_DEREG) {
			reqServiceResult = await requestDeRegistration(reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo
				, reqAuthnrAttachment, reqProtocolType, svcTrId);	
		} else {
			// operation error
			OnepassFido2Logger.error("OnepassFido2", "requestService", "operation error");
			let errorObject = OnepassFido2ErrorCode.getError("InvalidParams");
				
			reqServiceResult = {};
			reqServiceResult.statusCode = errorObject.onepassErrCd;
			reqServiceResult.description = errorObject.onepassErrMsg;
				
			resultCallbackFunc(reqServiceResult);
			return;
		}

		OnepassFido2Logger.debug("OnepassFido2","requestService result data : ", reqServiceResult);
		resultCallbackFunc(reqServiceResult);
		return;
	}

	var strSiteId = "<%=argGlobalSiteId%>";
	var strSvcId = "<%=argGlobalSvcId%>";

	// request registration
	const requestRegistration = async (reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo
		, reqAuthnrAttachment, credentialAlias, reqProtocolType, svcTrId) => {
		let reqRequestResult = null;
		let fido2w3cWebResult = null;
		let reqResponseResult = null;
		
		// request registration
		reqRequestResult = await reqRequestReg(reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo
			, reqAuthnrAttachment, credentialAlias, reqProtocolType, svcTrId);
		
		OnepassFido2Logger.debug("OnepassFido2","requestRegistration", "reqRequsetReg result : " + reqRequestResult);

		// request fido2 w3c web registration
		if( ONEPASS_FIDO_SUCCESS_CODE == reqRequestResult.statusCode ) {
			fido2w3cWebResult = await fido2Register(reqRequestResult);
		} else {
			OnepassFido2Logger.error("OnepassFido2","requestRegistration", "reqRequestResult ERROR : " + reqRequestResult.statusCode);
			return reqRequestResult;
		}
		
		// response registration
		if( ONEPASS_FIDO_SUCCESS_CODE == fido2w3cWebResult.statusCode ) {
			reqResponseResult = await reqResponseReg(reqOrigin, reqRpInfo, reqUserInfo, fido2w3cWebResult.result.credential
				, reqRequestResult.result.challengeToken, reqRequestResult.result.trId);
		} else {
			OnepassFido2Logger.error("OnepassFido2","requestRegistration", "fido2Register-> fido2w3cWebResult ERROR : " + fido2w3cWebResult.statusCode);
			return fido2w3cWebResult;
		}
		
		// registration result
		if( ONEPASS_FIDO_SUCCESS_CODE == reqResponseResult.statusCode ) {
			return reqResponseResult;
		} else {
			OnepassFido2Logger.error("OnepassFido2","requestRegistration", "reqResponseReg ERROR : " + reqResponseResult.statusCode);
			return reqResponseResult;
		}
	}

	// request authentication
	const requestAuthentication = async (reqSiteId, reqSvcId, reqOrigin, reqRpInfo
		, reqUserInfo, reqAuthnrAttachment, reqProtocolType, svcTrId) => {
		let reqRequestResult = null;
		let fido2w3cWebResult = null;
		let reqResponseResult = null;
		
		// request authenticate
		reqRequestResult = await reqRequestAuth(reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo, reqAuthnrAttachment, reqProtocolType, svcTrId);
		
		// request fido2 w3c web authenticate
		if( ONEPASS_FIDO_SUCCESS_CODE == reqRequestResult.statusCode ) {
			fido2w3cWebResult = await fido2Authenticate(reqRequestResult);
		} else {
			OnepassFido2Logger.error("OnepassFido2", "requestAuthentication", "reqRequestAuth ERROR : " + reqRequestResult.statusCode);
			return reqRequestResult;
		}
		
		// response authenticate
		if( ONEPASS_FIDO_SUCCESS_CODE == fido2w3cWebResult.statusCode ) {
			reqResponseResult = await reqResponseAuth(reqOrigin, reqRpInfo, reqUserInfo, fido2w3cWebResult.result.credential
				, reqRequestResult.result.challengeToken, reqRequestResult.result.trId);
		} else {
			OnepassFido2Logger.error("OnepassFido2", "requestAuthentication", "fido2Authenticate-> fido2w3cWebResult ERROR : " + fido2w3cWebResult.statusCode);
			return fido2w3cWebResult;
		}
		
		// authenticate result
		if( ONEPASS_FIDO_SUCCESS_CODE == reqResponseResult.statusCode ) {
			return reqResponseResult;
		} else {
			OnepassFido2Logger.error("OnepassFido2", "requestAuthentication", "reqResponseAuth ERROR : " + reqResponseResult.statusCode);
			return reqResponseResult;
		}
	}

	// request deRegistration
	const requestDeRegistration = async (reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo, reqAuthnrAttachment, reqProtocolType, svcTrId) => {
		let reqRequestResult = null;
		let fido2w3cWebResult = null;
		let reqResponseResult = null;
		
		// request authenticate
		reqRequestResult = await reqRequestDereg(reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo, reqAuthnrAttachment, reqProtocolType, svcTrId);
		
		// request fido2 w3c web authenticate
		if( ONEPASS_FIDO_SUCCESS_CODE == reqRequestResult.statusCode ) {
			fido2w3cWebResult = await fido2Authenticate(reqRequestResult);
		} else {
			OnepassFido2Logger.error("OnepassFido2", "requestDeRegistration", "reqRequestDereg ERROR : " + reqRequestResult.statusCode);
			return reqRequestResult;
		}
		
		// response authenticate

		if( ONEPASS_FIDO_SUCCESS_CODE == fido2w3cWebResult.statusCode ) {
			reqResponseResult = await reqResponseDereg(reqOrigin, reqRpInfo, reqUserInfo, fido2w3cWebResult.result.credential
				, reqRequestResult.result.challengeToken, reqRequestResult.result.trId);
		} else {
			OnepassFido2Logger.error("OnepassFido2", "requestDeRegistration", "fido2Authenticate-> fido2w3cWebResult ERROR : " + fido2w3cWebResult.statusCode);
			return fido2w3cWebResult;
		}
		
		// authenticate result
		if( ONEPASS_FIDO_SUCCESS_CODE == reqResponseResult.statusCode ) {
			return reqResponseResult;
		} else {
			OnepassFido2Logger.error("OnepassFido2", "requestDeRegistration", "reqResponseDereg ERROR : " + reqResponseResult.statusCode);
			return reqResponseResult;
		}
	}
	
	// req reg 
	const reqRequestReg = (reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo
		, reqAuthnrAttachment, credentialAlias, reqProtocolType, svcTrId) => {
		return new Promise((resolve, reject) => {
			let reqUrl = BASE_URL +"/fidoservice/api/fido2/reg/req";
			let query = {
				siteId : reqSiteId
				, svcId : reqSvcId
				, svcTrId : svcTrId
				, origin : reqOrigin
				, user : reqUserInfo
				, rp : reqRpInfo
				, authnrAttachment : reqAuthnrAttachment
				, credentialAlias : credentialAlias
				, reqProtocolType : reqProtocolType
			};
			
			OnepassFido2Logger.debug("OnepassFido2", "reqRequsetReg", "reqUrl : ", reqUrl);
			OnepassFido2Logger.debug("OnepassFido2", "reqRequsetReg", "query : ", query);
			
			try {
				$.ajax({
					url : reqUrl
					, headers : { "Accept": "application/json"}
					, crossDomain : true
					, dataType : "json"
					, method : "POST"
					, contentType : "application/json;charset=utf-8"
					, data : JSON.stringify(query)
					, success : function(data){
						OnepassFido2Logger.debug("OnepassFido2", "reqRequsetReg", "result data : " + JSON.stringify(data));
						let reqResultData = {};

						if( ONEPASS_FIDO_SUCCESS_CODE == data.statusCode ) {
							let obj = JSON.parse(data.result);
							reqResultData.statusCode = data.statusCode;
							reqResultData.result = obj;
						} else {
							OnepassFido2Logger.error("OnepassFido2", "reqRequsetReg", "error : (" + data.statusCode + "): " + data.description);
							reqResultData.statusCode = data.statusCode;
							reqResultData.description = data.description;
						}

						resolve(reqResultData);	
					}, error : function(jqXHR, textStatus, errorThrown){
						OnepassFido2Logger.error("OnepassFido2", "reqRequsetReg", "REG RESPONSE ERROR" + textStatus);
						let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
						let reqResultData = {};
						reqResultData.statusCode = errorObject.onepassErrCd;
						reqResultData.description = errorObject.onepassErrMsg;
						OnepassFido2Logger.debug("OnepassFido2", "reqRequsetReg", "return resultData : ", reqResultData);
						resolve(reqResultData);
					}
				});
			} catch(e) {
				OnepassFido2Logger.error("OnepassFido2", "reqRequsetReg", "AJAX ERROR" + e);
				let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
				let reqResultData = {};
				reqResultData.statusCode = errorObject.onepassErrCd;
				reqResultData.description = errorObject.onepassErrMsg;
				OnepassFido2Logger.debug("OnepassFido2", "reqRequsetReg", "return resultData : ", reqResultData);
				resolve(reqResultData);
			}
		});

	}
// reg resp
	const reqResponseReg = (reqOrigin, reqRpInfo, reqUserInfo, credential, challengeToken, trId) => {
		return new Promise((resolve, reject) => {
			let reqUrl = BASE_URL +"/fidoservice/api/fido2/reg/resp";
			let query = {
				origin : reqOrigin
				, user : reqUserInfo
				, rp : reqRpInfo
				, credential : credential
				, challengeToken : challengeToken
				, trId : trId
			};
			
			OnepassFido2Logger.debug("OnepassFido2", "reqResponseReg", "reqUrl : ", reqUrl);
			OnepassFido2Logger.debug("OnepassFido2", "reqResponseReg", "query : ", query);
			
			try {
				$.ajax({
					url : reqUrl
					, headers : { "Accept": "application/json"}
					, crossDomain : true
					, dataType : "json"
					, method : "POST"
					, contentType : "application/json;charset=utf-8"
					, data : JSON.stringify(query)
					, success : function(data, textStatus, xhr){
						if( ONEPASS_FIDO_SUCCESS_CODE == data.statusCode ) {
							OnepassFido2Logger.debug("OnepassFido2", "reqResponseReg", "result data : " + JSON.stringify(data));	
							let reqResultData = {};
							reqResultData.statusCode = data.statusCode;

							// get onepass json web token
							let jsonWebToken = OnepassFido2Util.getJsonWebToken(xhr.getResponseHeader("Authorization"));
							
							if( jsonWebToken != "" ) {
								var tmpJsonObj = JSON.parse(data.result);
								tmpJsonObj.jsonWebToken = $.trim(jsonWebToken);
								reqResultData.result = JSON.stringify(tmpJsonObj);
							} else {
								reqResultData.result = data.result;
							}

							resolve(reqResultData);
						} else {
							OnepassFido2Logger.error("OnepassFido2", "reqResponseReg", "error : (" + data.statusCode + "): " + data.description);
							resolve(data);
						}
					}, error : function(jqXHR, textStatus, errorThrown){
						OnepassFido2Logger.error("OnepassFido2", "reqResponseReg", "REG RESPONSE ERROR" + textStatus);
						let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
						let reqResultData = {};
						reqResultData.statusCode = errorObject.onepassErrCd;
						reqResultData.description = errorObject.onepassErrMsg;
						OnepassFido2Logger.debug("OnepassFido2", "reqResponseReg", "return resultData : ", reqResultData);
						resolve(reqResultData);
					}
				});
			} catch(e) {
				OnepassFido2Logger.error("OnepassFido2", "reqResponseReg", "AJAX ERROR" + e);
				let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
				let reqResultData = {};
				reqResultData.statusCode = errorObject.onepassErrCd;
				reqResultData.description = errorObject.onepassErrMsg;
				OnepassFido2Logger.debug("OnepassFido2", "reqResponseReg", "return resultData : ", reqResultData);
				resolve(reqResultData);
			}
		});
	}

	// req auth 
	const reqRequestAuth = (reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo, reqAuthnrAttachment, reqProtocolType, svcTrId) => {
		return new Promise((resolve, reject) => {
			let reqUrl = BASE_URL +"/fidoservice/api/fido2/auth/req";
			let query = {
				siteId : reqSiteId
				, svcId : reqSvcId
				, svcTrId : svcTrId
				, origin : reqOrigin
				, user : reqUserInfo
				, rp : reqRpInfo
				, reqProtocolType : reqProtocolType
				, authnrAttachment : reqAuthnrAttachment
			};
			
			OnepassFido2Logger.debug("OnepassFido2", "reqRequestAuth", "query : ", reqUrl);
			OnepassFido2Logger.debug("OnepassFido2", "reqRequestAuth", " query : ", query);
			
			try {
				$.ajax({
					url : reqUrl
					, headers : { "Accept": "application/json"}
					, crossDomain : true
					, dataType : "json"
					, method : "POST"
					, contentType : "application/json;charset=utf-8"
					, data : JSON.stringify(query)
					, success : function(data){
						OnepassFido2Logger.debug("OnepassFido2", "reqRequestAuth", "result data : " + JSON.stringify(data));
						let reqResultData = {};

						if( ONEPASS_FIDO_SUCCESS_CODE == data.statusCode ) {
							let obj = JSON.parse(data.result);
							reqResultData.statusCode = data.statusCode;
							reqResultData.result = obj;
						} else {
							OnepassFido2Logger.error("OnepassFido2", "reqRequestAuth", "error : (" + data.statusCode + "): " + data.description);
							reqResultData.statusCode = data.statusCode;
							reqResultData.description = data.description;
						}
						resolve(reqResultData);	
					}, error : function(jqXHR, textStatus, errorThrown){
						OnepassFido2Logger.error("OnepassFido2", "reqRequestAuth", "AUTH REQUEST ERROR" + textStatus);
						let errorObject = OnepassFido2ErrorCode.getError("InternalError");
						
						let reqResultData = {};
						reqResultData.statusCode = errorObject.onepassErrCd;
						reqResultData.description = errorObject.onepassErrMsg;
						OnepassFido2Logger.debug("OnepassFido2", "reqRequestAuth", "return resultData : ", reqResultData);
						resolve(reqResultData);
					}
				});
			} catch(e) {
				OnepassFido2Logger.error("OnepassFido2", "reqRequestAuth", "AJAX ERROR" + e);
				let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
				let reqResultData = {};
				reqResultData.statusCode = errorObject.onepassErrCd;
				reqResultData.description = errorObject.onepassErrMsg;
				OnepassFido2Logger.debug("OnepassFido2", "reqRequestAuth", "return resultData : ", reqResultData);
				resolve(reqResultData);
			}
		});
	}

	// auth resp 
	const reqResponseAuth = (reqOrigin, reqRpInfo, reqUserInfo, credential, challengeToken, trId) => {
		return new Promise((resolve, reject) => {
			var reqUrl = BASE_URL +"/fidoservice/api/fido2/auth/resp";
			var query = {
				origin : reqOrigin
				, user : reqUserInfo
				, rp : reqRpInfo
				, credential : credential
				, challengeToken : challengeToken
				, trId : trId
			};
			
			OnepassFido2Logger.debug("OnepassFido2", "reqResponseAuth", "reqUrl : ", reqUrl);
			OnepassFido2Logger.debug("OnepassFido2", "reqResponseAuth", "query : ", query);
			
			try {
				$.ajax({
					url : reqUrl
					, headers : { "Accept": "application/json"}
					, crossDomain : true
					, dataType : "json"
					, method : "POST"
					, contentType : "application/json;charset=utf-8"
					, data : JSON.stringify(query)
					, success : function(data, textStatus, xhr){
						if( ONEPASS_FIDO_SUCCESS_CODE == data.statusCode ) {
							OnepassFido2Logger.debug("OnepassFido2", "reqResponseAuth", "result data : " + JSON.stringify(data));	
							let reqResultData = {};
							reqResultData.statusCode = data.statusCode;

							// get onepass json web token
							let jsonWebToken = OnepassFido2Util.getJsonWebToken(xhr.getResponseHeader("Authorization"));
							
							if( jsonWebToken != "" ) {
								var tmpJsonObj = JSON.parse(data.result);
								tmpJsonObj.jsonWebToken = $.trim(jsonWebToken);
								reqResultData.result = JSON.stringify(tmpJsonObj);
							} else {
								reqResultData.result = data.result;
							}

							resolve(reqResultData);
						} else {
							OnepassFido2Logger.error("OnepassFido2", "reqResponseAuth", "error : (" + data.statusCode + "): " + data.description);
							resolve(data);
						}
					}, error : function(jqXHR, textStatus, errorThrown){
						OnepassFido2Logger.error("OnepassFido2", "reqResponseAuth", "AUTH RESPONSE ERROR" + textStatus);
						let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
						let reqResultData = {};
						reqResultData.statusCode = errorObject.onepassErrCd;
						reqResultData.description = errorObject.onepassErrMsg;
						OnepassFido2Logger.debug("OnepassFido2", "reqResponseAuth", "return resultData : ", reqResultData);
						resolve(reqResultData);
					}
				});
			} catch(e) {
				OnepassFido2Logger.error("OnepassFido2", "reqResponseAuth", "AJAX ERROR" + e);
				let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
				let reqResultData = {};
				reqResultData.statusCode = errorObject.onepassErrCd;
				reqResultData.description = errorObject.onepassErrMsg;
				OnepassFido2Logger.debug("OnepassFido2", "reqResponseAuth", "return resultData : ", reqResultData);
				resolve(reqResultData);
			}
		});
	}

	// req dereg 
	const reqRequestDereg = (reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo, reqAuthnrAttachment, reqProtocolType, svcTrId) => {
		return new Promise((resolve, reject) => {
			let reqUrl = BASE_URL +"/fidoservice/api/fido2/dereg/req";
			let query = {
				siteId : reqSiteId
				, svcId : reqSvcId
				, svcTrId : svcTrId
				, origin : reqOrigin
				, user : reqUserInfo
				, rp : reqRpInfo
				, reqProtocolType : reqProtocolType
				, authnrAttachment : reqAuthnrAttachment
			};
			
			OnepassFido2Logger.debug("OnepassFido2", "reqRequestDereg", "query : ", reqUrl);
			OnepassFido2Logger.debug("OnepassFido2", "reqRequestDereg", " query : ", query);
			
			try {
				$.ajax({
					url : reqUrl
					, headers : { "Accept": "application/json"}
					, crossDomain : true
					, dataType : "json"
					, method : "POST"
					, contentType : "application/json;charset=utf-8"
					, data : JSON.stringify(query)
					, success : function(data){
						OnepassFido2Logger.debug("OnepassFido2", "reqRequestDereg", "result data : " + JSON.stringify(data));
						let reqResultData = {};

						if( ONEPASS_FIDO_SUCCESS_CODE == data.statusCode ) {
							let obj = JSON.parse(data.result);
							reqResultData.statusCode = data.statusCode;
							reqResultData.result = obj;
						} else {
							OnepassFido2Logger.error("OnepassFido2", "reqRequestDereg", "error : (" + data.statusCode + "): " + data.description);
							reqResultData.statusCode = data.statusCode;
							reqResultData.description = data.description;
						}

						resolve(reqResultData);	
					}, error : function(jqXHR, textStatus, errorThrown){
						OnepassFido2Logger.error("OnepassFido2", "reqRequestDereg", "DEREG REQUEST ERROR" + textStatus);
						let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
						let reqResultData = {};
						reqResultData.statusCode = errorObject.onepassErrCd;
						reqResultData.description = errorObject.onepassErrMsg;
						OnepassFido2Logger.debug("OnepassFido2", "reqRequestDereg", "return resultData : ", reqResultData);
						resolve(reqResultData);
					}
				});
			} catch(e) {
				OnepassFido2Logger.error("OnepassFido2", "reqRequestDereg", "AJAX ERROR" + e);
				let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
				let reqResultData = {};
				reqResultData.statusCode = errorObject.onepassErrCd;
				reqResultData.description = errorObject.onepassErrMsg;
				OnepassFido2Logger.debug("OnepassFido2", "reqRequestDereg", "return resultData : ", reqResultData);
				resolve(reqResultData);
			}
		});
	}

	// auth dereg
	const reqResponseDereg = (reqOrigin, reqRpInfo, reqUserInfo, credential, challengeToken, trId) => {
		return new Promise((resolve, reject) => {
			let reqUrl = BASE_URL +"/fidoservice/api/fido2/dereg/resp";
			var query = {
				origin : reqOrigin
				, user : reqUserInfo
				, rp : reqRpInfo
				, credential : credential
				, challengeToken : challengeToken
				, trId : trId
			};
			
			OnepassFido2Logger.debug("OnepassFido2", "reqResponseDereg", "reqUrl : ", reqUrl);
			OnepassFido2Logger.debug("OnepassFido2", "reqResponseDereg", "query : ", query);
			
			try {
				$.ajax({
					url : reqUrl
					, headers : { "Accept": "application/json"}
					, crossDomain : true
					, dataType : "json"
					, method : "POST"
					, contentType : "application/json;charset=utf-8"
					, data : JSON.stringify(query)
					, success : function(data, textStatus, xhr){
						if( ONEPASS_FIDO_SUCCESS_CODE == data.statusCode ) {
							OnepassFido2Logger.debug("OnepassFido2", "reqResponseDereg", "result data : " + JSON.stringify(data));	
							let reqResultData = {};
							reqResultData.statusCode = data.statusCode;

							// get onepass json web token
							let jsonWebToken = OnepassFido2Util.getJsonWebToken(xhr.getResponseHeader("Authorization"));
							
							if( jsonWebToken != "" ) {
								var tmpJsonObj = JSON.parse(data.result);
								tmpJsonObj.jsonWebToken = $.trim(jsonWebToken);
								reqResultData.result = JSON.stringify(tmpJsonObj);
							} else {
								reqResultData.result = data.result;
							}

							resolve(reqResultData);
						} else {
							OnepassFido2Logger.error("OnepassFido2", "reqResponseDereg", "error : (" + data.statusCode + "): " + data.description);
							resolve(data);
						}
					}, error : function(jqXHR, textStatus, errorThrown){
						OnepassFido2Logger.error("OnepassFido2", "reqResponseDereg", "DEREG RESPONSE ERROR" + textStatus);
						let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
						let reqResultData = {};
						reqResultData.statusCode = errorObject.onepassErrCd;
						reqResultData.description = errorObject.onepassErrMsg;
						OnepassFido2Logger.debug("OnepassFido2", "reqResponseDereg", "return resultData : ", reqResultData);
						resolve(reqResultData);
					}
				});
			} catch(e) {
				OnepassFido2Logger.error("OnepassFido2", "reqResponseDereg", "AJAX ERROR" + e);
				let errorObject = OnepassFido2ErrorCode.getError("InternalError");
			
				let reqResultData = {};
				reqResultData.statusCode = errorObject.onepassErrCd;
				reqResultData.description = errorObject.onepassErrMsg;
				OnepassFido2Logger.debug("OnepassFido2", "reqResponseDereg", "return resultData : ", reqResultData);
				resolve(reqResultData);
			}
		});
	}


	///////////////////////////////////////////////////////////////////////////////////////////
	// FIDO2 W3C Web Operation
	///////////////////////////////////////////////////////////////////////////////////////////
	const fido2Register = (requestParams) => {
		return new Promise((resolve, reject) => {
			let reqResultData = {};
			
			try {
				reqResultData.statusCode = ONEPASS_FIDO_SUCCESS_CODE;
				
				let paramData = requestParams.result.publicKey;
				
				// PublicKeyCredentialCreationOptions
				let options = {};
				
				// required
				options.rp = paramData.rp;
				options.rp.id = OnepassFido2Util.getRpId(document.domain);
				options.user = paramData.user;
				options.user.id = OnepassFido2Util.base64url_decode(paramData.user.id);
				options.challenge = OnepassFido2Util.base64url_decode(paramData.challenge);
				options.pubKeyCredParams = paramData.pubKeyCredParams;
				
				options.timeout = paramData.timeout;		    
// authenticatorAttachment, residentKey, requireResidentKey, UserVerification, attestation
				options.authenticatorSelection = paramData.authenticatorSelection;
				options.attestation = paramData.attestation;
				options.extensions = paramData.extensions; // +

				if(paramData.excludeCredentials != undefined){
					options.excludeCredentials = paramData.excludeCredentials; // +				
					let i=0;
					for(i=0; i<paramData.excludeCredentials.length; i++) {		
						options.excludeCredentials[i].id = OnepassFido2Util.base64url_decode(paramData.excludeCredentials[i].id);	    	
					}	
				}
				if(paramData.extensions != undefined && paramData.extensions.authnSel != undefined){		
					let i=0;
					for(i=0; i<paramData.extensions.authnSel.length; i++) {		
						options.extensions.authnSel[i] = OnepassFido2Util.base64url_decode(paramData.extensions.authnSel[i]);	    	
					}	
				}
				
				OnepassFido2Logger.debug("OnepassFido2", "fido2Register webAuth API", "create options : " + JSON.stringify(options));
				
				// W3C Web API
				navigator.credentials.create({"publicKey": options})
				.then((result) => {
					
					OnepassFido2Logger.debug("OnepassFido2", "fido2Register webAuth API", "create result from Agent : \n" + OnepassFido2Util.objToString(result));
					
					// PublicKeyCredential
					let credential = {};
					if ('id' in result) {
						credential.id = result.id;
					}
					if ('type' in result) {
						credential.type = result.type;
					}
					if ('rawId' in result) {
						credential.rawId = btoa(
								new Uint8Array(result.rawId).reduce((s, byte) =>
								s + String.fromCharCode(byte), ''));
					}
					if ('response' in result) {
						let response = {};		    		
						response.clientDataJSON = OnepassFido2Util.base64url_encode(result.response.clientDataJSON);		    		
						response.attestationObject = OnepassFido2Util.base64url_encode(result.response.attestationObject);
						
						credential.response = response;
						OnepassFido2Logger.debug("OnepassFido2", "fido2Register webAuth API", "create result for RP : \n" + JSON.stringify(credential));
					
						// Send new credential back to Relying Party for validation and store
						reqResultData.result = {};
						reqResultData.result.credential = credential;
						
						resolve(reqResultData);
					} else {
						let errM = "Make Credential response lacking 'response' attribute";
						OnepassFido2Logger.error("OnepassFido2", "fido2Register webAuth API", errM);
						reqResultData.statusCode = ONEPASS_ERROR_INTERNAL;
						reqResultData.description = errM;
						resolve(reqResultData);
					}
				}).catch((err) => {
					let errM = "An error occurred during navigater.credentials.create() [" + err.toString() + "]";
					OnepassFido2Logger.error("OnepassFido2", "fido2Register webAuth API", errM);
					let errorObject = OnepassFido2ErrorCode.getError(err.name);
					reqResultData.statusCode = errorObject.onepassErrCd;
					reqResultData.description = errorObject.onepassErrMsg;
					resolve(reqResultData);
				});
				
			} catch (ex) {
				let errM = "An error occured during Register Parameters";
				OnepassFido2Logger.error("", ex);
				OnepassFido2Logger.error("OnepassFido2", "fido2Register webAuth API", errM);
				reqResultData.statusCode = ONEPASS_ERROR_INTERNAL;
				reqResultData.description = errM;
				resolve(reqResultData);
			}
		});
	}
	const fido2Authenticate = (requestParams) => {
		return new Promise((resolve, reject) => {
			let reqResultData = {};
		
			try {
				reqResultData.statusCode = ONEPASS_FIDO_SUCCESS_CODE;
				
				let paramData = requestParams.result.publicKey;
				
				// PublicKeyCredentialRequestOptions
				let options = {};
				
				// required
				options.challenge = OnepassFido2Util.base64url_decode(paramData.challenge);
				
				// optional
				options.timeout = paramData.timeout;	
				options.rpId = paramData.rpId;	    
				options.userVerification = paramData.userVerification;
				options.extensions = paramData.extensions;
				
				// base64url to byte array
				if(paramData.allowCredentials != undefined){
					options.allowCredentials = paramData.allowCredentials;
					let i=0;
					for(i=0; i<paramData.allowCredentials.length; i++) {		
						options.allowCredentials[i].id = OnepassFido2Util.base64url_decode(paramData.allowCredentials[i].id);	    	
					}	
				}
				if(paramData.extensions.txAuthGeneric != undefined
						&& paramData.extensions.txAuthGeneric.content != undefined){	
					OnepassFido2Logger.debug("OnepassFido2", "fido2Authenticate webAuth API", "txAuthGeneric content = " + paramData.extensions.txAuthGeneric.content);
					options.extensions.txAuthGeneric.content = OnepassFido2Util.base64url_decode(paramData.extensions.txAuthGeneric.content);			    
				}
				
			   OnepassFido2Logger.debug("OnepassFido2", "fido2Authenticate webAuth API", "get options : " + JSON.stringify(options));
				
				// W3C Web API
				navigator.credentials.get({"publicKey": options})
				  .then((result) => {
						  
					  OnepassFido2Logger.debug("OnepassFido2", "fido2Authenticate webAuth API", "get result from Agent : \n" + OnepassFido2Util.objToString(result));
					  
					  // PublicKeyCredential
					  let credential = {};
					  if ('id' in result) {
						  credential.id = result.id;
					  }
					  if ('type' in result) {
						  credential.type = result.type;
					  }
					  if ('rawId' in result) {
						  credential.rawId = btoa(new Uint8Array(result.rawId).reduce((s, byte) =>
							s + String.fromCharCode(byte), ''));
						  credential.rawId = result.rawId;
					  }
					  if ('response' in result) {
							let response = {};
							response.clientDataJSON = OnepassFido2Util.base64url_encode(result.response.clientDataJSON);
							response.authenticatorData = OnepassFido2Util.base64url_encode(result.response.authenticatorData);
							response.signature = OnepassFido2Util.base64url_encode(result.response.signature);
							response.userHandle = OnepassFido2Util.base64url_encode(result.response.userHandle);
							
							// filter
							for(let key in response) {
								if(response[key].length == 0)
									delete response[key];
							}		    	  	
							
							credential.response = response;
							OnepassFido2Logger.debug("OnepassFido2", "fido2Authenticate webAuth API", "get result for RP : \n" + JSON.stringify(credential));
							
							// Send new credential back to Relying Party for validation and store
							reqResultData.result = {};
							reqResultData.result.credential = credential;
							
							resolve(reqResultData);
					  } else {
						let errM = "Make Credential response lacking 'response' attribute";
						OnepassFido2Logger.error("OnepassFido2", "fido2Authenticate webAuth API", errM);
						reqResultData.statusCode = ONEPASS_ERROR_INTERNAL;
						reqResultData.description = errM;
						resolve(reqResultData);
					}
				}).catch(function (err) {
					let errM = "An error occurred during Assertion request [" + err.toString() + "]";
					OnepassFido2Logger.error("OnepassFido2", "fido2Authenticate webAuth API", errM);
					
					let errorObject = OnepassFido2ErrorCode.getError(err.name);
					reqResultData.statusCode = errorObject.onepassErrCd;
					reqResultData.description = errorObject.onepassErrMsg;
					resolve(reqResultData);
				});
			} catch (ex) {
				let errM = "An error occured during Authenticate Parameters";
				OnepassFido2Logger.error("OnepassFido2", "fido2Authenticate webAuth API", ex);
				OnepassFido2Logger.error("OnepassFido2", "fido2Authenticate webAuth API", errM);
				reqResultData.statusCode = ONEPASS_ERROR_INTERNAL;
				reqResultData.description = errM;
				resolve(reqResultData);
			}
		});
	}


	return {
		init : init
		, getOnepassServerAddr : getOnepassServerAddr
		, setOnepassServerAddr : setOnepassServerAddr
		, getOnepassFIdo2ClientVersion : getOnepassFIdo2ClientVersion
		, requestService : requestService
	};
})();
export default OnepassFido2;