import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { ModalDefaultAlert } from "../components/ModalDefaultAlert";
import { API } from "../config";
import $ from "jquery";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";

export const ModalChangePassword = ({ show, close }) => {
  const [sessionMember, setSessionMember] = useState(JSON.parse(sessionStorage.getItem("member")));
  const [errorMessage, setErrorMessage] = useState('Failed to change the password.');
  const [NewErrorMessage, setNewErrorMessage] = useState('Please enter your new password.')
  const [CurrentErrorMessage, setCurrentErrorMessage] = useState('Please enter your current password.');
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();

  const [alertState, setAlertState] = useState({
    isOpen: false,
    title: '',
    content: ''
  });
  const handleOpenModalDefaultAlert = (title, content) => {
    setAlertState({
      isOpen: true,
      title: title,
      content: content
    });
  };

  const closeModal = () => {
    if(!isComplete){
      $("#DialogChangePassword").removeClass("open");
    } else if(isComplete) {
      sessionStorage.clear();
      close();
      navigate("/");
    } else {
      close();
    }
  };

  useEffect(() => {
    setInputData({
      currentPassword: '',
      newPassword: '',
      reEnterNewPassword: ''
    });
  }, [show]);

  //input text
  const [inputData, setInputData] = useState({
    currentPassword: '',
    newPassword: '',
    reEnterNewPassword: ''
  });

  const setSession = () => {
    const member = JSON.parse(sessionStorage.getItem("member"));
    setSessionMember(member);
  }

  useEffect(() => {
    setSession()
  }, [])
  // useEffect(() => {
  //   if (sessionMember) {
  //     handleChangePassword();
  //   }
  // }, [sessionMember]);


  const handleChangePassword = async () => {
    const sessionMember = JSON.parse(sessionStorage.getItem("member"));
    const { currentPassword, newPassword, reEnterNewPassword } = inputData;

    // 입력한 새로운 비밀번호와 다시 입력한 새로운 비밀번호가 일치하는지 확인
    if (newPassword !== reEnterNewPassword) {
      handleOpenModalDefaultAlert("PASSWORD NOT CHANGED", "The new password and the re-entered new password do not match.");
      return;
    }
    fetch(`${API.CHANGEPASSWORD}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        siteId: sessionMember.siteId,
        svcId: sessionMember.svcId,
        userId: sessionMember.userId,
        currentPassword,
        newPassword,
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.state}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.resultCode === "217") {
          handleOpenModalDefaultAlert("FAIL", "[" + data.resultCode + "] \n\n" + data.resultMsg);
        } else {
          handleOpenModalDefaultAlert("SUCCESS", "[" + data.resultCode + "] password changed successfully");
          setIsComplete(true);
          closeModal();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

   // InputData onChange
    const handleInputChange = (e) => {
      const {name,value} = e.target;
  
      setInputData({
        ...inputData,
        [name]:value
      });
  
      // 입력 값이 비어 있는 경우 해당 필드에 대한 에러 메시지를 설정
      if (value.trim() === '') {
       e.target.closest(".input-item").classList.add("error");
      } else {
        e.target.closest(".input-item").classList.remove("error");
      }
    }
  return (
    <main>
    <div id="DialogChangePassword" className={`modal ${show ? "open" : ""}`}>
      <div className="modal-dialog">
        <button className="gnb gnb-close pc-only" onClick={close}>
          <img src={GnbClose} alt="닫기" />
        </button>

        <button className="gnb gnb-close mo-only" onClick={close}>
          <img src={GnbCloseWhite} alt="닫기" />
        </button>

        <div className="modal-dialog-inner">
          <div className="modal-header">
            <h5>Change password</h5>
          </div>

          <span className="line"></span>

          <div className="modal-body">
            <div className="text-group">
              <p>Enter your current password and new password.</p>
            </div>

            <div className="input-group">
              <div className="input-item">
                <div className="input">
                  <label>Current password</label>
                  <input
                    type="password"
                    placeholder="Enter your current password"
                    name="currentPassword"
                    value={inputData.currentPassword}
                    onChange={handleInputChange}

                  />
                  <span className="focus-border"></span>
                </div>
                <span className="error-message">{CurrentErrorMessage}</span>
              </div>

              <div className="input-item">
                <div className="input">
                  <label>New password</label>
                  <input
                    type="password"
                    placeholder="Enter your new password"
                    name="newPassword"
                    value={inputData.newPassword}
                    onChange={handleInputChange}

                  />
                  <span className="focus-border"></span>
                </div>
                <span className="error-message">{NewErrorMessage}</span>
              </div>

              <div className="input-item">
                <div className="input">
                <label>re-enter password</label>
                  <input
                    type="password"
                    placeholder="Re-enter your new password"
                    name="reEnterNewPassword"
                    value={inputData.reEnterNewPassword}
                    onChange={handleInputChange}

                  />
                  <span className="focus-border"></span>
                </div>
                <span className="error-message">{errorMessage}</span>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <div className="button-group">
              <button className="btn-lg btn-primary-fill"
               disabled={Object.values(inputData).some(val => val.trim() === '')}
               onClick={handleChangePassword}
               >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalDefaultAlert
        show={alertState.isOpen}
        close={() => setAlertState({...alertState, isOpen:false, closeModal})}
        title={alertState.title}
        content={alertState.content}
      />
    </main>
  );
};
