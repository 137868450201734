import React, { useState } from "react";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { SideMenu } from "../components/SideMenu";

import { ModalChangePassword } from "../components/ModalChangePassword";
import { ModalPrivacyPolicy } from "../components/ModalPrivacyPolicy";
import { ModalTermsOfService } from "../components/ModalTermsOfService";

export const AppLayout = ({ children, isMobile }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isShowSideMenu, setIsShowSideMenu] = useState(false);
  const [isShowChangePasswordModal, setIsShowChangePasswordModal] =
    useState(false);
  const [isShowPrivacyModal, setIsShowPrivacyModal] = useState(false);
  const [isShowTermsModal, setIsShowTermsModal] = useState(false);

  const testAuth = () => {
    setIsAuth(!isAuth);
  };

  const showSideMenu = () => {
    setIsShowSideMenu(true);
  };

  const closeSideMenu = () => {
    setIsShowSideMenu(false);
  };

  const showChangePasswordModal = () => {
    setIsShowChangePasswordModal(true);
  };
  const closeChangePasswordModal = () => {
    window.location.reload();
    setIsShowChangePasswordModal(false);
  };
  const showPrivacyModal = () => {
    setIsShowPrivacyModal(true);
  };
  const closePrivacyModal = () => {
    setIsShowPrivacyModal(false);
  };
  const showTermsModal = () => {
    setIsShowTermsModal(true);
  };
  const closeTermsModal = () => {
    setIsShowTermsModal(false);
  };

  return (
    <>
      <Header
        isMobile={isMobile}
        show={showChangePasswordModal}
        isAuth={isAuth}
        testAuth={testAuth}
        showSideMenu={showSideMenu}
      />
      <SideMenu
        isAuth={isAuth}
        testAuth={testAuth}
        show={isShowSideMenu}
        close={closeSideMenu}
        showPassword={showChangePasswordModal}
      />
      {children}
      <ModalChangePassword
        show={isShowChangePasswordModal}
        close={closeChangePasswordModal}
      />
      <ModalPrivacyPolicy show={isShowPrivacyModal} close={closePrivacyModal} />
      <ModalTermsOfService show={isShowTermsModal} close={closeTermsModal} />
      <Footer isMobile={isMobile} showPrivacy={showPrivacyModal} showTerms={showTermsModal}/>
    </>
  );
};
