import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DepthMenu } from "../components/DepthMenu";
import { DepthContent } from "../components/DepthContent";
import { DOCUMENTS_ITEMS } from "../documents/documents";
export const Documentation = ({ showLineNumbers, isMobile }) => {
  const params = useParams();  
  const navigate = useNavigate();
  const handleItemClick = (item) => {
      navigate(`/documentation/${item.id}`);
  };

  return (
    <>
      <main>
        <div id="Documentation">
          <div className="container">
            <div className="title">
            {DOCUMENTS_ITEMS.map((main, idx) => (
              main.id.toString() === params.id && (
                <h4>
                  {main.name}
                </h4>
              )
            ))}
            </div>
            <div className="container-inner">
              <div className="document-menu">
                <DepthMenu
                  items={DOCUMENTS_ITEMS}
                  params={params}
                  handleItemClick={handleItemClick}
                />
              </div>
              <div className="document-content">
                <DepthContent 
                  items={DOCUMENTS_ITEMS}
                  params={params}
                  handleItemClick={handleItemClick}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
