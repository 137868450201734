// config.js 
export const BASE_URL = process.env.REACT_APP_SERVER_URL;

export const ONEPASS_ADMIN_URL = `https://onepassdev.raonsecure.co.kr:28444`;

export const API = {
    COUNTRIES_CODE :`${BASE_URL}/freetrial/user/countries`,
    UAF : `${BASE_URL}/fidoservice/api/uaf`,
    DEMO_REQUEST : `${BASE_URL}/freetrial/demo/request`,
    DEMO_CONFIRM :  `${BASE_URL}/freetrial/demo/confirm`,
    CHANGEPASSWORD : `${BASE_URL}/freetrial/user/changePassword`,
    PASSWORD_RESET_LINK : `${BASE_URL}/freetrial/user/password/reset/link/request`,
    PASSWORD_RESET : `${BASE_URL}/freetrial/user/password/reset`,
    REGISTRATION : `${BASE_URL}/freetrial/user/registration/request`,
    REGIST_CHECK : `${BASE_URL}/freetrial/user/regist/check`,
    LOGIN :`${BASE_URL}/freetrial/user/login`
}

export const SITE_ID = "";
export const SVC_ID = "";

export const DEMO_SITE_ID = "00004";
export const DEMO_SVC_ID = "004";


export const OS_VERSION = {
    OS_WINDOW : '1',
    OS_MAC : '2',
    OS_ANDROID : '3',
    OS_IOS : '4',
    OS_ETC : '5'
}

export const AAID = {
    Biometrics : '2',
    PIN : '4',
    Pattern : '128'
}