import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { ChangePassword } from "../pages/ChangePassword";
import { Documentation } from "../pages/Documentation";
import { Authenticators } from "../pages/Authenticators";

export const AppRouter = ({ isMobile }) => {
  return (
    <Routes>
      <Route path="/" element={<Home isMobile={isMobile} />} />
      <Route path="/login" element={<Login isMobile={isMobile} />} />
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/documentation/:id" element={<Documentation isMobile={isMobile}/>}/>
      <Route
        path="/authenticators"
        element={<Authenticators isMobile={isMobile} />}
      />
    </Routes>
  );
};
