import React from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";

export const ModalDefaultAlert = ({ show, close, title, content }) => {
  return (
    <div id="DialogDefaultAlert" className={`modal ${show ? "open" : ""}`}>
      <div className="modal-dialog">
        <button className="gnb gnb-close pc-only" onClick={close}>
          <img src={GnbClose} alt="닫기" />
        </button>

        <button className="gnb gnb-close mo-only" onClick={close}>
          <img src={GnbCloseWhite} alt="닫기" />
        </button>

        <div className="modal-dialog-inner">
          <div className="modal-header">
            <h5>{title}</h5>
          </div>

          <div className="modal-body">
            <div className="text-group">
              <p>
                {content}
              </p>
            </div>
          </div>

          <div className="modal-footer">
            <div className="button-group" onClick={close}>
              <button className="btn-lg btn-primary-fill">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
