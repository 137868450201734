import React from 'react';
import { CodeBlock, nord } from "react-code-blocks";
import { Table } from 'antd';
import { admin_columns_1_1_1, admin_columns_1_1_1_2, admin_columns_1_2, admin_columns_2_1, admin_table_1_1_1, admin_table_1_1_1_2, admin_table_1_2,
   admin_table_2_1, admin_columns_2_2_1, admin_table_2_2_1, admin_table_2_2_2, admin_columns_2_2_2,
  admin_table_2_3_1_1_reg,admin_columns_2_3_1_1_reg, admin_table_2_3_1_1_dereg, admin_columns_2_3_1_1_dereg, admin_table_2_3_1_1_secondary, admin_columns_2_3_1_1_secondary,
  admin_table_2_3_1_1_primary, admin_columns_2_3_1_1_primary, 
  admin_table_2_3_1_1_lock,
  admin_columns_2_3_1_1_lock,
  admin_table_2_3_1_1_allow,
  admin_columns_2_3_1_1_allow,
  admin_table_2_3_2_1,
  admin_columns_2_3_2_1,
  admin_table_2_3_1_2,
  admin_columns_2_3_1_2,
  admin_table_2_3_1_2_dereg,
  admin_columns_2_3_1_2_dereg,
  admin_table_2_3_1_2_primary,
  admin_columns_2_3_1_2_primary,
  admin_table_2_3_1_2_allow,
  admin_columns_2_3_1_2_allow,
  admin_table_2_3_1_2_option,
  admin_columns_2_3_1_2_option,
  admin_table_2_3_1_3,
  admin_columns_2_3_1_3,
  admin_table_2_3_1_3_lock,
  admin_columns_2_3_1_3_lock,
  admin_table_2_3_1_3_auth,
  admin_columns_2_3_1_3_auth,
  admin_columns_4_1_1_5,
  admin_table_4_1_1_5,
  admin_table_4_1_2_2,
  admin_table_4_2_2,
  admin_table_4_2_3_2,
  admin_table_5_1_1_1,
  admin_table_5_2_1_1,
  admin_columns_5_1_1_1,
  admin_columns_5_2_1_1
} from './adminApiTable';
import { REQEST_DATA_TABLE_COLUMN, RESPONSE_DATA_TABLE_COLUMN, Table_4_2_1_3, Table_4_2_1_4, Table_4_2_2_3, Table_4_2_3_3, Table_4_2_4_2, Table_4_2_4_3, Table_4_2_5_2, Table_4_2_5_3, Table_4_2_6_2, Table_4_2_6_3 } from "./serverApiTable";
import { passkey_columns_1_4, passkey_columns_1_5, passkey_columns_2_2, passkey_columns_2_2_res, passkey_columns_auth, passkey_columns_auth_req_2, passkey_columns_auth_res, passkey_columns_auth_res_3_2, passkey_columns_deReg_req, passkey_columns_dereg_req_2, passkey_table_1_4, passkey_table_1_5, passkey_table_2_2, passkey_table_2_2_res, passkey_table_auth, passkey_table_auth_req_2, passkey_table_auth_res, passkey_table_auth_res_3_2, passkey_table_deReg_req, passkey_table_dereg_req_2 } from './passkeyApiTable';
export const tableData = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'command',
      type: 'String',
      size: '32(max)',
      mo: 'M',
      definition: 'requestServiceAuth',
      description: 'Request type',
    },
    {
      key: '2',
      nodeDepth: '1',
      field: 'bizReqType',
      type: 'String',
      size: '5(max)',
      mo: 'M',
      definition: 'app / push / qr',
      description: 'Request type (APP/PC)',
    },
    {
      key: '3',
      nodeDepth: '1',
      field: 'svcTrId',
      type: 'String',
      size: '20',
      mo: 'M',
      definition: 'Service Transaction ID',
      description: 'Transaction ID created by service server',
    },
  ];
  
  export const tableColumns = [
    {
      title: 'Node Depth',
      dataIndex: 'nodeDepth',
      key: 'nodeDepth',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'M/O',
      dataIndex: 'mo',
      key: 'mo',
    },
    {
      title: 'Definition',
      dataIndex: 'definition',
      key: 'definition',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  export const CodeComponent = (code, language) => (
    <CodeBlock
      text={code}
      theme={nord}
      language={language}
      style={{ width: '100px' }}
    />
  );

export const TableComponent = (tableData, tableColumns) => (
    <Table 
        dataSource={tableData} 
        columns={tableColumns} 
        rowClassName='doc-table' 
        pagination={false}
        bordered
        style={{ overflowX: 'scroll' }}
    />
);

export const DOCUMENTS_ITEMS= [
    // {
    //   id: 1,
    //   name: "Introduction",
    //   isDropdown: true,
    //   isDropdownOpen: false,
    //   isActive: false,
    // },
    // {
    //   id: 2,
    //   name: "Demo",
    //   isDropdown: true,
    //   isDropdownOpen: false,
    //   isActive: false,
    // },
    {
      id: 1,
      name: "Admin Guide",
      type: "depth1",
      isDropdown: true,
      isDropdownOpen: false,
      isActive: false,
      children: [
        {
          id: 1,
          name: "1. Outline",
          type: "depth2",
          isDropdown: true,
          isDropdownOpen: false,
          content : 
            <>
              <p>
              TouchEn OnePass is an authentication solution which follows the standard specification of FIDO Alliance. The product is applied to various online services including online banking, simple payment, game and portal for much secured and safer user authentication functions.
              </p>
            </>
          ,
          children: [
            {
              id: 1,
              name: "1.1. Specification",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              children: [
                {
                  id: 1,
                  name: "1.1.1. Server",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <p>● Software</p>
                      {TableComponent(admin_table_1_1_1,admin_columns_1_1_1)}
                      <p>● Hardware</p>
                      {TableComponent(admin_table_1_1_1_2,admin_columns_1_1_1_2)}
                    </>
                  ,
                },
              ],
            },
            {
              id: 2,
              name: "1.2. Terminology",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content: 
                <>
                  <p>The definitions of terms used in this document are as follows.</p>
                  {TableComponent(admin_table_1_2, admin_columns_1_2)}
                </>
              ,
            },
          ],
        },
        {
          id: 2,
          name: "2. How to Start",
          type: "depth2",
          isDropdown: true,
          isDropdownOpen: false,
          content:
            <>
              <p>This manual provides the effective way of using TouchEn OnePass Admin Console.We recommend Admin to understand the manual prior to using the product. We provide the management console for effectively managing TouchEn OnePass.  You should succeed in identification and authentication through the webUI to use it.  Recommend to use Chrome for accessing to Admin page.</p>
            </>
          ,
          children: [
            {
              id: 1,
              name: "2.1. Menu",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content: 
                <>
                  <p>TouchEn OnePass Trial Admin page consists of the following.</p>
                  {TableComponent(admin_table_2_1, admin_columns_2_1)}
                </>
              ,
            },
            {
              id: 2,
              name: "2.2. Admin Login",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content:
                <>
                  <p>
                    Installation personnel creates an Admin account and password. The Admin has to change the password when log-in the first time and then conducts authentication with the new password.
                  </p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "2.2.1. Login",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content:
                    <>
                      <p>Admin opens the management console for log-in. - [Wallpaper] &gt; Open the ‘Chrome’ - URL: https://domain:portnumber</p>
                      <img src="/img/document/admin_2.2.1.png" alt="" style={{ maxWidth: "800px" }} />
                      <div className='figure'>[Figure 1] Log-in Page</div>
                      {TableComponent(admin_table_2_2_1, admin_columns_2_2_1)}
                      <p>
                        The Admin can set the login retry count with the allowed range from 1 to 20. If the login retry count exceeds the allowed range, you are restricted from log-in for a certain period of time or the account will be locked depending on the settings.
                        <br/>
                        Here’s the default value of route Admin set up by the installation personnel.
                        <ul>
                          <li>Maximum login retry count: 3</li>
                          <li>If exceeds the maximum login retry count: Account lock (5 minutes)</li>
                        </ul>
                        If identification and authentication is executed, home menu will be on the screen.
                      </p>
                    </>
                },
                {
                  id: 2,
                  name: "2.2.2. Change Password",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: (
                    <>
                      <p>
                        When logging in for the first time, the Admin must change their account password to enhance security.
                        Please change your password regularly for security purposes.
                        The Admin can set the login retry count with the allowed range from 1 to 20. If the login retry count exceeds the allowed range, you are restricted from log-in for a certain period of time or the account will be locked depending on the settings.
                        <ul>
                          <li>[Account and Authority] - [Account Management] – select the Admin – Click the ‘OK’ button</li>
                          <li>Change the password and set up the expiration date policy in the Account Information menu.</li>
                        </ul>
                        Here’s password combination rules for change.
                        The number of allowed texts is 94 in total.
                      </p>
                      {TableComponent(admin_table_2_2_2, admin_columns_2_2_2)}
                      <p>If the expiration date has exceeded, the following screen for change password will be showed when you try to log-in.</p>
                      <img src="/img/document/admin_2.2.2.png" alt="" style={{ maxWidth: "800px" }} />
                      <div className='figure'>[Figure 2] Change Password</div>
                    </>
                  ),
                },
              ],
            },
            {
              id: 3,
              name: "2.3. Policy Management",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content:
                <>
                  <p>
                    The Admin manages policies for each task. 
                    <br/>
                    Manage policies on the [Site Policy] menu.
                  </p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "2.3.1. Policy Information",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content:
                    <>
                      <p>
                        The Policy Information has three tabs, FIDO, FIDO2 and Common.
                      </p>
                    </>
                  ,
                  children: [
                    {
                      id: 1,
                      name: "2.3.1.1. FIDO",
                      type: "depth5",
                      content:
                      <>
                      <p>
                        <ul>
                          <li>
                            <strong>Execution Policy by Action</strong>
                          </li>
                        </ul>
                        Manage the process for each action (Registration, Deregistration, Primary Authentication, Secondary Authentication).
                      </p>
                      <div><strong>[Registration]</strong></div>
                      <p>
                        Set the procedure for registering a user in the TouchEn OnePass Server. 
                      </p>
                      {TableComponent(admin_table_2_3_1_1_reg, admin_columns_2_3_1_1_reg)}
                      <div><strong>[Deregistration]</strong></div>
                      <p>Set the procedure for deregistration.</p>
                      {TableComponent(admin_table_2_3_1_1_dereg, admin_columns_2_3_1_1_dereg)}
                      <div><strong>[Primary Authentication]</strong></div>
                      <p>Set the procedure for primary authentication.</p>
                      {TableComponent(admin_table_2_3_1_1_primary, admin_columns_2_3_1_1_primary)}
                      <div><strong>[Secondary Authentication]</strong></div>
                      <p>Set the procedure for secondary authentication.</p>
                      {TableComponent(admin_table_2_3_1_1_secondary, admin_columns_2_3_1_1_secondary)}
                      <p>
                        <ul>
                          <li>
                            <strong>Allowed Authenticator and Restriction</strong>
                          </li>
                        </ul>
                        Add allowed authenticators for each task and manage the processing method of restricted items according to authenticators.
                        <div><strong>Information</strong></div>
                        <ul>
                          <li>
                            When installing the product, an authorized Admin can allocate (add / change) the registered allowed authentication device 
                          </li>
                          <li>
                            Provided allowed authentication device should <strong>‘support’</strong> <em>‘expiration date’</em>, <em>‘restriction functions for signature verification failures’</em> and <em>‘restriction functions for local authentication failures’</em>.
                            If you set <strong>‘not-supported’</strong> item to <strong>‘Use’</strong>, the set value will not be applied and shading in red.
                          </li>
                        </ul>
                        <div><strong>[Allowed Authenticator]</strong></div>
                        {TableComponent(admin_table_2_3_1_1_lock, admin_columns_2_3_1_1_lock)}
                        <div><strong>[Lock Policy]</strong></div>
                        Set the lock policy in the management console [Site Policy] &gt; [Policy Information] &gt; [FIDO] &gt; [Allowed Authentication Device]
                        <br/>
                        [STEP1] Select an authenticator to apply the lock policy from the Allowed Authentication Device tab. Select ‘Use’ if signature verification or local authentication fails. 
                      </p>
                      <img src="/img/document/admin_2.3.1.1.png" alt="" style={{ maxWidth: "800px" }} />
                      <div className='figure'>[Figure 24] Allowed Authentication Device</div>
                      <p>
                        <ul>
                          <li>
                            <strong>Allowed app</strong>
                          </li>
                        </ul>
                        Use the hash value of scope and certification and package name to manage allowed apps. Set scope (task only or task integration) and click the Add App button to add the app ID of certification and package name.
                        <br/>
                        If you change the scope of running service, you might not able to use registered authenticators.
                      </p>
                      <img src="/img/document/admin_2.3.1.1.png" alt="" style={{ maxWidth: "800px" }} />
                      <div className='figure'>[Figure 25] Allowed App</div>
                      {TableComponent(admin_table_2_3_1_1_allow, admin_columns_2_3_1_1_allow)}
                      </>
                      ,
                    },
                    {
                      id: 2,
                      name: "2.3.1.2. FIDO2",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content:
                        <>
                          <p>
                            <ul>
                              <li>
                                <strong>Execution Policy by Action</strong>
                              </li>
                            </ul>
                            Manage the process for each action (Registration, Deregistration, Primary Authentication, Secondary Authentication).
                          </p>
                          <div><strong>[Registration]</strong></div>
                          <p>
                            Set the procedure for registering a user in the TouchEn OnePass Server. 
                          </p>
                          {TableComponent(admin_table_2_3_1_2, admin_columns_2_3_1_2)}
                          <div><strong>[Deregistration]</strong></div>
                          <p>Set the procedure for deregistration.</p>
                          {TableComponent(admin_table_2_3_1_2_dereg, admin_columns_2_3_1_2_dereg)}
                          <div><strong>[Primary Authentication]</strong></div>
                          <p>Set the procedure for primary authentication.</p>
                          {TableComponent(admin_table_2_3_1_2_primary, admin_columns_2_3_1_2_primary)}
                          <p>
                            <ul>
                              <li>
                                <strong>Allowed RP information</strong>
                              </li>
                            </ul>
                          </p>
                          {TableComponent(admin_table_2_3_1_2_allow, admin_columns_2_3_1_2_allow)}
                          <p>
                            <ul>
                              <li>
                                <strong>Option Policy </strong>
                              </li>
                            </ul>
                          </p>
                          {TableComponent(admin_table_2_3_1_2_option, admin_columns_2_3_1_2_option)}
                        </>
                      , 
                    },
                    {
                      id: 3,
                      name: "2.3.1.3. Common",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content:
                      <>
                        <p>
                          <ul>
                            <li>
                              <strong>Service usage information</strong>
                            </li>
                          </ul>
                          Set the service period for the task.
                          <br/>
                          Go to the management console [Site Policy] &gt; [Policy Information] &gt; [Common] &gt; [Service Usage Information].
                        </p>
                        <img src="/img/document/admin_2.3.1.1.png" alt="" style={{ maxWidth: "800px" }} />
                        <div className='figure'>[Figure 26] Service Usage Information</div>
                        <p>
                          <ul>
                            <li>
                              <strong>Restriction policy</strong>
                            </li>
                          </ul>
                          Manage the processing method of restricted items for authenticator.
                        </p>
                        <div><strong>[Restriction Policy]</strong></div>
                        {TableComponent(admin_table_2_3_1_3, admin_columns_2_3_1_3)}
                        <div><strong>[Lock Policy]</strong></div>
                        <p>
                          [STEP1] Go to the Restriction Policy tab to set signature verification retry count and processing method of signature verification failure 
                          <br/>
                          [STEP2] Go to the Lock Policy tab to set the lock target and the unlock method for registering authenticators.
                        </p>
                        <img src="/img/document/admin_2.3.1.3_lock.png" alt="" style={{ maxWidth: "800px" }} />
                        <div className='figure'>[Figure 27] Restriction Policy / Lock Policy</div>
                        <p>Lock Policy</p>
                        {TableComponent(admin_table_2_3_1_3_lock, admin_columns_2_3_1_3_lock)}
                        <p>
                          <ul>
                            <li>
                              <strong>Authenticator threat</strong>
                            </li>
                          </ul>
                          Set to allow the device depending on the damage of authenticator META information.
                        </p>
                        {TableComponent(admin_table_2_3_1_3_auth, admin_columns_2_3_1_3_auth)}
                      </>
                      ,
                    },
                  ]
                },
              ],
            },
          ],
        },
        {
          id: 3,
          name: "3. AuthenticatorInformation",
          type: "depth2",
          isDropdown: false,
          isDropdownOpen: false,
          content: 
            <>
              <p>In order to use authenticator in TouchEn OnePass, you should register MEATA DATA file(“META file”) for the authenticator. Installation personnel register META file in person and Site Admin allocate the registered authenticator to the site to use. It is unable to use TouchEn OnePass through an unregistered authenticator </p>
            </>
          ,
        },
        {
          id: 4,
          name: "4. History Management",
          type: "depth2",
          isDropdown: false,
          isDropdownOpen: false,
          content: 
            <>
              <p>Authorized Admin inquire and manage user registration information and transaction history </p>
            </>
          ,
          children: [
            {
              id: 1,
              name: "4.1.	Registration Information",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content : 
                <>
                  <p>
                    Manage registered user information, device information and device registration information. 
                  </p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "4.1.1.	User Information Management",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  children: [
                    {
                      id: 1,
                      name: "4.1.1.1.	Inquire User Information",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                          <p>
                            [STEP1] Go to [Registration Information] menu.
                            <br/>
                            [STEP2] Select the target Site and Task to inquire the user’s registration information for each site and task.
                            <br/>
                            [STEP3] Click the 'Search' button on the User Information tab. &#40;‘Y’ on Non-user Lock Inquiry means that a registered device does not exist but user status is lock.&#41;
                          </p>
                          <img src='/img/document/admin_4.1.1.1_1.png' alt='' style={{ maxWidth: "900px" }} />
                          <div class='figure'>[Figure 50] User Information</div>
                          <p>
                            [STEP4] Check inquired user information.
                          </p>
                          <img src='/img/document/admin_4.1.1.1_2.png' alt='' style={{ maxWidth: "900px" }} />
                          <div class='figure'>[Figure51] Inquire the User Information</div>
                        </>
                      ,
                    },
                    {
                      id: 2,
                      name: "4.1.1.2.	User Lock",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                          <p>
                            In order to blcok registered users from TouchEn OnePass funtions, you can set User Lock.
                            <br/>
                            [STEP1] Inquire the user list through the [Inquire User Information] function. &#40;[Registration Information] &gt; [User Information] &gt; Inquire user list&#41;.
                            <br/>
                            [STEP2] Click the button 'lock' to lock the user.
                            <br/>
                            [STEP3] Click the Confirm button on the Lock Status Change message.
                          </p>
                          <img src='/img/document/admin_4.1.1.2_1.png' alt='' style={{ maxWidth: "900px" }}/>
                          <div class='figure'>[Figure52] Change Lock Status</div>
                          <img src='/img/document/admin_4.1.1.2_2.png' alt='' style={{ maxWidth: "900px" }}/>
                          <div class='figure'>[Figure53] Lock</div>
                        </>
                      ,
                    },
                    {
                      id: 3,
                      name: "4.1.1.3.	User Unlock",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content:
                        <>
                          <p>Unlock the user who is locked due to Admin lock or signature verification / local authentication failures. 
                          <br/>
                          [STEP1] Inquire the user list through the [Inquire User Information] function. &#40;[Registration Information]  &gt; [User Information] &gt; Inquire user list&#41; .
                          <br/>
                          [STEP2] Click the 'unlock' button of the locked user.
                          <br/>
                          [STEP3] Click the Confirm button on the Lock Status Change message.
                          </p>
                          <img src='/img/document/admin_4.1.1.3_1.png' alt='' style={{ maxWidth: "900px" }}/>
                          <div className='figure'>[Figure54] Unlock User</div>
                        </>
                      ,
                    },
                    {
                      id: 4,
                      name: "4.1.1.4.	Delete User",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                        <p>Click the ‘Delete’ button on the [Inquire User Information] function to delete the user’s device and lock status.</p> 
                        <img src='/img/document/admin_4.1.1.4_1.png' alt='' style={{ maxWidth: "800px" }}/>
                        <div class='figure'>[Figure55] Delete User</div>
                        </>,
                    },
                    {
                      id: 5,
                      name: "4.1.1.5.	Search Condition",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                        <p>Inquire the user information with registration date or user ID.</p>
                        {TableComponent(admin_table_4_1_1_5, admin_columns_4_1_1_5)}
                        </>
                      ,
                    },
                  ]
                },
                {
                  id: 2,
                  name: "4.1.2.	Manage Device Registration Information",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  children: [
                    {
                      id: 1,
                      name: "4.1.2.1.	Inquire Device Registration Information",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                        <p>
                          [STEP1] Go to the [Registration Information] menu.<br/>
                          [STEP2] Go to the [Device Registration Information] tab.<br/>
                          [STEP3] Select ‘=Management Site=’ on the right top of the page to inquire the registered device information of all the sites. Select the target site and task on the right top of the page to inquire registered user information for each site and task.<br/>
                          [STEP4] Click the 'search'  button on the Device Registration Information.
                        </p>
                        <img src='/img/document/admin_4.1.2.1_1.png' alt='' style={{ maxWidth: "900px" }}/>
                        <div className='figure'>[Figure56] Registered Device Information</div>
                        <p>[STEP5] Check the inquired device registration information.</p>
                        <img src='/img/document/admin_4.1.2.1_2.png' alt='' style={{ maxWidth: "900px" }}/>
                        <div className='figure'>[Figure57] Inquire Registered Device Information </div>
                        </>
                      ,
                    },
                    {
                      id: 2,
                      name: "4.1.2.2.	Search Condition",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                        <p>Inquire device registration information with registration date or user ID.</p>
                        {TableComponent(admin_table_4_1_2_2, admin_columns_4_1_1_5)}
                        </>
                      ,
                    },
                    {
                      id: 3,
                      name: "4.1.2.3.	Delete Device Information",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                          <p>
                          Delete the device information registered in the device subscribers.
                          <br/>
                          [STEP1] Inquire device subscriber information through the [Inquire Device Registration Information].
                          <br/> 
                          &#40; [Registration Information] &gt; [Device Registration Information] &gt; Inquire the list of device subscribers &#41;
                          <br/>
                          [STEP2] Click the Delete button to delete the registered device information.
                          </p>
                        </>
                      ,
                    }
                  ]
                }
              ]
            },
            {
              id: 2,
              name: "4.2.	Log Management",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              children: [
                {
                  id: 1,
                  name: "4.2.1.	Inquire Admin Monitoring History",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <p>
                        Provide all the history of authorized Admin’s actions conducted on the management console.
                        <br/>
                        [STEP1] Go to the [Log and Status] menu.
                        <br/>
                        [STEP2] Click the 'search' button on the Admin Monitoring tab.
                      </p>
                      <img src='/img/document/admin_4.2.1_1.png' alt='' style={{ maxWidth: "900px" }}/>
                      <div className='figure'>[Figure60] Admin Monitoring History</div>
                    </>
                  ,
                },
                {
                  id: 2,
                  name: "4.2.2.	Search Condition",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      {TableComponent(admin_table_4_2_2, admin_columns_4_1_1_5)}
                    </>
                  ,
                },
                {
                  id: 3,
                  name: "4.2.3.	Inquire Transaction History",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <p>Provide the transaction history of service user registration and authentication.</p>
                    </>
                  ,
                  children: [
                    {
                      id: 1,
                      name: "4.2.3.1.	Inquire Transaction History",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                          <p>
                            [STEP1] Go to the [Log and Status] menu.
                            <br/>
                            [STEP2] Go to the [Transaction History] tab.
                            <br/>
                            [STEP3] Select ‘=Management Site=’ on the right top of the page to inquire the registered device information of all the sites. Select the target site and task on the right top of the page to inquire registered device information for each site and task.
                            <br/>
                            [STEP4] Click the 'search' button on the Transaction History tab.
                          </p>
                          <img src='/img/document/admin_4.2.3.1_1.png' alt='' style={{ maxWidth: "900px" }}/>
                          <div className='figure'>[Figure61] Transaction History</div>
                          <p>
                            [STEP5] Check the inquired the transaction history.
                          </p>
                          <img src='/img/document/admin_4.2.3.1_2.png' alt='' style={{ maxWidth: "900px" }}/>
                          <div className='figure'>[Figure62] Inquire Transaction History</div>
                        </>
                      ,
                    },
                    {
                      id: 2,
                      name: "4.2.3.2.	Search Condition",
                      type: "depth5",
                      isDropdown: false,
                      isDropdownOpen: false,
                      content: 
                        <>
                          {TableComponent(admin_table_4_2_3_2, admin_columns_4_1_1_5)}
                        </>
                      ,
                    },
                  ]
                },
              ]
            }
          ],
        },
        {
          id: 5,
          name: "5.	Account and Authority Management",
          type: "depth2",
          children: [
            {
              id: 1,
              name: "5.1.	Authority Management",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              children: [
                {
                  id: 1,
                  name: "5.1.1.	Admin Type and Authority",
                  type: "depth4",
                  content : 
                    <>
                    <img src="/img/document/admin_5.1.1.png" alt="" style={{ maxWidth: "800px" }} />
                    <div className='figure'>[Figure78] Admin Type List</div>
                    {TableComponent(admin_table_5_1_1_1, admin_columns_5_1_1_1)}
                    <p>
                      ● Information
                      <br/>
                      Authority in use will not have the delete menu. In other words, authority in use cannot be deleted.
                    </p>
                    </>
                  ,
                },
              ],
            },
            {
              id: 2,
              name: "5.2.	Account Management",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              children: [
                {
                  id: 1,
                  name: "5.2.1.	Add Admin Type for Each Site",
                  type: "depth4",
                  content : 
                    <>
                    <ul>
                      <li>[STEP1] Go to the [Account and Authority] &lt; [Account Management].</li>
                      <li>[STEP2] Select a ‘=Management Site=’ to add an account.</li>
                      <li>[STEP3] Click the [+Add account] button.</li>
                      <li>[STEP4] Enter Admin ID, Admin Name, Admin Type and Password, and click the [Save] button. </li>
                      <li>[STEP5] Confirm the registered Admin account.</li>
                    </ul>
                    <img src="/img/document/admin_5.1.2.png" alt="" style={{ maxWidth: "800px" }} />
                    <div className='figure'>[Figure83] Inquire Admin Account</div>
                    <p>
                      ● InputData
                    </p>
                    {TableComponent(admin_table_5_2_1_1, admin_columns_5_2_1_1)}
                    <p>
                      ● Information
                      <br/>
                      Admin Type created by the Admin is automatically provided. The Admin Type will be provided same as the one for account creation under the same ‘Site/Task’. If unable to select the Admin Type, follow the process in 9.2.1. Add Admin Type for Each Site to create an Admin Type
                    </p>
                    </>
                  ,
                },
                {
                  id: 1,
                  name: "5.2.2.	Edit Admin Account for Each Site",
                  type: "depth4",
                  content : 
                    <>
                    <ul>
                      <li>[STEP1] Go to the [Account and Authority]&gt;[Authority Management] tap </li>
                      <li>[STEP2] Select ‘=Management Site=’ where Admin account to be edited is belonged to</li>
                      <li>[STEP3] Click the [Search] button in the Admin account list and click the [Confirm] button for the Admin</li>
                      <li>[STEP4] Edit the information and click the [Save] button</li>
                    </ul>
                    <img src="/img/document/admin_5.2.2.png" alt="" style={{ maxWidth: "800px" }} />
                    <div className='figure'>[Figure84] Admin Account Information</div>
                    <p></p>
                  </>
                  ,
                },
              ],
            },
          ],
        },
      ]
    },
    {
      id: 2,
      name: "Server API Reference",
      type: "depth1",
      isDropdown: true,
      isDropdownOpen: false,
      isActive: false,
      children: [
        {
          id: 1,
          name: "1.	Introduction",
          type: "depth2",
          isDropdown: true,
          isDropdownOpen: false,
          content: 
            <>
              <p>TouchEn OnePass Server provides a set of backend APIs for Service Provider. The Service Provider should integrate TouchEn OnePass Server APIs to use the FIDO function. You can utilize all service functions including FIDO Registration/Authentication/Deregistration through the set of APIs.</p>
            </>
          ,
        },
        {
          id: 2,
          name: "2.	FIDO 1.0 UAF API",
          type: "depth2",
          isDropdown: true,
          isDropdownOpen: false,
          children: [
            {
              id: 1,
              name: "2.1 Simple Authentication Service Enrollment",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content:
                <>
                  <p>This API is to register a FIDO 1.0 UAF user. The Service Provider should request this API to provide the simple authentication service to a user.</p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "API Flow",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <img src='/img/document/document_4.2.1.1.png' alt='' style={{ maxWidth: "900px" }} />
                    </>
                  ,
                },
                {
                  id: 2,
                  name: "API Description",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <ul>
                        <li>Interface : Simple Authentication Service Enrollment</li>
                        <li>Request URL : /interfBiz/processRequest.do</li>
                        <li>Protocol : HTTPS</li>
                        <li>HTTP Method : POST</li>
                      </ul>
                    </>
                  ,
                },
                {
                  id: 3,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      {TableComponent(Table_4_2_1_3, REQEST_DATA_TABLE_COLUMN)}
                      {CodeComponent(` // Request Data JSON Example
                      {
                        "command": "requestServiceRegist",
                        "bizReqType": "server",
                        "svcTrId": "65519171036275812957",
                        "siteId": "0123456789",
                        "svcId": "0000011111",
                        "loginId": "userId"
                      }
                      `
                      , 'javascript')}
                    </>
                  ,
                },
                {
                  id: 4,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      {TableComponent(Table_4_2_1_4, RESPONSE_DATA_TABLE_COLUMN)}
                      {CodeComponent(` // Response Data JSON Example
                      {
                        "resultCode":"000",
                        "resultMsg":"success",
                        "resultData": {
                          "trId":"14428195504269171858",
                          "pushResult":"1",
                          "qrImageUrl":"https://domain/grcode/grCodeMgmt/getQrCodeByRequestParam.do?enc=ABCD23",
                          "qrImage":"iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJA...ASUVORK5CYII=",
                          "expireTime":"90"
                        }
                        `
                      , 'javascript')}
                    </>
                  ,
                },
              ]
            },
            {
              id: 2,
              name: "2.2 Simple Authentication",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content:
                <>
                  <p>This API is for FIDO 1.0 UAF user authentication. Use the Simple Authentication Service Enrollment API to register a user. Use this API for FIDO 1.0 authentication. </p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "API Flow",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <img src='/img/document/document_4.2.2.1.png' alt='' style={{ maxWidth: "900px" }}/>
                    </>
                  ,
                },
                {
                  id: 2,
                  name: "API Description",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <ul>
                        <li>Interface : Simple Authentication</li>
                        <li>Request URL : /interfBiz/processRequest.do</li>
                        <li>Protocol : HTTPS</li>
                        <li>HTTP Method : POST</li>
                      </ul>
                    </>
                  ,
                },
                {
                  id: 3,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      {TableComponent(Table_4_2_2_3, REQEST_DATA_TABLE_COLUMN)}
                      {CodeComponent(` // Request Data JSON Example
                      {
                        "command": "requestServiceAuth",
                        "bizReqType": "server",
                        "svcTrId": "65519171036275812957",
                        "siteId": "0123456789",
                        "svcId": "0000011111",
                        "loginId": "userId"
                      }
                      `
                      , 'javascript')}
                    </>
                  ,
                },
                {
                  id: 4,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      {TableComponent(Table_4_2_1_4, RESPONSE_DATA_TABLE_COLUMN)}
                      {CodeComponent(` // Response Data JSON Example
                      {
                        "resultCode":"000",
                        "resultMsg":"success",
                        "resultData": {
                          "trId":"14428195504269171858",
                          "pushResult":"1",
                          "qrImageUrl":"https://domain/grcode/grCodeMgmt/getQrCodeByRequestParam.do?enc=ABCD23",
                          "qrImage":"iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJA...ASUVORK5CYII=",
                          "expireTime":"90"
                        }
                        `
                      , 'javascript')}
                    </>
                  ,
                },
              ]
            },
            {
              id: 3,
              name: "2.3 Deregistration",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content:
                <>
                  <p>This API is to deregister the FIDO 1.0 UAF service. You no longer use the simple authentication service after invoking this API. </p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "API Flow",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <img src='/img/document/document_4.2.3.1.png' alt='' style={{ maxWidth: "900px" }}/>
                    </>
                  ,
                },
                {
                  id: 2,
                  name: "API Description",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <ul>
                        <li>Interface : Service deregistration</li>
                        <li>Request URL : /interfBiz/processRequest.do</li>
                        <li>Protocol : HTTPS</li>
                        <li>HTTP Method : POST</li>
                      </ul>
                    </>
                  ,
                },
                {
                  id: 3,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(Table_4_2_3_3, REQEST_DATA_TABLE_COLUMN),
                    CodeComponent(` // Request Data JSON Example
                    {
                      "command": "requestServiceRelease",
                      "bizReqType": "server",
                      "svcTrId": "65519171036275812957",
                      "siteId": "0123456789",
                      "svcId": "0000011111",
                      "loginId": "userId"
                    }
                    `
                    , 'javascript'),
                  ],
                },
                {
                  id: 4,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(Table_4_2_1_4, RESPONSE_DATA_TABLE_COLUMN),
                    CodeComponent(` // Response Data JSON Example
                    {
                      "resultCode":"000",
                      "resultMsg":"success",
                      "resultData": {
                        "trId":"14428195504269171858",
                        "pushResult":"1",
                        "qrImageUrl":"https://domain/grcode/grCodeMgmt/getQrCodeByRequestParam.do?enc=ABCD23",
                        "qrImage":"iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJA...ASUVORK5CYII=",
                        "expireTime":"90"
                      }
                      `
                    , 'javascript'),
                  ],
                },
              ]
            },
            {
              id: 4,
              name: "2.4 Transaction Result Confirmation",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content:
                <>
                  <p>This API is to check the result of FIDO Registration/Authentication/Deregistration. The Service Provider requests this API to check the final result of FIDO transaction. </p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "API Description",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <ul>
                        <li>Interface : Transaction Result Confirmation</li>
                        <li>Request URL : /interfBiz/processRequest.do</li>
                        <li>Protocol : HTTPS</li>
                        <li>HTTP Method : POST</li>
                      </ul>
                    </>
                  ,
                },
                {
                  id: 2,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(Table_4_2_4_2, REQEST_DATA_TABLE_COLUMN),
                    CodeComponent(` // Request Data JSON Example
                    {
                      "command": "trResultConfirm",
                      "svcTrId": "58491670716093645603"
                    }
                    `
                    , 'javascript'),
                  ],
                },
                {
                  id: 3,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(Table_4_2_4_3, RESPONSE_DATA_TABLE_COLUMN),
                    CodeComponent(` // Response Data JSON Example
                    {
                      "resultCode":"000",
                      "resultMsg":"Successfully processed.",
                      "resultData": {
                        "trStatus":"3",
                        "trStatusMsg":"Wait for transaction."
                      }
                    }
                    `
                    , 'javascript'),
                  ],
                },
              ]
            },
            {
              id: 5,
              name: "2.5 Retrieve the allowed authenticator list",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content:
                <>
                  <p>This API is to provide the list of authenticators allowed to be registered to the Service Provider. The site Admin sets the allowed registration status on the Admin page. The Service Provider shows the allowed authenticator list to user. </p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "API Description",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <ul>
                        <li>Interface : Retrieve the allowed authenticator list</li>
                        <li>Request URL : /interfBiz/processRequest.do</li>
                        <li>Protocol : HTTPS</li>
                        <li>HTTP Method : POST</li>
                      </ul>
                    </>
                  ,
                },
                {
                  id: 2,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(Table_4_2_5_2, REQEST_DATA_TABLE_COLUMN),
                    CodeComponent(` // Request Data JSON Example
                    {
                      "command": "allowedAuthnr",
                      "siteId": "0123456789",
                      "svcId": "0000011111"
                    }
                    `
                    , 'javascript'),
                  ],
                },
                {
                  id: 3,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(Table_4_2_5_3, RESPONSE_DATA_TABLE_COLUMN),
                    CodeComponent(` // Response Data JSON Example
                    {
                      "resultCode":"000",
                      "resultMsg":"Successfully processed.",
                      "resultData": {
                        "aaidAllowList":[
                          {
                            "aaid": "0012#0002",
                            "verificationType": "4",
                            "verificationNm": "Passcode",
                            "vendorNm": "RAONSECURE",
                            "vendorId": "0012"
                          }, 
                          {
                            "aaid": "0012#0007",
                            "verificationType": "2",
                            "verificationNm": "Fingerprint",
                            "vendorNm": "RAONSECURE",
                            "vendorId": "0012"
                          }
                        ]
                      }
                    }
                    `
                    , 'javascript'),
                  ],
                },
              ]
            },
            {
              id: 6,
              name: "2.6 Retrieve registration status",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content:
                <>
                  <p>This API is to retrieve the list of registered authenticators. You can invoke this API to check whether the user registers an authenticator. </p>
                </>
              ,
              children: [
                {
                  id: 1,
                  name: "API Description",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: 
                    <>
                      <ul>
                        <li>Interface : Retrieve registration status</li>
                        <li>Request URL
                          <ol>Server : /interfBiz/processRequest.do</ol>
                          <ol>App : /interfDeviceBiz/processRequest.do</ol>
                        </li>
                        <li>Protocol : HTTPS</li>
                        <li>HTTP Method : POST</li>
                      </ul>
                    </>
                  ,
                },
                {
                  id: 2,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(Table_4_2_6_2, REQEST_DATA_TABLE_COLUMN),
                    CodeComponent(` // Request Data JSON Example
                    {
                      "command": "checkRegisteredStatus",
                      "siteId": "0123456789",
                      "svcId": "0000011111",
                      "loginId": "userId",
                      "version": "1",
                      “rpId” : “sample.bank.com”
                      "protocolFamily": "all"
                    }
                    `
                    , 'javascript'),
                  ],
                },
                {
                  id: 3,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(Table_4_2_6_3, RESPONSE_DATA_TABLE_COLUMN),
                    CodeComponent(` // Response Data JSON Example
                    {
                      "resultCode":"000",
                      "resultMsg":"Successfully processed.",
                      "resultData": {
                        "regYn": "Y",
                        "aaidList": {
                          "accepted": [
                            {
                              "aaid": "0012#0002",
                              "verificationType": "4",
                              "verificationNm": "Passcode",
                              "vendorNm": "RAONSECURE",
                              "vendorId": "0012"
                            }
                            "registered": [
                              {
                                "aaid": "0012#0002",
                                "keyId": "h6uOTb11gwKfqU3qMWYVWqpqDp5q2BqqndyCoMkm7Y4",
                                "verificationType": "4",
                                "verificationNm": "Passcode",
                                "vendorNm": "RAONSECURE",
                                "vendorId": "0012"
                              }
                            ]
                          }
                        }
                      }
                      `
                      , 'javascript'),
                  ],
                },
              ]
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "Passkey",
      type: "depth1",
      isDropdown: true,
      isDropdownOpen: false,
      isActive: false,
      children: [
        {
          id: 1,
          name: "1.	Passkey",
          type: "depth2",
          isDropdown: true,
          isDropdownOpen: false,
          content: 
            <>
              <p>Passkey is a safer and more convenient authentication method that replaces passwords. Passwords require users to remember and type them, and they must also be stored in the service provider's database.<br/>
                Passkey, on the other hand, is securely stored on the user's device and can be authenticated using the user's biometric information. The service provider only needs to store a public key that can be exposed without concern. The only thing transmitted from the user's device to the server is the electronic signature proving authentication. No other secrets, such as passwords, are transmitted.<br/>
                This makes it safe from phishing attacks and server database breaches.
              </p>
            </>
          ,
        },
        {
          id: 2,
          name: "2.	Registration",
          type: "depth2",
          isDropdown: true,
          isDropdownOpen: false,
          children: [
            {
              id: 1,
              name: "2.1.	Initial Request for Passkey Registration",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content: "<ul>\
                          <li>Request URL : /opf2/rp/getMakeCredential</li>\
                          <li>Protocol : HTTPS</li>\
                          <li>HTTP Method : POST</li>\
                       </ul>",
              children:[
                {
                  id: 1,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_1_4, passkey_columns_1_4),
                    CodeComponent(` // Request Data JSON Example
                    {
                      "siteId": "SITEA0000000001",
                      "svcId": "00001",
                      "svcTrId": "11526491728467773203",
                      "origin": "example.com",
                      "user": {
                        "name": "jkmoon0001@raonsecure.com",
                        "displayName": "jkmoon0001"
                      },
                      "rp": {
                        "id": "example.com"
                      },
                      "reqProtocolType": "1",
                      "authnrAttachment": "platform",
                      "credentialAlias": "Windows 11"
                    }
                    `
                    , 'javascript')
                  ],
                },
                {
                  id: 2,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_1_5, passkey_columns_1_5),
                    CodeComponent(` // Response Data JSON Example
                  {
                    "statusCode": 1200,
                    "result": "{
                        \"publicKey\": {
                            \"rp\": {
                                \"id\": \"example.com\",
                                \"name\": \"example\"
                            },
                            \"user\": {
                                \"displayName\": \"jkmoon0001\",
                                \"id\": \"MTYwNjQzODA2NjQ3MDg1ODY5Mjg\"
                                \"name\": \"jkmoon0001@raonsecure.com\"
                            },
                            \"challenge\": \"SxnUdxTdgtNsXV_fcCFP-EFpk5w2tnIKzX2IkfPg_qo\",
                            \"pubKeyCredParams\": [
                                {
                                    \"type\": \"public-key\",
                                    \"alg\": -257
                                },
                                {
                                    \"type\": \"public-key\",
                                    \"alg\": -258
                                }
                            ],
                            \"timeout\": 30000,
                            \"authenticatorSelection\": {
                                \"authenticatorAttachment\": \"platform\",
                                \"requireResidentKey\": false,
                                \"userVerification\": \"preferred\"
                            }
                        },
                        \"trId\": \"16468897283854903960\",
                        \"challengeToken\": \"yMXvDnBd6RvBUyvGPLolgdYkBtk0i0p8h9rsiewjLoI\"
                      }"
                  }
                   `
                   , 'javascript'),
                  ],
                },
              ]      
            },
            {
              id: 2,
              name: "2.2.	Secondary Request for Passkey Registration",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content: "<ul>\
                          <li>Request URL : /opf2/rp/sendMakeCredential</li>\
                          <li>Protocol : HTTPS</li>\
                          <li>HTTP Method : POST</li>\
                       </ul>",
              children:[
                {
                  id: 1,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_2_2, passkey_columns_2_2),
                    CodeComponent(` // Request Data JSON Example
                    {
                        "origin": "http://localhost:8080",
                        "user": {
                            "name": "test0004"
                        },
                        "rp": {
                            "id": "localhost"
                        },
                        "credential": {
                            "id": "a3RHj8FV78toB-XMlKQmflo-WQgMKRlQTpfgIG7pgxk",
                            "response": {
                                "clientDataJSON": "eyJ0eXBlIjoid2ViYXV0aG4uY3JlYXRlIiwiY2…",
                                "attestationObject": "o2NmbXRjdHBtZ2F0dFN0bXSmY2FsZzn..."
                            }
                        },
                        "challengeToken": "yMXvDnBd6RvBUyvGPLolgdYkBtk0i0p8h9rsiewjLoI",
                        "trId":"16468897283854903960"
                    }
                    `
                    , 'javascript')
                  ],
                },
                {
                  id: 2,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_2_2_res, passkey_columns_2_2_res),
                    CodeComponent(` // Response Data JSON Example
                    {
                          "statusCode": 1200,
                          "result": "{
                              \"credentialHashId\": \"qFrnhwTT3-QrNaRxx5kGIONq8BaE6w6qX8Obg1abFcM\"
                          }"
                    }
                                `
                      , 'javascript'),
                  ],
                },
                {
                  id: 3,
                  name: "Initial Request for Passkey Registration",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(
                `const reqRequsetReg = (reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo, reqAuthnrAttachment,
                  credentialAlias, reqProtocolType, svcTrId) => {
                  return new Promise((resolve, reject) => {
                    let reqUrl = {TouchEn OnePass server URL} + “/opf2/rp/getMakeCredential”;
                    let query = {
                        siteId: reqSiteId,
                        svcId: reqSvcId,
                        svcTrId: svcTrId,
                        origin: reqOrigin,
                        user: reqUserInfo,
                        rp: reqRpInfo,
                        authnrAttachment: reqAuthnrAttachment,
                        credentialAlias: credentialAlias,
                        reqProtocolType: reqProtocolType
                  };
                    try {
                        $.ajax({
                            url: reqUrl,
                            headers: { "Accept": "application/json" },
                            dataType: "json",
                            method: "POST",
                            contentType: "application/json;charset=utf-8",
                            data: JSON.stringify(query),
                            success: function(data) {
                                let reqResultData = {};
                                if (data.statusCode == "1200") {
                                    reqResultData.statusCode = data.statusCode;
                                    reqResultData.result = JSON.parse(data.result);
                                } else {
                                    reqResultData.statusCode = data.statusCode;
                                }
                                resolve(reqResultData);
                            },
                            error: function(jqXHR, textStatus, errorThrown) {
                                // Handle error appropriately
                            }
                        });
                    } catch (error) {
                        // Handle error appropriately
                    }
                });
      }`
                      , 'javascript'),
                  ],
                },
                {
                  id: 4,
                  name: "Request for Passkey Authenticator Registration",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(`// requestParams: Send the response data for the initial registration request in the parameter
                    const fido2Register = (requestParams) => {
                        return new Promise((resolve, reject) => {
                            let reqResultData = {};
                            try {
                                reqResultData.statusCode = "1200";
                                let paramData = requestParams.result.publicKey;
                                // PublicKeyCredentialCreationOptions
                                let options = {};
                                // required
                                options.rp = paramData.rp;
                                options.rp.id = { current domain's host };
                                options.user = paramData.user;
                                // Decode the user.id delivered by the server to base64Url
                                options.user.id = decodeBase64Url(paramData.user.id);
                                // Decode the challenge delivered by the server to base64Url
                                options.challenge = decodeBase64url(paramData.challenge);
                                options.pubKeyCredParams = paramData.pubKeyCredParams;
                                options.timeout = paramData.timeout;
                                options.authenticatorSelection = paramData.authenticatorSelection;
                                options.attestation = paramData.attestation;
                                // W3C Web API
                                navigator.credentials.create({ "publicKey": options })
                                    .then((result) => {
                                        // PublicKeyCredential
                                        let credential = {};
                                        credential.id = result.id;
                                        credential.type = result.type;
                                        let response = {};
                                        // Encode the response data to base64Url
                                        response.clientDataJSON = encodeBase64url(result.response.clientDataJSON);
                                        response.attestationObject = encodeBase64url(result.response.attestationObject);
                                        credential.response = response;
                                        // Send new credential back to Relying Party for validation and store
                                        reqResultData.result = {};
                                        reqResultData.result.credential = credential;
                                        resolve(reqResultData);
                                    }).catch((err) => {
                                        // Something went wrong. Handle appropriately.
                                    });
                            } catch (ex) {
                                // Something went wrong. Handle appropriately.
                            }
                        });
                    }
                    `, 'javascript'),

                  ],
                },
                {
                  id: 5,
                  name: "Secondary Request for Passkey Registration",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(`
                    const reqResponseReg = (reqOrigin, reqRpInfo, reqUserInfo, credential, challengeToken, trId) => {
                        return new Promise((resolve, reject) => {
                            let reqUrl = { TouchEn OnePass server URL } + "/opf2/rp/sendMakeCredential";
                            let query = {
                                origin: reqOrigin,
                                user: reqUserInfo,
                                rp: reqRpInfo,
                                credential: credential,
                                challengeToken: challengeToken,
                                trId: trId
                            };
                            try {
                                $.ajax({
                                    url: reqUrl,
                                    headers: { "Accept": "application/json" },
                                    dataType: "json",
                                    method: "POST",
                                    contentType: "application/json;charset=utf-8",
                                    data: JSON.stringify(query),
                                    success: function(data, textStatus, xhr) {
                                        if (data.statusCode == "1200") {
                                            let reqResultData = {};
                                            reqResultData.statusCode = data.statusCode;
                                            reqResultData.result = data.result;
                                            resolve(reqResultData);
                                        } else {
                                            // Deliver the data to process the error
                                            resolve(data);
                                        }
                                    },
                                    error: function(jqXHR, textStatus, errorThrown) {
                                        // Something went wrong. Handle appropriately
                                    }
                                });
                            } catch (e) {
                                // Something went wrong. Handle appropriately
                            }
                        });
                    }
                    `, 'javascript'),
                  ],
                },
              ]      
            },
          ]
        },
        {
          id: 3,
          name: "3.	Authentication",
          type: "depth2",
          isDropdown: true,
          isDropdownOpen: false,
          children: [
            {
              id: 1,
              name: "3.1.	Initial Request for Passkey Registration",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content: "<ul>\
                          <li>Request URL : /opf2/rp/getGetAssertion</li>\
                          <li>Protocol : HTTPS</li>\
                          <li>HTTP Method : POST</li>\
                       </ul>",
              children:[
                {
                  id: 1,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_auth, passkey_columns_auth),
                    CodeComponent(` // Request Data JSON Example
                    {
                        "siteId": "SITEA0000000001",
                        "svcId": "00001",
                        "svcTrId": "11526491728467773203",
                        "origin": "example.com",
                        "user": {
                            "name": "jkmoon0001@raonsecure.com",
                            "displayName": "jkmoon0001"
                        },
                        "rp": {
                            "id": "example.com"
                        },
                        "reqProtocolType": "1",
                        "authnrAttachment": "platform"
                    }
                    `, 'javascript')
                  ],
                },
                {
                  id: 2,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_auth_res, passkey_columns_auth_res),
                    CodeComponent(` // Response Data JSON Example
                    {
                        "statusCode": 1200,
                        "result": {
                            "publicKey": {
                                "challenge": "4BG0SgZJNRtMyKl5o-P4AtZG6m01ARtOYv_Q1JdHokg",
                                "timeout": 30000,
                                "rpId": "example.com",
                                "allowCredentials": [
                                    {
                                        "type": "public-key",
                                        "id": "kSZU6yUPcMlxMai5cK6f8jLEhjYhO_-joVf5kh6YVC0"
                                    }
                                ],
                                "userVerification": "preferred"
                            },
                            "trId": "16468923531521971520",
                            "challengeToken": "heklYdFkFhb3bY4uuV7Tt7RP9Wph_5LOfZxMKOVtj4A"
                        }
                    }
                    `
                    , 'javascript'),
                  ],
                },
              ]      
            },
            {
              id: 2,
              name: "3.2. Request for Secondary Authentication",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content: "<ul>\
                          <li>Request URL : /opf2/rp/sendGetAssertion</li>\
                          <li>Protocol : HTTPS</li>\
                          <li>HTTP Method : POST</li>\
                       </ul>",
              children:[
                {
                  id: 1,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_auth_req_2, passkey_columns_auth_req_2),
                    CodeComponent(` // Request Data JSON Example
                    {
                        "origin": "http://localhost:8080",
                        "user": {
                            "name": "test0001"
                        },
                        "rp": {
                            "id": "localhost"
                        },
                        "credential": {
                            "id": "kSZU6yUPcMlxMai5cK6f8jLEhjYhO_-joVf5kh6YVC0",
                            "response": {
                                "clientDataJSON": "eyJ0eXBlIjoid2ViYXV0aG4uZ2V0IiwiY2hhbGxlbmdlIjoiZ2NGMEFaZU…",
                                "authenticatorData": "SZYN5YgOjGh0NBcPZHZgW4_krrmihjLHmVzzuoMdl2MFAAAACA",
                                "signature": "cXIM_omOQEykW3d66HpNZX6Hed29y60REa5RVXYXHiyCd85FO3O9l94Qui…"
                            }
                        },
                        "challengeToken": "heklYdFkFhb3bY4uuV7Tt7RP9Wph_5LOfZxMKOVtj4A",
                        "trId": "16468923531521971520"
                    }
                    `, 'javascript')
                  ],
                },
                {
                  id: 2,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_auth_res_3_2, passkey_columns_auth_res_3_2),
                    CodeComponent(` // Response Data JSON Example
                    {
                          "statusCode": 1200,
                          "result": "{
                              \"credentialHashId\": \"qFrnhwTT3-QrNaRxx5kGIONq8BaE6w6qX8Obg1abFcM\"
                          }"
                    }
                    `
                    , 'javascript'),
                  ],
                },
                {
                  id: 3,
                  name: "Initial Request for Passkey Authentication",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(`
                    const reqRequestAuth = (reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo, reqAuthnrAttachment,
                        reqProtocolType, svcTrId) => {
                        return new Promise((resolve, reject) => {
                            let reqUrl = {TouchEn OnePass server URL} + "/opf2/rp/getGetAssertion";
                            let query = {
                                siteId: reqSiteId,
                                svcId: reqSvcId,
                                svcTrId: svcTrId,
                                origin: reqOrigin,
                                user: reqUserInfo,
                                rp: reqRpInfo,
                                authnrAttachment: reqAuthnrAttachment,
                                reqProtocolType: reqProtocolType
                            };
                            try {
                                $.ajax({
                                    url: reqUrl,
                                    headers: { "Accept": "application/json" },
                                    dataType: "json",
                                    method: "POST",
                                    contentType: "application/json;charset=utf-8",
                                    data: JSON.stringify(query),
                                    success: function(data) {
                                        let reqResultData = {};
                                        if (data.statusCode == "1200") {
                                            reqResultData.statusCode = data.statusCode;
                                            reqResultData.result = JSON.parse(data.result);
                                        } else {
                                            reqResultData.statusCode = data.statusCode;
                                            reqResultData.description = data.description;
                                        }
                                        resolve(reqResultData);
                                    },
                                    error: function(jqXHR, textStatus, errorThrown) {
                                        // Something went wrong. Handle appropriately.
                                    }
                                });
                            } catch (e) {
                                // Something went wrong. Handle appropriately.
                            }
                        });
                    }
                    `
                    , 'javascript')
                  ],
                },
                {
                  id: 4,
                  name: "Request for Passkey Authenticator Authentication",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(`// requestParams: Deliver the response data for the initial request for Passkey authentication in parameter
                    const fido2Authenticate = (requestParams) => {
                        return new Promise((resolve, reject) => {
                            let reqResultData = {};
                            try {
                                reqResultData.statusCode = "1200";
                                let paramData = requestParams.result.publicKey;
                                // PublicKeyCredentialCreationOptions
                                let options = {};
                                // required
                                options.challenge = TouchEn OnePass2Util.base64url_decode(paramData.challenge);
                                // optional
                                options.timeout = paramData.timeout;
                                options.rpId = paramData.rpId;
                                options.userVerification = paramData.userVerification;
                                // base64url to byte array
                                if (paramData.allowCredentials != undefined) {
                                    options.allowCredentials = paramData.allowCredentials;
                                    let i = 0;
                                    for (i = 0; i < paramData.allowCredentials.length; i++) {
                                        options.allowCredentials[i].id = decodeBase64Url(paramData.allowCredentials[i].id);
                                    }
                                }
                                // W3C Web API
                                navigator.credentials.get({ "publicKey": options })
                                    .then((result) => {
                                        // PublicKeyCredential
                                        let credential = {};
                                        credential.id = result.id;
                                        credential.type = result.type;
                                        let response = {};
                                        // Encode the response data to base64Url
                                        response.clientDataJSON = encodeBase64url(result.response.clientDataJSON);
                                        response.authenticatorData = encodeBase64url(result.response.authenticatorData);
                                        response.signature = encodeBase64url(result.response.signature);
                                        response.userHandle = encodeBase64url(result.response.userHandle);
                                        credential.response = response;
                                        // Send new credential back to Relying Party for validation and store
                                        reqResultData.result = {};
                                        reqResultData.result.credential = credential;
                                        resolve(reqResultData);
                                    }).catch((err) => {
                                        // Something went wrong. Handle appropriately.
                                    });
                            } catch (ex) {
                                // Something went wrong. Handle appropriately.
                            }
                        });
                    }
                `
                , 'javascript')
                  ],
                },
                {
                  id: 5,
                  name: "Secondary Request for Passkey Authentication",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(`
                    const reqResponseAuth = (reqOrigin, reqRpInfo, reqUserInfo, credential, challengeToken, trId) => {
                        return new Promise((resolve, reject) => {
                            let reqUrl = { TouchEn OnePass server URL } + "/opf2/rp/sendMakeCredential";
                            let query = {
                                origin: reqOrigin,
                                user: reqUserInfo,
                                rp: reqRpInfo,
                                credential: credential,
                                challengeToken: challengeToken,
                                trId: trId
                            };
                            try {
                                $.ajax({
                                    url: reqUrl,
                                    headers: { "Accept": "application/json" },
                                    dataType: "json",
                                    method: "POST",
                                    contentType: "application/json;charset=utf-8",
                                    data: JSON.stringify(query),
                                    success: function(data, textStatus, xhr) {
                                        if (data.statusCode == "1200") {
                                            let reqResultData = {};
                                            reqResultData.statusCode = data.statusCode;
                                            reqResultData.result = data.result;
                                            resolve(reqResultData);
                                        } else {
                                            // Deliver the data to process the error
                                            resolve(data);
                                        }
                                    },
                                    error: function(jqXHR, textStatus, errorThrown) {
                                        // Something went wrong. Handle appropriately
                                    }
                                });
                            } catch (e) {
                                // Something went wrong. Handle appropriately
                            }
                        });
                    }
                    `
                    , 'javascript')                
                  ],
                },
              ]      
            },
          ]
        },
        {
          id: 4,
          name: "4.	Deregistration",
          type: "depth2",
          isDropdown: true,
          isDropdownOpen: false,
          children: [
            {
              id: 1,
              name: "4.1. Primary Request for Passkey Deregistration",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content: "<ul>\
                          <li>Request URL : /opf2/rp/getDecommission</li>\
                          <li>Protocol : HTTPS</li>\
                          <li>HTTP Method : POST</li>\
                       </ul>",
              children:[
                {
                  id: 1,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_deReg_req, passkey_columns_deReg_req),
                    CodeComponent(`// Request Data JSON Example
                    {
                        "siteId": "SITEA0000000001",
                        "svcId": "00001",
                        "svcTrId": "11526491728467773203",
                        "origin": "example.com",
                        "user": {
                            "name": "jkmoon0001@raonsecure.com",
                            "displayName": "jkmoon0001"
                        },
                        "rp": {
                            "id": "example.com"
                        },
                        "reqProtocolType": "1"
                    }
                `, 'javascript')
                  ],
                },
                {
                  id: 2,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_auth_res, passkey_columns_auth_res),
                    CodeComponent(`// Response Data JSON Example
                    {
                        "statusCode": 1200,
                        "result": {
                            "publicKey": {
                                "challenge": "4BG0SgZJNRtMyKl5o-P4AtZG6m01ARtOYv_Q1JdHokg",
                                "timeout": 30000,
                                "rpId": "example.com",
                                "allowCredentials": [
                                    {
                                        "type": "public-key",
                                        "id": "kSZU6yUPcMlxMai5cK6f8jLEhjYhO_-joVf5kh6YVC0"
                                    }
                                ],
                                "userVerification": "preferred"
                            },
                            "trId": "16468923531521971520",
                            "challengeToken": "heklYdFkFhb3bY4uuV7Tt7RP9Wph_5LOfZxMKOVtj4A"
                        }
                    }
                    `
                    , 'javascript'),
                  ],
                },
              ]      
            },
            {
              id: 2,
              name: "4.2. Secondary Request for Deregistration ",
              type: "depth3",
              isDropdown: false,
              isDropdownOpen: false,
              content: "<ul>\
                          <li>Request URL : /opf2/rp/sendDecommission</li>\
                          <li>Protocol : HTTPS</li>\
                          <li>HTTP Method : POST</li>\
                       </ul>",
              children:[
                {
                  id: 1,
                  name: "Request Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_dereg_req_2, passkey_columns_dereg_req_2),
                    CodeComponent(`// Request Data JSON Example
                    {
                        "origin": "http://localhost:8080",
                        "user": {},
                        "rp": {
                            "id": "localhost"
                        },
                        "credential": {
                            "id": "kSZU6yUPcMlxMai5cK6f8jLEhjYhO_-joVf5kh6YVC0",
                            "response": {
                                "clientDataJSON": "eyJ0eXBlIjoid2ViYXV0aG4uZ2V0IiwiY2hhbGxlbmdlIjoiZ2NGMEFaZU…",
                                "signature": "cXIM_omOQEykW3d66HpNZX6Hed29y60REa5RVXYXHiyCd85FO3O9l94Qui…"
                            }
                        },
                        "challengeToken": "heklYdFkFhb3bY4uuV7Tt7RP9Wph_5LOfZxMKOVtj4A",
                        "trId": "16468923531521971520"
                    }
                    `
                    , 'javascript')
                  ],
                },
                {
                  id: 2,
                  name: "Response Data",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    TableComponent(passkey_table_auth_res_3_2, passkey_columns_auth_res_3_2),
                    CodeComponent(` // Response Data JSON Example
                    {
                          "statusCode": 1200,
                          "result": "{
                              \"credentialHashId\": \"qFrnhwTT3-QrNaRxx5kGIONq8BaE6w6qX8Obg1abFcM\"
                          }"
                    }
                    `
                    , 'javascript'),
                  ],
                },
                {
                  id: 3,
                  name: "Initial Request for Passkey Deregistration",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(`
                    const reqRequestDereg = (reqSiteId, reqSvcId, reqOrigin, reqRpInfo, reqUserInfo, reqAuthnrAttachment,
                       reqProtocolType, svcTrId) => {
                        return new Promise((resolve, reject) => {
                            let reqUrl = {TouchEn OnePass server URL} + “/opf2/rp/getDecommission;
                            let query = {
                                siteId: reqSiteId,
                                svcId: reqSvcId,
                                svcTrId: svcTrId,
                                origin: reqOrigin,
                                user: reqUserInfo,
                                rp: reqRpInfo,
                                reqProtocolType: reqProtocolType
                            };
                            try {
                                $.ajax({
                                    url: reqUrl,
                                    headers: { "Accept": "application/json" },
                                    dataType: "json",
                                    method: "POST",
                                    contentType: "application/json;charset=utf-8",
                                    data: JSON.stringify(query),
                                    success: function(data) {
                                        let reqResultData = {};
                                        if (data.statusCode == "1200") {
                                            reqResultData.statusCode = data.statusCode;
                                            reqResultData.result = JSON.parse(data.result);
                                        } else {
                                            reqResultData.statusCode = data.statusCode;
                                            reqResultData.description = data.description;
                                        }
                                        resolve(reqResultData);
                                    },
                                    error: function(jqXHR, textStatus, errorThrown) {
                                        // Something went wrong. Handle appropriately.
                                    }
                                });
                            } catch (e) {
                                // Something went wrong. Handle appropriately.
                            }
                        });
                    }
                    `
                    , 'javascript'),
                  ],
                },
                {
                  id: 4,
                  name: "Request for Passkey Authenticator Authentication",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(`// requestParams: Deliver the response data for the initial request for 
                    Passkey authentication in parameter
                    const fido2Authenticate = (requestParams) => {
                        return new Promise((resolve, reject) => {
                            let reqResultData = {};
                            try {
                                reqResultData.statusCode = "1200";
                                let paramData = requestParams.result.publicKey;
                                // PublicKeyCredentialCreationOptions
                                let options = {};
                                // required
                                options.challenge = TouchEn OnePass2Util.base64url_decode(paramData.challenge);
                                // optional
                                options.timeout = paramData.timeout;
                                options.rpId = paramData.rpId;
                                options.userVerification = paramData.userVerification;
                                // base64url to byte array
                                if (paramData.allowCredentials != undefined) {
                                    options.allowCredentials = paramData.allowCredentials;
                                    let i = 0;
                                    for (i = 0; i < paramData.allowCredentials.length; i++) {
                                        options.allowCredentials[i].id = decodeBase64Url(paramData.allowCredentials[i].id);
                                    }
                                }
                                // W3C Web API
                                navigator.credentials.get({ "publicKey": options })
                                    .then((result) => {
                                        // PublicKeyCredential
                                        let credential = {};
                                        credential.id = result.id;
                                        credential.type = result.type;
                                        let response = {};
                                        // Encode the response data to base64Url
                                        response.clientDataJSON = encodeBase64url(result.response.clientDataJSON);
                                        response.authenticatorData = encodeBase64url(result.response.authenticatorData);
                                        response.signature = encodeBase64url(result.response.signature);
                                        response.userHandle = encodeBase64url(result.response.userHandle);
                                        credential.response = response;
                                        // Send new credential back to Relying Party for validation and store
                                        reqResultData.result = {};
                                        reqResultData.result.credential = credential;
                                        resolve(reqResultData);
                                    }).catch((err) => {
                                        // Something went wrong. Handle appropriately.
                                    });
                            } catch (ex) {
                                // Something went wrong. Handle appropriately.
                            }
                        });
                    }
                    `
                    , 'javascript'),
                  ],
                },
                {
                  id: 5,
                  name: "Secondary Request for Passkey Deregistration",
                  type: "depth4",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content: [
                    CodeComponent(`
                    const reqResponseDereg = (reqOrigin, reqRpInfo, reqUserInfo, credential, challengeToken, trId) => {
                        return new Promise((resolve, reject) => {
                            let reqUrl = { TouchEn OnePass server URL } + "/opf2/rp/sendDecommission";
                            let query = {
                                origin: reqOrigin,
                                user: reqUserInfo,
                                rp: reqRpInfo,
                                credential: credential,
                                challengeToken: challengeToken,
                                trId: trId
                            };
                            try {
                                $.ajax({
                                    url: reqUrl,
                                    headers: { "Accept": "application/json" },
                                    dataType: "json",
                                    method: "POST",
                                    contentType: "application/json;charset=utf-8",
                                    data: JSON.stringify(query),
                                    success: function(data, textStatus, xhr) {
                                        if (data.statusCode == "1200") {
                                            let reqResultData = {};
                                            reqResultData.statusCode = data.statusCode;
                                            reqResultData.result = data.result;
                                            resolve(reqResultData);
                                        } else {
                                            // Deliver the data to process the error
                                            resolve(data);
                                        }
                                    },
                                    error: function(jqXHR, textStatus, errorThrown) {
                                        // Something went wrong. Handle appropriately
                                    }
                                });
                            } catch (e) {
                                // Something went wrong. Handle appropriately
                            }
                        });
                    }
                    `
                    , 'javascript')
                  ],
                },
              ]      
            },
          ]
        },
      ]
    },
    {
      id: 4,
      name: "Apply Sample",
      isDropdown: true,
      isDropdownOpen: false,
      isActive: false,
      children: [
        {
          id: 1,
          name: "1.	TouchEn OnePass ApplySample",
          isDropdown: true,
          isDropdownOpen: false,
          content: `<p>&nbsp;&nbsp;&nbsp;TouchEn OnePass FreeTrial provides functions and sample source codes for FIDO trial.
          The user applies TouchEn OnePass ApplySample on the actual Service Provider environment to use the FIDO authentication solution.
          The user registers user’s FIDO authentication and authenticates through the registered authenticator.</p>`
        },
        {
          id: 2,
          name: "2.	TouchEn OnePass ApplySample Download",
          isDropdown: true,
          isDropdownOpen: false,
          content: `
          <a href="/downloads/TouchEn OnePass_ApplySample_v1.0.zip" download>
            Download ZIP File
          </a>
          `,
        },
        {
          id: 3,
          name: "3.	How to Apply",
          isDropdown: true,
          isDropdownOpen: false,
          //content: `<img src='https://trial.digitaltrustnetworks.com/img/digitaltrust.png' alt='© 2024 Digital Trust Networks Inc. All rights reserved.' style='max-width: 200px;'>`,
          children: [
            {
              id: 1,
              name: "3-1. Scenario",
              isDropdown: false,
              isDropdownOpen: false,
              content:`<p>
              1.	The user applies for the TouchEn OnePass Free Trial and uses the given information to use the FIDO authentication solution. <br>
              2.	Enter the ID and click the [Login] button. Retrieve the registered FIDO authenticator with the user ID.  <br>
              3.	If there is no registered authenticator, the [Register Page] will show up for authenticator registration.<br>
              4.	Register UAF and Passkey on the Register Page.<br>
              5.	[UAF Register] Use the TouchEn OnePass FreeTrial app to scan the QR code on the screen.<br>
              6.	Register UAF with the preferred authenticator on the TouchEn OnePass FreeTrial app. <br>
              7.	After completing registration on the app, click the [CONFIRM] button the [UAF Register] page. <br>
              8.	The “Success” message will show up. <br>
              9.	After the registration, redirect to the login page. <br>
              10.	If there is the registered authenticator, the [Authentication Page] will show up for authentication with the registered authenticator. <br>
              11.	Authenticate with the registered UAF or Passkey on the Authentication Page.<br>
              12.	[UAF Authentication] – Scan the QR code with the TouchEn OnePass FreeTrial app. <br>
              13.	Authenticate with the authenticator registered by the TouchEn OnePass FreeTrial app. <br>
              14.	Click the [Confirm] button on the [UAF Registration] page. <br>
              15.	The “Success” message will show up. <br>
              16.	After the authentication, redirect to a certain page automatically. <br><br/>
              </p>`,
            },
            {
              id: 2,
              name: "3-2.	Source Code",
              isDropdown: false,
              isDropdownOpen: false,
              children: [
                {
                  id: 1,
                  name: "Initial Configuration",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content : `<p>&nbsp;&nbsp;&nbsp;In order to use TouchEn OnePass ApplySample, you need to apply for TouchEn OnePass FreeTrial. When the Admin confirms your apply, you will receive the information for TouchEn OnePass ApplySample. You use the information to configure parameters and call the right method for authentication. <br>
                  In order to use this service, you may additionally configure the callback function for Service Provider login success or failure. <br>
                  * Required: Base URL, TouchEn OnePass FreeTrial URL(ServiceUrl), Application information (Site ID, Service ID)<br/><br/>
                  </p>`
                },
                {
                  id: 2,
                  name: "How to Configure",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content : `<p>1.	Download the TouchEn OnePass ApplySample file. <br>
                  2.	Unzip the file on the static web source path for the FIDO authentication function. <br>
                  &nbsp;&nbsp;&nbsp;<strong>* ROOT/{specified path}</strong> <br>
                  3.	Delete the password box from the login form. <br>
                  4.	Copy and paste the function below to the javascript file for login. <br>
                  </p>`
                },
                {
                  id: 3,
                  isDropdown: false,
                  isDropdownOpen: false,
                  content : (
                    CodeComponent(`
                    const URL_ROOT = /* Service Provider의 ROOT URL */;
                    /**
                     * Open the TouchEn OnePass ApplySample for login.
                     *
                     * @param {let} serviceUrl – Enter the TouchEn OnePass FreeTrial server address.
                     * @param {let} siteId – After applying for TouchEn OnePass FreeTrial, enter the Site ID in the email from the Admin. 
                     * @param {let} svcId – After applying for TouchEn OnePass FreeTrial, enter the Service ID in the email from the Admin.
                     * @param {let} loginId – Enter the userId from the login form. 
                     * @param {let} returnUrl – After completing login to TouchEn OnePass ApplySample, callback the function URL 
                     */
                    function openUserPortal(serviceUrl, siteId, svcId, loginId, returnUrl) {
                      // Call the TouchEn OnePass ApplySample page pasted to the WEBROOT path.
                      window.open(URL_ROOT + "resources/static/userportal/test.html?"
                      + "serviceUrl=" + serviceUrl
                      + "&siteId=" + siteId
                      + "&svcId=" + svcId
                      + "&loginId=" + loginId
                      + "&returnUrl=" + returnUrl);
                    }
                    `
                    , 'javascript')
                  )
                },
                {
                  id: 4,
                  isDropdown: false,
                  isDropdownOpen: false,
                  content : `<p>
                    5.	Delete the existing function for login and call the function above. Please refer to the parameter for the right configuration. <br>
                    6.	Restart the Service Provider server and proceed the login test. <br><br/>
                  </p>`
                },
                {
                  id: 5,
                  name: "[Example]",
                  isDropdown: false,
                  isDropdownOpen: false,
                  content : (
                    CodeComponent(`
                    const URL_ROOT = window.location.protocol + "//" + window.location.host;
                    function openUserPortal(serviceUrl, siteId, svcId, loginId, returnUrl) {
                      window.open(URL_ROOT + "resources/static/userportal/test.html?"
                      + "serviceUrl=" + serviceUrl
                      + "&siteId=" + siteId
                      + "&svcId=" + svcId
                      + "&loginId=" + loginId
                      + "&returnUrl=" + returnUrl);
                    }

                    function doLogin() {
                      var obj = $("#formIdPw").serializeObject();
                      var returnUrl = URL_ROOT + "demo/generateUserSession.do?userId=" + obj["userId"];

                      openUserPortal("https://trial.digitaltrustnetworks.com", "SITE003", "SVC003", obj["userId"], returnUrl);
                    }
                    `
                    , 'javascript')
                  )
                },
              ],
            },
          ],
        },
      ],
    },
    
    
    
  ] 

