import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// IMAGES
import Logo from "../assets/images/logo/touchEnOnePass.png";
import GnbClose from "../assets/images/svg/gnb-close.svg";
import IconUser from "../assets/images/svg/icon-user.svg";
import IconLock from "../assets/images/svg/icon-lock.svg";
import IconLogin from "../assets/images/svg/icon-login.svg";
import IconPlusBlack from "../assets/images/svg/icon-plus-b.svg";
import IconMinusBlack from "../assets/images/svg/icon-minus-b.svg";

import { DepthMenu } from "../components/DepthMenu";
import { DOCUMENTS_ITEMS } from "../documents/documents";
export const SideMenu = ({ show, close, testAuth, showPassword }) => {
  const navigate = useNavigate();
  const params = useParams();  
  const location = useLocation();
  const [isShow, setIsShow] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [menu, setMenu] = useState([
    {
      name: "Authenticators",
      path: "/authenticators",
      active: false,
    },
    {
      name: "Admin",
      path: "/admin",
      active: false,
    },
    {
      name: "Documentation",
      active: false,
    },
  ]);

  const handleShowPassword = () => {
    showPassword();
    close();
  };

  const goToPage = (item, path) => {
    // navigate(path);
    // const updateMenu = menu.map((item) => ({
    //   ...item,
    //   active: item.path === path,
    // }));
    // setMenu(updateMenu);
    // close();
    if (item.path) {
      navigate(path);
      const updateMenu = menu.map((item) => ({
        ...item,
        active: item.path === path,
      }));
      setMenu(updateMenu);
      close();
    } else {
      console.log("children");
      setIsShow(!isShow);
    }
  };

  const logout = () => {
    sessionStorage.removeItem("member");
    sessionStorage.removeItem("userId");
    setIsAuth(false);
    navigate("/");
    window.location.reload();
  };

  

  const handleItemClick = (item) => {
    navigate(`/documentation/${item.id}`);
    close();
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const updateMenu = menu.map((item) => ({
      ...item,
      active: item.path === currentPath,
    }));
    setMenu(updateMenu);

    if (sessionStorage.getItem("member")) setIsAuth(true);
  }, [location]);

  return (
    <>
      {show && (
        <div id="SideMenu" className={show ? "open" : ""}>
          <div className="container">
            <div className="header">
              <div className="logo">
                <img src={Logo} alt="로고" />
              </div>

              <button className="gnb gnb-close" onClick={close}>
                <img src={GnbClose} alt="닫기" />
              </button>
            </div>

            <div className="body">
              <div className="information">
                <button className="user-info">
                  <i>
                    <img src={IconUser} alt="" />
                  </i>
                  <span>{sessionStorage.getItem("userId")}</span>
                </button>

                <div className="button-group flex-row">
                  <button
                    className="btn-icon color-black"
                    onClick={handleShowPassword}
                  >
                    <i>
                      <img src={IconLock} alt="" />
                    </i>
                    Change PW
                  </button>

                  <button className="btn-icon color-primary" onClick={logout}>
                    <i>
                      <img src={IconLogin} alt="" />
                    </i>
                    Logout
                  </button>
                </div>
              </div>
            </div>

            <span className="line"></span>

            <ul className="menu">
              {menu.map((item, idx) => (
                <li
                  className={item.active ? "active" : ""}
                  onClick={() => goToPage(item, item.path)}
                  key={idx}
                >
                  {item.name}

                  {!item.path &&
                    (isShow ? (
                      <img src={IconMinusBlack} alt="" />
                    ) : (
                      <img src={IconPlusBlack} alt="" />
                    ))}
                </li>
              ))}
            </ul>

            {isShow && (
              <DepthMenu
                items={DOCUMENTS_ITEMS}
                params={params}
                close={close}
                handleItemClick={handleItemClick}
                
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
