import React from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";

export const ModalSubmitAlert = ({ show, close, name }) => {

  const closeModal = () => {
    window.location.reload();
  }
  
  return (
    <div id="DialogSubmitAlert" className={`modal ${show ? "open" : ""}`}>
      <div className="modal-dialog">
        <button className="gnb gnb-close pc-only" onClick={closeModal}>
          <img src={GnbClose} alt="닫기" />
        </button>

        <button className="gnb gnb-close mo-only" onClick={closeModal}>
          <img src={GnbCloseWhite} alt="닫기" />
        </button>

        <div className="modal-dialog-inner">
        <div className="modal-header">
            <h5>Thank you {name}!</h5>
          </div>

          <div className="modal-body">
            <div className="text-group">
              <p>
                Your request has successfully submitted! One of our experts will
                reach you out soon. Thank you.
              </p>
            </div>
          </div>

          <div className="modal-footer">
            <div className="button-group" onClick={closeModal}>
              <button className="btn-lg btn-primary-fill">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
