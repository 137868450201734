import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ONEPASS_ADMIN_URL } from "../config";

// IMAGES
import Logo from "../assets/images/logo/touchEnOnePass.png";
import IconUser from "../assets/images/svg/icon-user.svg";
import IconLock from "../assets/images/svg/icon-lock.svg";
import IconLogin from "../assets/images/svg/icon-login.svg";
import IconLoginPrimary from "../assets/images/svg/icon-login-p.svg";
import IconMenu from "../assets/images/svg/icon-menu.svg";

export const Header = ({ show, isMobile, showSideMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const [menu, setMenu] = useState([
    {
      name: "Authenticators",
      path: "/authenticators",
      active: false,
    },
    {
      name: "Admin",
      path: "/admin",
      active: false,
    },
    {
      name: "Documentation",
      path: "/documentation/1",
      active: false,
    },
  ]);

  const goToPage = (name, path) => {
    if (name === "Admin") {
      openAdmin();
    } else {
      navigate(path);
      const updateMenu = menu.map((item) => ({
        ...item,
        active: item.path === path,
      }));
      setMenu(updateMenu);
    }
  };

  useEffect(() => {
    const header = headerRef.current;

    if (header) {
      setHeaderHeight(header.offsetHeight);
    }

    const handleScroll = () => {
      if (window.scrollY > headerHeight) {
        header.classList.add("fixed");
      } else {
        header.classList.remove("fixed");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerHeight]);

  useEffect(() => {
    const currentPath = location.pathname;
    const documentPattern = /^\/documentation\/\d+$/;
    const updateMenu = menu.map((item) => ({
      ...item,
      active: item.path === currentPath || (item.name === "Documentation" && documentPattern.test(currentPath))
    }));
    setMenu(updateMenu);
  }, [location]);

  const logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const openAdmin = () => {
    window.open(ONEPASS_ADMIN_URL, "_blank");
  };

  return (
    <header ref={headerRef}>
      <div className="header-container">
        <div className="header-left">
          <div className="logo" onClick={() => navigate("/")}>
            <img src={Logo} alt="로고" />
          </div>
        </div>

        <>
          {!isMobile
            ? sessionStorage.length > 0 && (
                <div className="header-center">
                  <ul>
                    {menu.map((item, idx) => (
                      <li
                        className={item.active ? "active" : ""}
                        onClick={() => goToPage(item.name, item.path)}
                        key={idx}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            : null}
        </>

        <div className="header-right">
          {!isMobile
            ? sessionStorage.length > 0 && (
                <>
                  <button className="user-info">
                    <i>
                      <img src={IconUser} alt="" />
                    </i>
                    <span>{sessionStorage.getItem("userId")}</span>
                  </button>

                  <button
                    className="btn-header btn-icon color-black"
                    onClick={show}
                  >
                    <i>
                      <img src={IconLock} alt="" />
                    </i>
                    Change PW
                  </button>
                </>
              )
            : null}

          {sessionStorage.length > 0 ? (
            isMobile ? (
              <button className="gnb" onClick={showSideMenu}>
                <i>
                  <img src={IconMenu} alt="" />
                </i>
              </button>
            ) : (
              <button
                className="btn-header btn-icon color-primary"
                onClick={() => logout()}
              >
                <i>
                  <img src={isMobile ? IconLoginPrimary : IconLogin} alt="" />
                </i>
                Logout
              </button>
            )
          ) : (
            <>
              <button
                className="btn-header btn-icon color-primary"
                onClick={() => navigate("/login")}
              >
                <i>
                  <img src={isMobile ? IconLoginPrimary : IconLogin} alt="" />
                </i>
                Login with registration
              </button>

              {/* <button
                className="btn-header btn-icon color-primary"
                onClick={testAuth}
              >
                Auth
              </button> */}
            </>
          )}
          
        </div>
      </div>
    </header>
  );
};
