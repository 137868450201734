export const admin_table_1_1_1 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'OS',
      description: 'CentOS 7.0 (64bit)',
    },
    {
      key: '2',
      nodeDepth: '2',
      field: 'Language',
      description: 'JAVA (JDK 1.7)',
    },
    {
      key: '3',
      nodeDepth: '3',
      field: 'Browser',
      description: 'Edge, Chrome, FireFox, Opera, WebView',
    },
    {
        key: '4',
        nodeDepth: '4',
        field: 'DBMS',
        description: 'Oracle 11g ↑, MySQL 5.6 ↑, Maria DB 5.5 ↑, DB2 9.7 ↑, Tibero 6.0, MSSQL 2012 ↑, Postgresql 9 ↑',
      }
      
  ];
  
  export const admin_columns_1_1_1 = [
    {
      title: 'Category',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Details',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const admin_table_1_1_1_2 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'Hardware',
      description: 'CPU : 2CPU, 4Core ↑, Memory : 32GB ↑, Disk : 1TB (RAID1) ↑',
    },

  ];
  
  export const admin_columns_1_1_1_2 = [
    {
      title: 'Category',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Details',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const admin_table_1_2 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'Administrator (Admin)',
      description: `Administrator is in charge of managing all service policies. Admin has an access via webUI management console to manage service.
      - Super User: It is generated when install the product 
      - Sub-Admin(site/task): Route Admin generates Sub-admin. Sub-admin manages services according to set authority.`,
    },
      {
          key: '2',
          nodeDepth: '2',
          field: 'Authenticator',
          description: 'Authentication device KeyPair generation / Verification API / Signature'
      },
      {
        key: '3',
        nodeDepth: '3',
        field: 'OnePass Server',
        description: 'A server for service registration, deregistration and authentication through user’s authentication'
    },
    {
        key: '4',
        nodeDepth: '4',
        field: 'OnePass App',
        description: 'An application installed in a smartphone to perform FIDO UAF protocol functions (registration, deregistration, authentication)'
    },
    {
        key: '5',
        nodeDepth: '5',
        field: 'APP ID',
        description: 'Application identifier'
    },
    {
        key: '6',
        nodeDepth: '6',
        field: 'Authentication Device ID',
        description:'Authenticator Attestation Id\nThe Unique ID information of authenticator given by mobile device maker.\n(‘makerID#modelcode’)',
    },
    {
        key: '7',
        nodeDepth: '7',
        field: 'Valid Scope',
        description: 'A valid scope for the actual data to be entered via interface\n(If the scope is null, it shows ‘-‘.)\n* If you enter letters, consider them as bytes. If you enter numbers, consider them as they are.',
    },
  ];
  
  export const admin_columns_1_2 = [
    {
      title: 'Term',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const admin_table_2_1 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'Statistics',
      description: `Statistics and data on registration information and transaction history`,
    },
      {
          key: '2',
          nodeDepth: '2',
          field: 'Site Policy',
          description: 'Policy for each SITE and SERVICE'
      },
      {
        key: '3',
        nodeDepth: '3',
        field: 'Registration Information',
        description: 'Inquire the registered user, device and authenticator information '
    },
    {
        key: '4',
        nodeDepth: '4',
        field: 'Authenticator Information',
        description: 'META management function for the authenticator'
    },
    {
        key: '5',
        nodeDepth: '5',
        field: 'Log and Status',
        description: 'Log on Admin and transaction history'
    },
    {
        key: '6',
        nodeDepth: '6',
        field: 'Account and Authority',
        description:'Create an Admin and allocate authority',
    }
  ];
  
  export const admin_columns_2_1 = [
    {
      title: 'Menu',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  export const admin_table_2_2_1 = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'USER ID',
        type: 'Enter the Admin ID',
        definition: '-',
        description: `5 ~ 20`,
      },
        {
            key: '2',
            nodeDepth: '2',
            field: 'PASSWORD',
            type: 'Enter the Admin Password',
            definition: '-',
            description: '-'
        },

  ];
  
  export const admin_columns_2_2_1 = [
      {
          title: 'Item',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Details',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Default',
          dataIndex: 'definition',
          key: 'definition',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_2_2 = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Capital letter',
        type: 'A ~ Z',
        description: `26`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'Small letter',
        type: 'a ~ z',
        description: `26`,
      },
      {
        key: '3',
        nodeDepth: '3',
        field: 'Number',
        type: '1 ~ 0',
        description: `10`,
      },
      {
        key: '4',
        nodeDepth: '4',
        field: 'Special letter',
        type: '`~!@#$%^&*()_+-=[]\;’,./{}|:”<>?',
        description: `32`,
      },

  ];
  
  export const admin_columns_2_2_2 = [
      {
          title: 'Type',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Text',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Number',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_1_reg = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Valid time',
        type: '300',
        description: `10 ~ 3600`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'Performance procedure',
        type: '1.Initialize device information (all users)\n2. Initialize authenticator (device-user)\n3. Initialize authenticator (user)\n4. Registration',
        description: 'Initialize device information (all users) / Initialize authenticator (device-user) / Initialize authenticator (user) /  Registration',
      },
      
      {
        key: '3',
        nodeDepth: '3',
        field: 'Whether to send device ID',
        type: 'Not use',
        description: `Use / Not use`,
      },
      {
        key: '4',
        nodeDepth: '4',
        field: 'Additional response data information',
        type: 'None',
        description: `None / Transfer authenticator info`,
      },
      {
        key: '5',
        nodeDepth: '5',
        field: 'Whether to save detail info',
        type: 'Not use',
        description: `Use / Not use`,
      },
  ];
  
  export const admin_columns_2_3_1_1_reg = [
      {
          title: 'Item',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Default',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_1_dereg = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Expiration time',
        type: '90',
        description: `10 ~ 3600`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'Execution procedure',
        type: 'Deregister',
        description: 'Deregister / Authenticate',
      },
      
      {
        key: '3',
        nodeDepth: '3',
        field: 'Device ID delivery status',
        type: 'Not use',
        description: `Use / Not use`,
      },
      {
        key: '4',
        nodeDepth: '4',
        field: 'Detail information save status',
        type: 'Not use',
        description: `Use / Not use`,
      },
  ];
  
  export const admin_columns_2_3_1_1_dereg = [
      {
          title: 'Item',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Default',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_1_primary = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Expiration time',
        type: '90',
        description: `10 ~ 3600`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'Execution procedure',
        type: 'Authenticate',
        description: 'Deregister / Authenticate',
      },
      
      {
        key: '3',
        nodeDepth: '3',
        field: 'Device ID delivery status',
        type: 'Not use',
        description: `Use / Not use`,
      },
      {
        key: '4',
        nodeDepth: '4',
        field: 'Detail information save status',
        type: 'Not use',
        description: `Use / Not use`,
      },
      {
        key: '5',
        nodeDepth: '5',
        field: 'TC image information save status',
        type: 'Not use',
        description: `Use / Not use`,
      },
  ];
  
  export const admin_columns_2_3_1_1_primary = [
      {
          title: 'Item',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Default',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_1_secondary = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Expiration time',
        type: '300',
        description: `10 ~ 3600`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'Execution procedure',
        type: 'Authenticate',
        description: '-',
      },
      
      {
        key: '3',
        nodeDepth: '3',
        field: 'Device ID delivery status',
        type: 'Not use',
        description: `Use / Not use`,
      },
      {
        key: '4',
        nodeDepth: '4',
        field: 'Detail information save status',
        type: 'Not use',
        description: `Use / Not use`,
      },
      {
        key: '5',
        nodeDepth: '5',
        field: 'TC image information save status',
        type: 'Not use',
        description: `Use / Not use`,
      },
  ];
  
  export const admin_columns_2_3_1_1_secondary = [
      {
          title: 'Item',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Default',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];

  export const admin_table_2_3_1_1_lock = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Add authenticator',
        type: 'Click the [+Authentication Device] to select an authenticator ',
        definition: 'None',
        description: ``,
      },
        {
            key: '2',
            nodeDepth: '2',
            field: 'Expiration date',
            type: 'Select the expiration date when add authenticators',
            definition: '1 year',
            description: `1 year / 10 year / 2 year / 3 year / 4 year / 5 year /unlimited`
        },
        {
          key: '3',
          nodeDepth: '3',
          field: 'Restriction if signature verification fails',
          type: [
            "Set whether to allow authentication or not if when the signature verification of authenticator with a public key in the server fails.",
            "- Unused: not restrict to authentication using authenticators.",
            "- Used: restrict to authentication trial using authenticators. If the number of signature verification failures exceeds the range or signature verification fails, authentication attempt control is set up depending on processing methods (Ignore, Lock, Lock for a certain time, Lock for a weighted time)",
          ],
        
          definition: 'Not use',
          description: `Use / Not use`
      },

  ];
  
  export const admin_columns_2_3_1_1_lock = [
      {
          title: 'Menu',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Details',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Default',
          dataIndex: 'definition',
          key: 'definition',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_1_allow = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Scope',
        type: 'Select scope (task only or task integration)',
        definition: 'Task only',
        description: `Task only / Task integration`,
      },
        {
            key: '2',
            nodeDepth: '2',
            field: 'APP ID',
            type: 'Enter the hash value of certification when you select task only or task integration',
            definition: '-',
            description: `-`
        },
        {
          key: '3',
          nodeDepth: '3',
          field: 'Package name',
          type: "Enter the package name of certification when you select task only or task integration",
          definition: '-',
          description: `-`
      },

  ];
  
  export const admin_columns_2_3_1_1_allow = [
      {
          title: 'Menu',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Details',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Default',
          dataIndex: 'definition',
          key: 'definition',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_2 = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Expiration time',
        type: '300',
        description: `10 ~ 3600`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'Execution procedure',
        type: '1. Initialize authenticator (user) 2. Register',
        description: `Initialize authenticator (authenticator) / Initialize authenticator (user) / Register`,
      },
      {
        key: '3',
        nodeDepth: '3',
        field: 'Detail information save status',
        type: 'Not use',
        description: `Use / Not use`,
      },


  ];
  
  export const admin_columns_2_3_1_2 = [
      {
          title: 'Item',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Default',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Vaild Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_2_dereg = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Expiration time',
        type: '90',
        description: `10 ~ 3600`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'Execution procedure',
        type: 'Deregister (execute authentication)',
        description: 'Deregister (execute authentication)',
      },
      {
        key: '3',
        nodeDepth: '3',
        field: 'Detail information save status',
        type: 'Not use',
        description: `Use / Not use`,
      },
  ];
  
  export const admin_columns_2_3_1_2_dereg = [
      {
          title: 'Item',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Default',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Vaild Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_2_primary = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Expiration time',
        type: '90',
        description: `10 ~ 3600`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'Execution procedure',
        type: 'Authenticate',
        description: 'Authenticate',
      },
      {
        key: '3',
        nodeDepth: '3',
        field: 'Detail information save status',
        type: 'Not use',
        description: `Use / Not use`,
      },
  ];
  
  export const admin_columns_2_3_1_2_primary = [
      {
          title: 'Item',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Default',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Vaild Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_2_allow = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Domain',
        type: 'Enter domain to be connected with OnePass Server',
        definition: '-',
        description: `Except ;<>%” `,
      },
        {
            key: '2',
            nodeDepth: '2',
            field: 'Service name',
            type: 'Enter service name for the domain',
            definition: '-',
            description: `-`
        },

  ];
  
  export const admin_columns_2_3_1_2_allow = [
      {
          title: 'Menu',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Details',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Default',
          dataIndex: 'definition',
          key: 'definition',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_2_option = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'Limit time for registration response',
      type: 'Set the limit time for response when registering authenticator',
      definition: '30000',
      description: `20000~60000`,
    },
    {
      key: '2',
      nodeDepth: '2',
      field: 'Limit time for authentication response',
      type: 'Set the limit time for response when authenticating authenticator',
      definition: '30000',
      description: `20000~60000`,
    },
    {
      key: '3',
      nodeDepth: '3',
      field: 'Authentication device connection method',
      type: 'Select authenticator connection method',
      definition: 'Platform & Cross platform',
      description: `Platform / Cross platform / Platform & Cross platform`,
    },
    {
      key: '4',
      nodeDepth: '4',
      field: 'Authenticator public key authentication information save status',
      type: 'Select whether to save authenticator public key authentication information',
      definition: 'false',
      description: `true/false`,
    },
    {
      key: '5',
      nodeDepth: '5',
      field: 'User confirmation type',
      type: 'Select user confirmation type',
      definition: 'Biometric / Non-biometric',
      description: `Biometric / Non-biometric / Biometric & Non-biometric`,
    },
  ];
  export const admin_columns_2_3_1_2_option = [
      {
          title: 'Menu',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Details',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Default',
          dataIndex: 'definition',
          key: 'definition',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_3 = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'Signature verification retry count',
      type: 'Set signature verification retry count',
      definition: '10',
      description: `1 ~ 100`,
    },
    {
      key: '2',
      nodeDepth: '2',
      field: 'Processing method of signature verification failure',
      type: 'Select how to handle if signature verification failed (Ignore / Lock / Temporary Lock)',
      definition: 'Ignore',
      description: `Ignore / Lock / Temporary Lock / Lock for a weighted time`,
    },
    {
      key: '3',
      nodeDepth: '3',
      field: 'Local verification retry count',
      type: 'Set local verification retry count',
      definition: '10',
      description: `1 ~ 100`,
    },
    {
      key: '4',
      nodeDepth: '4',
      field: 'Processing method of local verification failure',
      type: 'Select how to handle if local verification failed (Ignore / Lock / Temporary Lock)',
      definition: 'Ignore',
      description: `Ignore / Lock / Temporary Lock / Lock for a weighted time`,
    },
  ];
  export const admin_columns_2_3_1_3 = [
      {
          title: 'Menu',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Details',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Default',
          dataIndex: 'definition',
          key: 'definition',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_3_lock = [
    {
        key: '1',
        nodeDepth: '1',
        field: 'User',
        type: 'Unlock user',
        description: `Unlock all the devices of registered user`,
      },
      {
        key: '2',
        nodeDepth: '2',
        field: 'User',
        type: 'Keep locking',
        description: 'Unable to unlock',
      },
  ];
  
  export const admin_columns_2_3_1_3_lock = [
      {
          title: 'Target',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'How to unlock when registering authenticator',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
      },
  ];
  export const admin_table_2_3_1_3_auth = [
    {
      key: '1',
      nodeDepth: '1',
      field: 'Registration',
      type: 'Select Allow / Not Allowed depending on  damage types (User verification skip, Device KEY damage, Authentication KEY damage, KEY protection damage, Authenticator discarded) when registering',
      definition: '-',
      description: `Allow / Not Allowed`,
    },
    {
      key: '2',
      nodeDepth: '2',
      field: 'Primary Authentication',
      type: 'Select Allow / Not Allowed depending on  damage types (User verification skip, Device KEY damage, Authentication KEY damage, KEY protection damage, Authenticator discarded) when doing Primary Authentication (log-in)',
      definition: '-',
      description: `Allow / Not Allowed`,
    },
  ];
  export const admin_columns_2_3_1_3_auth = [
      {
          title: 'Menu',
          dataIndex: 'field',
          key: 'field',
      },
      {
          title: 'Details',
          dataIndex: 'type',
          key: 'type',
      },
      {
          title: 'Default',
          dataIndex: 'definition',
          key: 'definition',
      },
      {
          title: 'Valid Scope',
          dataIndex: 'description',
          key: 'description',
      },
  ];

  export const admin_columns_4_1_1_5 = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
    },
  ]

  export const admin_table_4_1_1_5 = [
    {
      key: '1',
      item: 'Registration date',
      details: 'Select search date. Provide the data of the day (basic)',
    },
    {
      key: '2',
      item: 'User ID',
      details: 'Inquire user information with user ID regardless of the date\
              (When search user ID, registration date condition is ignored.)',
    },
    {
      key: '3',
      item: 'Non-user lock inquiry',
      details: 'Inquire the lock status data among non-users',
    },
  ]

  export const admin_table_4_1_2_2 = [
    {
      key: '1',
      item: 'Registration date',
      details: 'Select search date. The data of the day is provided.',
    },
    {
      key: '2',
      item: 'User ID',
      details: 'Inquire user information with user ID regardless of the date',
    },
  ]

  export const admin_table_4_2_2 = [
    {
      key: '1',
      item: 'Period',
      details: 'Set the search period. The data of the day is provided.',
    },
    {
      key: '2',
      item: 'Admin ID',
      details: 'Enter Admin ID.',
    },
    {
      key: '3',
      item: 'Log type',
      details: 'Select the log type (Connection management, Account management, Site \
                management, Policy management, Registration information, Authentication \
                device information, Version management, System management)',
    },
    {
      key: '4',
      item: 'Action',
      details: 'Select the action according to the log type.\
              - Connection management: All, Log-in, Log-out\
              - Account management: All, Register account, Edit account, Delete account, Change password, Change account status, Register Admin type, Edit Admin type, Delete Admin type\
              - Site management: All, Register site, Edit site, Delete site, Register task, Edit task, Delete task, Check task\
              - Policy management: Register policy, Edit policy, Delete policy, Allocate policy, Revoke policy\
              - Registration information: Lock, Unlock, Lock all, Unlock all, Delete device information, Delete certification\
              - Authentication device information: Register device information, Edit device information, Delete device information, Edit site device information\
              - Version management: Register app version, Edit app version, Delete app version, Register library version, Edit library version, Delete library version\
              - System management: Register PUSH information, Edit PUSH information, Delete PUSH information\
              ',
    },
  ]

  export const admin_table_4_2_3_2 = [
    {
      key: '1',
      item: 'Period',
      details: 'Set the search period. The data of the day is provided.',
    },
    {
      key: '2',
      item: 'User ID',
      details: 'Inquire the transaction history with user ID regardless of the date',
    },
    {
      key: '3',
      item: 'Service transaction ID',
      details: 'Inquire the transaction history with the service transaction ID regardless of the date ',
    },
    {
      key: '4',
      item: 'transaction ID',
      details: 'Inquire the transaction history with transaction ID regardless of the date ',
    },
    {
      key: '5',
      item: 'Category',
      details: 'Select transaction type (All, Registration, Deregistration, Primary Authentication, Secondary Authentication)',
    },
    {
      key: '6',
      item: 'Authentication',
      details: 'Select an authentication (All, Presence, All, Pattern, Faceprint, Fingerprint, Handprint, Location, Passcode, None, Eyeprint, Voiceprint) you used for transaction',
    },
  ]

  export const admin_table_5_1_1_1 = [
    {
      item: 'Admin type',
      details: 'Enter Admin type',
      default: '-',
      scope: '5 ~ 30',
    },
    {
      item: 'Type description',
      details: 'Enter description about the type',
      default: '-',
      scope: '0 ~ 150',
    },
    {
      item: 'Menu authority',
      details: 'Select R/W for each menu',
      default: '-',
      scope: '-',
    },
    {
      item: 'Log-in policy-Password length',
      details: 'Set the length of password',
      default: '8',
      scope: 'Log-in policy',
    },
    {
      item: 'Log-in policy-Password complexity',
      details: `Select the combination of password. 
      - no limit
      - numbers + letters
      - numbers + numbers + special letters
      - 3 out of small letters, Capital letters, numbers and special letters
      `,
      default: 'Numbers + letters + special letters',
      scope: '-',
    },
    {
      item: 'Log-in policy-The allowed number of log-in failures',
      details: `Enter the allowed number of log-in failures`,
      default: '5',
      scope: '3 ~ 20',
    },
    {
      item: 'Log-in policy-Processing method if the number exceeds',
      details: `Select the processing method (lock account, lock for a certain period of time) if the number of log-in failures exceeds the allowable range`,
      default: 'Lock account',
      scope: '-',
    },
    {
      item: 'Log-in policy-Password change period (days)',
      details: `Enter the change period of password`,
      default: '90',
      scope: '10 ~ 900',
    },
    {
      item: 'Log-in policy-Session maintenance time (second)',
      details: `Enter the session maintenance time`,
      default: '300',
      scope: '120 ~ 259200',
    },
    {
      item: 'Log-in policy-Duplicate log-in account',
      details: `Select whether to allow or not duplicate log-in`,
      default: 'Not allowed',
      scope: '-',
    },
    {
      item: 'Log-in policy-Limit reusing old passwords ',
      details: `Enter the number not to use the same passwords for the next`,
      default: '2',
      scope: '1~30',
    },
  ];
  
  export const admin_columns_5_1_1_1 = [
      {
          title: 'Item',
          dataIndex: 'item',
          key: 'item',
      },
      {
          title: 'Details',
          dataIndex: 'details',
          key: 'details',
      },
      {
          title: 'Default',
          dataIndex: 'default',
          key: 'default',
      },
      {
          title: 'Scope',
          dataIndex: 'scope',
          key: 'scope',
      },
  ];

  export const admin_table_5_2_1_1 = [
  {
    item: 'Admin Id',
    details: 'Enter Admin ID',
    default: '-',
    scope: '5 ~ 20',
  },
  {
    item: 'Admin name',
    details: 'Enter Admin name',
    default: '-',
    scope: '2 ~ 20',
  },
  {
    item: 'Email',
    details: 'Enter Admin’s email<br>(ex. raon@raonsecure.com)',
    default: '-',
    scope: '5 ~ 30',
  },
  {
    item: 'Phone number',
    details: 'Enter Admin’s phone number<br>(ex. 010-1234-5678)',
    default: '-',
    scope: '1 ~ 14',
  },
  {
    item: 'Account status',
    details: `Select the status of new Admin (Active / Inactive)`,
    default: '-',
    scope: '-',
  },
  {
    item: 'Admin type*',
    details: `Select Admin type
    Generate Admin type from [Account and Authority]>[Authority Management]
    `,
    default: '-',
    scope: '-',
  },
  {
    item: 'Expiration date',
    details: `Set the expiration date of account`,
    default: '-',
    scope: '-',
  },
  {
    item: 'Password*',
    details: `Enter Admin password`,
    default: '-',
    scope: '1 ~ 20',
  },
  {
    item: 'Password confirm*',
    details: `Reenter Admin password`,
    default: '-',
    scope: '1 ~ 20',
  },
  {
    item: 'Designate access IP',
    details: `Select whether to designate IP or not (check / not check)`,
    default: '-',
    scope: '-',
  },
  {
    item: 'Starting range IP',
    details: `Enter starting IP address when checking access IP designation`,
    default: '-',
    scope: '1 ~ 12',
  },
  {
    item: 'End range IP',
    details: `Enter end IP address when checking access IP designation`,
    default: '-',
    scope: '1 ~ 12',
  },
  ];

  export const admin_columns_5_2_1_1 = [
    {
        title: 'Item',
        dataIndex: 'item',
        key: 'item',
    },
    {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
    },
    {
        title: 'Default',
        dataIndex: 'default',
        key: 'default',
    },
    {
        title: 'Scope',
        dataIndex: 'scope',
        key: 'scope',
    },
  ];