import {OS_VERSION} from "../config";

export function hexToByteString(hex) {
    let byteString = '';
    for (let i = 0; i < hex.length; i += 2) {
      byteString += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    return byteString;
  };


  export function getOsType() {
    var platform = navigator.platform.toLowerCase();

    if (platform.indexOf('win') > -1) {
      return OS_VERSION.OS_WINDOW;
    } else if (platform.indexOf('mac') > -1) {
      if (platform.indexOf('macintel') > -1 && navigator.maxTouchPoints > 1) {
        return OS_VERSION.OS_IOS;
      } else {
        return OS_VERSION.OS_MAC;
      }
    } else {
      var userAgent = navigator.userAgent.toLowerCase();
      
      if ( userAgent.indexOf('android') > -1) {
        return OS_VERSION.OS_ANDROID;
      } else if ( userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1 || userAgent.indexOf("ipod") > -1) {
        return OS_VERSION.OS_IOS;
      } else {
        return OS_VERSION.OS_ETC;
      }
    }
  };