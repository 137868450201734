import React from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";

export const ModalPrivacyPolicy = ({ show, close }) => {
  const url =
    "https://www.digitaltrustnetworks.com/en/policy/privacy";


  return (
    <div id="DialogTerms" className={`modal ${show ? "open" : ""}`}>
      <div className="modal-dialog">
        <button className="gnb gnb-close pc-only" onClick={close}>
          <img src={GnbClose} alt="닫기" />
        </button>

        <button className="gnb gnb-close mo-only" onClick={close}>
          <img src={GnbCloseWhite} alt="닫기" />
        </button>

        <div className="modal-dialog-inner">
          <div className="modal-header">
            <h5>Privacy Policy</h5>
          </div>

          <div className="modal-body">
            <iframe src={url} title="Terms of Service" frameBorder="0"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
