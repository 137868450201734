import React, { createElement, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";
import ImgIconEmail from "../assets/images/popup/img-icon-email.svg";
import ImgIconLock from "../assets/images/popup/img-icon-lock.svg";
import ImgIconAdd from "../assets/images/popup/img-icon-add.svg";
import ImgIconBioface from "../assets/images/popup/img-icon-bioface.svg";
import IconCheck from "../assets/images/svg/icon-check.svg";
import ArrowRightWhite from "../assets/images/svg/arrow-right-w.svg";
import OnepassFido2 from "../js/OnepassFido2";
import $ from "jquery";
import {DEMO_SITE_ID, DEMO_SVC_ID } from "../config";
import { API } from "../config";

export const ModalPasskeySign = ({ show, close, openSubmitAlert, isCallNextStep }) => {
  let [currentStep, setCurrentStep] = useState(1);


  // 이메일 검증 로직 
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [otpValid, setOtpValid] = useState(false);
  const [notAllow, setNotAllow] = useState(true);
  const [otp, setOtp] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [expired, setExpired] = useState(false);

  // 인증장치 타이머 
  const [clock, setClock] = useState(null);

  // 이메일 유효성 검사
  useEffect(() => {
    if (emailValid) {
      setNotAllow(false);
      return;
    }
    setNotAllow(true);
  }, [emailValid]);

  useEffect(() => {
    if (otpValid) {
      setNotAllow(false);
      return;
    }
    setNotAllow(true);
  }, [otpValid]);

  useEffect(() => {
    setClock(null);
  }, [currentStep]);

  useEffect(() => {
    if (clock !== null && clock === 0) {
      setNotAllow(true);
    }
  }, [clock]);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const regex =
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (regex.test(e.target.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  // OTP 유효성 검사 
  const handleOtp = (e) => {
    setOtp(e.target.value);
    const regex = /^\d{6}$/;
    if(regex.test(e.target.value)){
     setOtpValid(true);
    } else {
      setOtpValid(false);
    }
 };

  const handleNext = () => {
    if(currentStep === 1){
      callStep1API();  // step 1 : 메일 전송 
      setIsComplete(false); // Passkey demo 재시도 시 Success 모달창 노출을 위한 필요
    } else if(currentStep === 2){
      callStep2API(); // step 2 : otp 검증 
    }
  };

  useEffect(() => {
    if (currentStep === 2) {
      setNotAllow(true);
    } else if (currentStep === 3) { // step 3: 등록 API 호출
      callStep3API();
    } else if (currentStep === 4) { // step 4: 인증 API 호출 
      callStep4API();
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 2 && clock === null) {
      setClock(300); // 5 Minutes
    } else if (currentStep === 3 || currentStep === 4) {
      setClock(90); 
    }
  }, [currentStep]);

  useEffect(() => {
    let timerId;
    if (clock !== null && clock > 0) {
      timerId = setInterval(() => {
        setClock((prevClock) => prevClock - 1);
      }, 1000);
      setExpired(false);
    } else if (clock === 0) {
      clearInterval(timerId);
      setExpired(true);
      // setCurrentStep((prevStep) => prevStep - 1);
    }
    return () => clearInterval(timerId);
  }, [clock]);

  // 타이머 시간 형식 변환 함수
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds
      }`;
  };

  // STEP 1 : 메일 전송
  const callStep1API = async () => {
    fetch(`${API.DEMO_REQUEST}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email })
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        console.log(response);
        return response.json();
      })
      .then(data => {
        console.log(data);
        if (data.resultCode === "000") {
          setRequestId(data.resultData.reqId);
          setCurrentStep(currentStep + 1);
        } else if(data.resultCode === "200"){
          openSubmitAlert("EMAIL SEND FAIL", "[" + data.resultCode + "] " + data.resultMsg);
        } else if(data.resultCode === "201"){
          openSubmitAlert("CONNECTED_TIMED_OUT", "[" + data.resultCode + "] " + data.resultMsg);
        } else if(data.resultCode === "202"){
          openSubmitAlert("MAIL_SERVER_CONNECTION_FAILED", "[" + data.resultCode + "] " + data.resultMsg);
        } else {
          console.log(data);
          openSubmitAlert("FAIL", "[" + data.resultCode + "] " + data.resultMsg);
        }
      })
      .catch((error) => {
        console.error('Error Fetching data : ', error);
        openSubmitAlert("FAIL", `Error Fetching data : ${error}`);
      });
  };

 // STEP 3 : OTP 검증 
  const callStep2API = () => {  
    fetch(`${API.DEMO_CONFIRM}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ otp: otp, id: requestId })
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        console.log(response);
        return response.json();
      })
      .then(data => {
        console.log(data);
        if(data.resultCode === "000"){
          setCurrentStep(currentStep + 1);
        } else {
          openSubmitAlert("FAIL", "Verification of the OTP you entered failed.");
        }
      })
      .catch((error) => {
        console.error('Error Fetching data : ', error);
        openSubmitAlert("FAIL", `Error Fetching data : ${error}`);
      });
  }

  // STEP 3 : Passkey 등록 API
  const callStep3API = async () => {
    const reqParams = {
        "op": "reg"
      , "siteId": DEMO_SITE_ID
      , "svcId": DEMO_SVC_ID
      , "userNm": email
      , "authnrAttachment": "platform"
      , "credentialAlias": "test"
    }
    console.log("reqParams : ", reqParams);
    const result = OnepassFido2.requestService(reqParams, regCallBackFunction);
  }

  // 등록 및 인증 수행 결과
  const regCallBackFunction = (result) => {
    if ("1200" == result.statusCode && currentStep === 3) {
      openSubmitAlert("Passkey Created!", "Authenticate with the Passkey.", true);
    } else if ("1200" == result.statusCode && currentStep === 4) {
      setIsComplete(true);
      closeModal();
    } else {
      openSubmitAlert("FAIL", "Registration has not been completed.");
    }
  };
  // Passkey demo 창이면서 OK 버튼이 눌렸을 때 callStep4API 호출  
  useEffect(() => {
    if(isCallNextStep) {
      setCurrentStep(currentStep + 1);
    } 
  }, [isCallNextStep]);

  // STEP 4 : Passkey 인증 API
  const callStep4API = async () => {
    const reqParams = {
        "op": "auth"
      , "siteId": DEMO_SITE_ID
      , "svcId": DEMO_SVC_ID
      , "userNm": email
      , "authnrAttachment": "platform"
      , "credentialAlias": "test"
    };
    console.log("reqParams : ", reqParams)
    const result = OnepassFido2.requestService(reqParams, regCallBackFunction);
  };

  const handlePrev = () => {
    if(currentStep === 3){
      callStep3API();
      setClock(90);
    } else if (currentStep === 4) {
      callStep4API();
      setClock(90);

    } else {
      setCurrentStep(currentStep - 1);
      setOtp("");
      setEmail('');
      setEmailValid(false);
    }
  };

  const closeModal = () => {
    close();
    setCurrentStep(1);
    setEmail('');
    setOtp('');
    setEmailValid(false);
  };

  useEffect(() => {
    if(isComplete) {
      $("#DialogSuccess").addClass("open");
    } else {
      $("#DialogSuccess").removeClass("open");
    }
  }, [isComplete]);

  const modalStepIndicator = [
    {
      step: 1,
      active: true,
      success: false,
    },
    {
      step: 2,
      active: false,
      success: false,
    },
    {
      step: 3,
      active: false,
      success: false,
    },
    {
      step: 4,
      active: false,
      success: false,
    },
  ];

  const modalContext = [
    {
      step: 1,
      context01: "Get Email authentication code",
      context02: "Enter an Email address to get the authentication code.",
      img: ImgIconEmail,
      input: createElement("input", {
        type: "email",
        placeholder: "Enter your email address",
        name: "email",
        value: email,
        onChange: handleEmail
      }),
      errorMessage: "Invalid Email address",
      button: "Enter the code",
    },
    {
      step: 2,
      context01: "Enter the authentication code",
      context02: "We sent the code to your email",
      userEmail: email,
      img: ImgIconEmail,
      input: createElement("input", {
        type: "number",
        placeholder: "Enter the 6-digit code",
        name: "otp",
        value: otp,
        onChange: handleOtp
      }),
      errorMessage: "Invalid authentication code",
      timer: formatTime(clock),
      button: "Authenticate",
      link: "Re-enter Email",
    },
    {
      step: 3,
      context01: "Add new Authenticator",
      context02:
        "After adding a new Authenticator, push the button below to authenticate.",
      img: ImgIconAdd,
      timer: formatTime(clock),
      button: "Authenticate",
      link: "Re-register Authenticator",
    },
    {
      step: 4,
      context01: "Authenticate",
      context02: "Proceed with authentication",
      img: ImgIconBioface,
      timer: formatTime(clock),
      link: "Re-authenticate Authenticator",
    },
  ];

  return (
    <div id="DialogPasskeySign" className={`modal ${show ? "open" : ""}`}>
      {modalContext.map(
        (modal) =>
          modal.step === currentStep && (
            <div className="modal-dialog" key={modal.step}>
              <button className="gnb gnb-close pc-only" onClick={closeModal}>
                <img src={GnbClose} alt="닫기" />
              </button>

              <button className="gnb gnb-close mo-only" onClick={closeModal}>
                <img src={GnbCloseWhite} alt="닫기" />
              </button>

              <div className="modal-dialog-inner">
                <div className="modal-header">
                  <h5>TouchEn OnePass Passkey</h5>
                </div>

                <span className="line"></span>

                <div className="modal-body">
                  <div className="steps">
                    <ul>
                      {modalStepIndicator.map((step) => (
                        // 스탭이 넘어갈때 className 변경
                        <li
                          className={
                            modal.step > step.step
                              ? "success"
                              : modal.step === step.step
                                ? "active"
                                : ""
                          }
                          key={step.step}
                        >
                          {modal.step > step.step ? (
                            // 스탭이 지나가면 IconCheck, 스텝이 맞으면 숫자
                            <i>
                              <img src={IconCheck} alt="" />
                            </i>
                          ) : (
                            `0${step.step}`
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="img-item">
                    <img
                      src={modal.img}
                      alt="스텝이미지01"
                      className="step-img"
                    />
                  </div>

                  <div className="text-group">
                    <p>{modal.context01}</p>
                    <span>{modal.context02}</span>
                  </div>

                  {modal.userEmail && (
                    <p className="user-email">{modal.userEmail}</p>
                  )}

                  {modal.input && (
                    <div className="input-group">
                      <div className="input-item error">
                        <div className="input">
                          {modal.input}

                          <span className="focus-border"></span>

                          {modal.timer && (
                            <span className="timer color-error">
                              {modal.timer}
                            </span>
                          )}
                        </div>
                        {!emailValid && email.length > 0 &&( 
                          <span className="error-message">
                            {modal.errorMessage}
                          </span>
                        )}
                        {!otpValid && otp.length > 0 &&( 
                          <span className="error-message">
                            {modal.errorMessage}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {!modal.input && modal.timer ? (
                    <span className="timer color-error">{formatTime(clock)}</span>

                  ) : (
                    ""
                  )}
                </div>

                <div className="modal-footer">
                  <div className="button-group flex-col">
                    {modal.button && (
                      <button
                        className="btn-lg btn-primary-fill btn-icon" disabled={notAllow || (currentStep === 2 && expired)}
                        onClick={handleNext}
                      >
                        {modal.button}
                        <i>
                          <img src={ArrowRightWhite} alt="ArrowRightWhite" />
                        </i>
                      </button>
                    )}

                    {modal.link && (
                      <Link onClick={handlePrev}>{modal.link}</Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

