import React, { useEffect, useState, useContext } from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";
import ImgIconCheck from "../assets/images/popup/img-icon-check.svg";
import ImgIconQR from "../assets/images/popup/img-icon-qr.svg";
import ImgIconAdd from "../assets/images/popup/img-icon-add.svg";
import {OS_VERSION, AAID} from "../config"
import { BASE_URL, API } from "../config";
import {hexToByteString, getOsType} from "../js/util"

export const ModalFidoDelete = ({ show, close, param, isMobile, openSubmitAlert }) => {

  const [qrImg, setQrImg] = useState('');
  const [clock, setClock] = useState(null);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isQrExpire, setIsQrExpire] = useState(false);
  let [currentStep, setCurrentStep] = useState(1);
  const [appStoreUrl, setAppStoreUrl] = useState('');

  const modalContext = [
    {
      step: 1,
      title: "Delete FIDO1.0 Authenticator",
      context: "Use the TouchEn OnePass Free Trial app to scan the QR code. Delete your authenticator.",
      tabs: true,
      button: "Delete completed!"
    },
    {
      step: 2,
      title: "Delete FIDO1.0 Authenticator",
      context: "FIDO1.0 Delete has been completed successfully.",
      tabs: false,
      button: "OK"
    }
  ];

  const getSvcTrId = () => {
    var timestamp = (new Date().getTime() % (60 * 60 * 1000)).toString();
    for (var i = timestamp.length; i < 20; i++) {
      timestamp = timestamp + Math.floor(Math.random() * 10);
    }
    return timestamp;
  }

  const [tdata, setTdata] = useState(null);

  useEffect(() => {
    setCurrentStep(1);
    setIsFirstStep(true);
    const sessionMember = JSON.parse(sessionStorage.getItem("member"));
    if(sessionMember) {
      setTdata({
        "command": "requestServiceRelease",
        "bizReqType": "server",
        "svcTrId": getSvcTrId(),
        "siteId": sessionMember.siteId,
        "svcId": sessionMember.svcId,
        "loginId": sessionMember.userId,
        "deviceId": param.deviceId,
        "verifyType": AAID[param.aaid]
      })
    }
    }, [show, sessionStorage]);

  useEffect(() => {
    if (tdata) {
        deleteFidoItem();
    }
  }, [tdata])

  useEffect(() => {
    // 타이머 작동 로직
    let timerId;
    if (clock !== null && clock > 0) {
      setIsQrExpire(false);
      timerId = setInterval(() => {
        setClock((prevClock) => prevClock - 1);
      }, 1000);
    } else if (clock === 0) {
      clearTimeout();
      // qr이미지가 사라지도록 처리 
      setIsQrExpire(true);
    }

    // 컴포넌트 언마운트 시 타이머 해제
    return () => clearInterval(timerId);
  }, [clock]);

  const deleteFidoItem = () => {
    fetch(`${API.UAF}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tdata),
    })
      .then(response=> {
        if(!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if(data.resultCode === "000") {

          if(isMobile) {
            callbackForApp(data.resultData.qrImageUrl);
          } else {
            const qrImgStream = data.resultData.qrImage;
            if (qrImgStream) {
              setQrImg('data:image/png;base64,' + qrImgStream);
            }

            const expiretime = data.resultData.expireTime;
            if(expiretime){
              setClock(expiretime);
            }
          }
        } else {
          openSubmitAlert("FAIL", "[" + data.resultCode + "] " + data.resultMsg);
          close();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        openSubmitAlert("FAIL", `error! ${error}`);
      });
  };

  function callbackForApp(qrImgStream) {
    var token = qrImgStream.split('?');
    var enc = token[1].split('=');
    var qrData = hexToByteString(enc[1]);
    console.log(qrData);
    const encodeQrString = btoa(qrData);

    setClock(1000 * 10);
  
    switch (getOsType()) {
      case OS_VERSION.OS_ANDROID:
        console.log("android");
        setAppStoreUrl("intent://digitaltrust?data=" + encodeQrString + "#Intent;scheme=onepassfido;package=com.dtn.onepassfido.trial;end");
        break;
  
      case OS_VERSION.OS_IOS:
        setAppStoreUrl("onepassfido://digitaltrust/data?" + encodeQrString);
        break;
  
      default:
    }
  }

  // 타이머 시간 형식 변환 함수
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds
      }`;
  };

  const completeAuth = () => {
    fetch(`${API.UAF}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        command : "trResultConfirm",
        svcTrId: tdata.svcTrId,
        protocolFamily: "uaf"
      })
    })
      .then(response=> {
        if(!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if(data.resultCode === "000"){
          const trStatus = data.resultData.trStatus;

          if (trStatus === "1"){
            setIsFirstStep(false);
            setCurrentStep(currentStep + 1);
          } else {
            openSubmitAlert("FAIL", "["+data.resultCode+"] Authentication has not been completed.");
          }
        } else {
          openSubmitAlert("FAIL", "["+data.resultCode+"] " + data.resultMsg);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        openSubmitAlert("FAIL", `error! ${error}`);
      });
  };


  const modalClose = () => {
    close();
    window.location.reload();
  }

  return (
    <div id="DialogFidoDelete" className={`modal ${show ? "open" : ""}`}>
      {modalContext.map( (modal) =>
      modal.step === currentStep && (
        <div className="modal-dialog" key={modal.step}>
          <button className="gnb gnb-close pc-only" onClick={close}>
            <img src={GnbClose} alt="닫기" />
          </button>

          <button className="gnb gnb-close mo-only" onClick={close}>
            <img src={GnbCloseWhite} alt="닫기" />
          </button>

          <div className="modal-dialog-inner">
            <div className="modal-header">
              <h5>{modal.title}</h5>
            </div>

            <span className="line"></span>

            {isFirstStep ? (
              <div className="modal-body">
                <div className="img-item">
                  <img
                    src={isMobile ? ImgIconAdd : ImgIconQR}
                    alt="스텝이미지01"
                    className="step-img"
                  />
                </div>

                  <div className="text-group">
                    <p>{modal.context}</p>
                  </div>
                  
                  {!isMobile ? (
                    <div className="img-item">
                      <div className="qr-img">
                      {qrImg && <img
                          id="qrImg"
                          src={qrImg}
                          style={{ filter: isQrExpire ? 'blur(4px)' : 'none' }}
                        />}
                      </div>
                    </div>
                  ) : (
                    <p className="open-the-app" onClick={() => window.open(appStoreUrl)}>Open the App</p>
                  )}
                  

                  {isQrExpire ? (
                    <span className="color-error">Authenticate timeout. Please try again.</span>
                  ) : (
                    <span className="timer color-error">{formatTime(clock)}</span>
                  )}
              </div>
            ) : (
              <div className="modal-body">
                <div className="img-item">
                  <img
                    src={ImgIconCheck}
                    alt="스텝이미지05"
                    className="step-img"
                  />
                </div>
                <div className="text-group">
                  <p>{modal.context}</p>
                </div>
              </div>
            )}
           
           {isFirstStep ? (
            <div className="modal-footer">
                <div className="button-group">
                  <button disabled={isQrExpire} className="btn-lg btn-primary-fill" onClick={completeAuth}>
                    {modal.button}
                  </button>
                </div>
              </div>
           ) : (
             <div className="modal-footer">
             <div className="button-group">
               <button className="btn-lg btn-primary-fill" onClick={modalClose}>
                 {modal.button}
               </button>
             </div>
           </div>
           )}
          </div>
        </div>
      )
      )}
    </div>
  );
};
