import React, { useEffect, useState } from "react";

import { ModalAddPasskeyAuth } from "../components/ModalAddPasskeyAuth";
import { ModalAddFidoAuth } from "../components/ModalAddFidoAuth";
import { ModalFidoDelete } from "../components/ModalFidoDelete";
import { ModalDefaultAlert } from "../components/ModalDefaultAlert";
import { StoreArea } from "../components/StoreArea";
import { OnepassFido2 } from "../js/OnepassFido2"

import IconDelete from "../assets/images/svg/icon-delete.svg";
import IconPlus from "../assets/images/svg/icon-plus.svg";
import ImgIconUser from "../assets/images/svg/img-icon-user.svg";
import ImgIconAndroid from "../assets/images/svg/img-icon-android.svg";
import ImgIconWarning from "../assets/images/svg/img-icon-warning.svg";
import ImgIconPin from "../assets/images/svg/img-icon-pin.svg";
import ImgIconBio from "../assets/images/svg/img-icon-bio.svg";
import ImgIconPattern from "../assets/images/svg/img-icon-pattern.svg";
import { OnepassFido2Util } from "../js/OnepassFido2Util"
import { AAID } from "../config";
import { BASE_URL , API} from "../config";

export const Authenticators = ({ isMobile }) => {
  const [isShowAddPasskeyAuth, setIsShowAddPasskeyAuth] = useState(false);
  const [isShowAddFidoAuth, setIsShowAddFidoAuth] = useState(false);
  const [isShowDeleteFidoAuth, setIsShowDeleteFidoAuth] = useState(false);
  const [deleteParam, setDeleteParam] = useState("");
  const [addParam, setAddParam] = useState("");
  const [sessionMember, setSessionMember] = useState(null);
  const [deviceId, setDeviceId] = useState('');
  const [alertState, setAlertState] = useState({
    isOpen: false,
    title: '',
    content: ''
  });
  var initAaidList = [
    {
      id: 1,
      thumb: ImgIconBio,
      userName: "Biometrics",
      verificationType: AAID.Biometrics,
      button: IconPlus,
    },
    {
      id: 2,
      thumb: ImgIconPin,
      userName: "PIN",
      verificationType: AAID.PIN,
      button: IconPlus,
    },
    {
      id: 3,
      thumb: ImgIconPattern,
      userName: "Pattern",
      verificationType: AAID.Pattern,
      button: IconPlus,
    }
  ];
  const handleOpenModalDefaultAlert = (title, content) => {
    setAlertState({
      isOpen: true,
      title: title,
      content: content
    });
  };
  const [modalTabs, setModalTabs] = useState([
    {
      name: "Passkey",
      active: true,
      isNoAuth:false,
      authList:[],
      list: [
        {
          id: 1,
          thumb: ImgIconUser,
          userName: "",
          button: IconDelete,
        }
      ],
      button: "Add new Authenticator",
    },
    {
      name: "FIDO1.0",
      active: false,
      isNoAuth:false,
      authList: [
      {
        authImg: ImgIconUser,
        name: ""
      }
    ],
    list: initAaidList,
    button: "Add new Authenticator",
  }]);

  const setSession= () => {
    const member = JSON.parse(sessionStorage.getItem("member"));
    setSessionMember(member)
  }

  useEffect(() => {
    setSession()
  }, [])
    

  useEffect(() => {
    if(sessionMember){
    async function fetchData() {
      fetch(`${API.UAF}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          command : "checkRegisteredStatus2",
          siteId: sessionMember.siteId,
          svcId: sessionMember.svcId,
          loginId: sessionMember.userId,
          protocolFamily: "all",
          rpId: OnepassFido2Util.getRpId(document.domain),
          version: "1"
        })
      })
      .then(response=> {
        if(!response.ok) {
          handleOpenModalDefaultAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setAuthenticator(data);
      })
      .catch(error => {
        console.error('Error Fetching data : ', error);
        handleOpenModalDefaultAlert("FAIL", `error! ${error}`);
      });
    }

    fetchData();
  }
  }, [sessionMember]);

  const setAuthenticator = (data) => {
    let existUaf = false;
    let existFido2 = false;
    let uafObject = {};
    let fido2Object = {};
    console.log(data);
    // TODO : Backend에서 넘어오는 에러코드는 상수로 정리 필요
    if(data.resultCode === '000' && data.resultData.regYn === "Y"){
        console.log(data);
        for(let i = 0; i < data.resultData.registeredProtocolFamily.length; i++){
          let protocol = data.resultData.registeredProtocolFamily[i];
          if (protocol === 'uaf'){
            existUaf = true;
            uafObject = data.resultData.registeredList;
            //setUafList([...uafList, data.resultData.registeredList]);
          } else if(protocol === 'fido2'){
            existFido2 = true;
            fido2Object = data.resultData.fido2RegisteredList;
          }
        }

        if(existUaf && existFido2) {
          settingAuthenticators(uafObject, fido2Object);
        } else if(existUaf && !existFido2) {
          settingAuthenticators(uafObject, null);
        } else if(!existUaf && existFido2) {
          settingAuthenticators(null, fido2Object);
        } else {
          settingNoAuthenticator(true, true);
          console.error("do not exist uaf/fido2");
        }
    } else {
      settingNoAuthenticator(true, true);
      console.error("do not exist uaf/fido2");
    }
  }

  // Fido2, Uaf 인증장치 없을 때 NO Authenticator 표시
  const settingNoAuthenticator = (isFido2NoAuth, isUafNoAuth) => {
    const updatedTabsChange = modalTabs.map((tabs) => {
      if (tabs.name === "Passkey") {
        return { ...tabs, isNoAuth: isFido2NoAuth };
      } else {
        return { ...tabs, isNoAuth: isUafNoAuth };
      }
    });

    setModalTabs(updatedTabsChange);
  }

  const settingAuthenticators = (uafObject, fido2Object) => {
    const updatedModalTabs = [...modalTabs];
    // uaf list setting
    if(uafObject != null) {
      uafObject.forEach(uafItem => {
        if(uafItem.deviceList.length > 0){
          const device = uafItem.deviceList[0];
          setDeviceId(device.deviceId);

          Array.from(initAaidList).forEach(item => {
            for(var i=0; i<device.aaidList.length; i++){
              const aaid = device.aaidList[i];
              if(aaid.verificationType === item.verificationType){
                item.button = IconDelete;
              }
            }
          })

          const updatedAuthList = [{
            name:device.deviceId,
            authImg:ImgIconUser
          }]

        // modalTabs 배열의 두 번째 요소를 복제(clone)하여 업데이트된 authList를 적용합니다.
        updatedModalTabs[1] = {
          ...updatedModalTabs[1],
          authList: updatedAuthList,
          list:initAaidList
        };
      }});
    } else {
      updatedModalTabs[1] = {
        ...updatedModalTabs[1],
        isNoAuth: true,
      };
    }

    // fido2 list setting
    if(fido2Object != null) {
        if(fido2Object.aaguidList.length > 0){

          const updateAaidList = [];
          for(var i=0; i<fido2Object.aaguidList.length; i++){
            const aaguidObj = fido2Object.aaguidList[i];
            
            const updatedList = {
              userName: aaguidObj.credentialAlias,
              thumb: ImgIconUser,
              button: IconDelete
            };
            updateAaidList.push(updatedList);
          }

        // modalTabs 배열의 첫 번째 요소를 복제(clone)하여 업데이트된 authList를 적용합니다.
        updatedModalTabs[0] = {
          ...updatedModalTabs[0],
          authList:[],
          list: updateAaidList
        }
      }
    } else {
      updatedModalTabs[0] = {
        ...updatedModalTabs[0],
        isNoAuth: true,
      };
    }
    // 업데이트된 modalTabs 배열을 적용합니다.
    setModalTabs(updatedModalTabs);
  }

  const setAuthListMap = modalTabs.map((authList) => {
      return { ...authList, active: true };
  });

  
  const getSvcTrId = () => {
    var timestamp = (new Date().getTime() % (60 * 60 * 1000)).toString();
    for (var i = timestamp.length; i < 20; i++) {
      timestamp = timestamp + Math.floor(Math.random() * 10);
    }
    return timestamp;
  }

  const deleteAuthItem = (tabName, name) => {
    if(tabName === "Passkey"){
      deletePasskeyItem();
    } else {
      // 삭제할 aaid를 모달로 넘기기 위함
      setDeleteParam({deviceId:deviceId, aaid:name});
      // fido 인증장치 삭제 모달 열기
      setIsShowDeleteFidoAuth(true);
    }
  }

  const addAuthItem = (name) => {
    setAddParam({deviceId:deviceId, aaid:name});
    setIsShowAddFidoAuth(true);
  }

  async function deletePasskeyItem() {
    if(sessionMember){
      let reqParams = {};
      reqParams.op = "dereg";

      reqParams.siteId = sessionMember.siteId;
      reqParams.svcId = sessionMember.svcId;

      reqParams.userNm = sessionMember.userId;

      let result = OnepassFido2.requestService(reqParams, authCallBackFunction);
    }
  }

  function authCallBackFunction(result) {
    if ("1200" == result.statusCode) {
      handleOpenModalDefaultAlert("SUCCESS", "Passkey delete success");
      window.location.reload();
    } else {
      handleOpenModalDefaultAlert("FAIL", "[" + result.statusCode + "] \n\n" + result.description);
    }
  }



  const handleTabs = (name) => {
    const updatedTabsChange = modalTabs.map((btn) => {
      if (btn.name === name) {
        return { ...btn, active: true };
      } else {
        return { ...btn, active: false };
      }
    });

    setModalTabs(updatedTabsChange);
  };
  const showAddAuthModal = (name) => {
      if (name === "Passkey") {
        setIsShowAddPasskeyAuth(true);
      } else {
        setIsShowAddFidoAuth(true);
      }
  };

  const showAddPasskeyAuthModal = () => {
    setIsShowAddPasskeyAuth(true);
  };
  const closeAddPasskeyAuthModal = () => {
    setIsShowAddPasskeyAuth(false);
  };
  const showAddFidoAuthModal = (type) => {
    if (type === "add") {
      setIsShowAddFidoAuth(true);
    }
  };
  const closeAddFidoAuthModal = () => {
    setIsShowAddFidoAuth(false);
    setAddParam('');
  };
  const closeDeleteFidoAuthModal = () => {
    setIsShowDeleteFidoAuth(false);
  };


  return (
    <main>
      <div id="Authenticators">
        <div className="container">
          <div className="title">
            <h4>{sessionStorage.userId}’s Authenticators</h4>
          </div>

          <div className="item-group">
            <div className="tabs">
              <div className="button-group">
                {modalTabs.map((btn, idx) => (
                  <div
                    className={`tab-item ${btn.active ? "active" : ""}`}
                    onClick={() => handleTabs(btn.name)}
                    key={idx}
                  >
                    {btn.name}
                  </div>
                ))}
              </div>
            </div>

            {modalTabs.map(
              (tabs, idx) =>
                tabs.active && (
                  <div className="content tab-content open-flex" key={idx}>
                    {tabs.isNoAuth && (
                      <div className="empty-list">
                        <div className="warning-icon">
                          <img src={ImgIconWarning} alt="느낌표 아이콘" />
                        </div>

                        <p>No Authenticator</p>
                      </div>
                    )}

                    {tabs.authList && !tabs.isNoAuth && (
                      <div className="list-group">
                        {tabs.authList.map((item, idx) => (
                          <div className="auth-list" key={idx}>
                            <img
                              className="thumb-item"
                              src={item.authImg}
                              alt=""
                            />

                            <div className="text-item">
                              <p>{deviceId}</p>
                            </div>
                          </div>
                        ))}
                        </div>
                      )}

                      {!tabs.isNoAuth && (
                      <div className="list-group">
                        {tabs.list.map((list) => (
                          <div className="item-list">
                            <img
                              className="thumb-item"
                              src={list.thumb}
                              alt="리스트 썸네일"
                            />

                            <p>{list.userName}</p>
                            
                            {list.button === IconDelete ? (
                              <i className="icon-delete"
                                onClick={() => deleteAuthItem(tabs.name, list.userName)}>
                                <img src={list.button} alt="삭제" />
                              </i>
                            ) : (
                              <i className="icon-add"
                                onClick={() => addAuthItem(list.userName)}>
                                <img src={list.button} alt="삭제" />
                              </i>
                            )}
                          </div>
                        ))}
                      </div>
                      )}

                    {tabs.button && (
                      <div className="button-group">
                        <button
                          className="btn-lg btn-primary-fill"
                          onClick={() => showAddAuthModal(tabs.name)}
                        >
                          {tabs.button}
                        </button>
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>

        <StoreArea 
          openSubmitAlert={handleOpenModalDefaultAlert}
          isMobile={isMobile}/>

        {isShowAddPasskeyAuth && ( 
          <ModalAddPasskeyAuth
            show={isShowAddPasskeyAuth}
            close={closeAddPasskeyAuthModal}
            openSubmitAlert={handleOpenModalDefaultAlert}
          />
        )}
        {isShowAddFidoAuth && ( 
          <ModalAddFidoAuth
            show={isShowAddFidoAuth}
            close={closeAddFidoAuthModal}
            param={addParam}
            isMobile={isMobile}
            openSubmitAlert={handleOpenModalDefaultAlert}
          />
        )}
        {isShowDeleteFidoAuth && (
          <ModalFidoDelete
            show={isShowDeleteFidoAuth}
            close={closeDeleteFidoAuthModal}
            param={deleteParam}
            isMobile={isMobile}
            openSubmitAlert={handleOpenModalDefaultAlert}/>
        )}
        <ModalDefaultAlert
        show={alertState.isOpen}
        close={() => setAlertState({...alertState, isOpen:false})}
        title={alertState.title}
        content={alertState.content}
      />
      </div>
    </main>
  );
};
