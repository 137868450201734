import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";
import ImgIconCheck from "../assets/images/popup/img-icon-check.svg";
import {OS_VERSION} from "../config";
import {hexToByteString, getOsType} from "../js/util"
import { BASE_URL, API } from "../config";

export const ModalFidoAuth = ({ show, close, data, isMobile, openSubmitAlert }) => {
  var OS_ANDROID = 3;
  var OS_IOS = 4;

  const navigate = useNavigate();

  const [qrImg, setQrImg] = useState('');
  const [clock, setClock] = useState(null);
  let [currentStep, setCurrentStep] = useState(1);
  const [isQrExpire, setIsQrExpire] = useState(false);
  const [appStoreUrl, setAppStoreUrl] = useState('');

  const modalContext = [
    {
      step: 1,
      title: "FIDO1.0 Authenticate",
      context: "Scan the QR code to authenticate with FIDO1.0.",
      isShowQr: true,
      button: "Authentication complete"
    },
    {
      step: 2,
      title: "FIDO1.0 Authenticate",
      context: "FIDO1.0 authentication has been completed successfully.",
      isShowQr: false,
      button: "LOGIN"
    }
  ];

  const getSvcTrId = () => {
    var timestamp = (new Date().getTime() % (60 * 60 * 1000)).toString();
    for (var i = timestamp.length; i < 20; i++) {
      timestamp = timestamp + Math.floor(Math.random() * 10);
    }
    return timestamp;
  }

  const [tdata, setTdata] = useState(null);

  useEffect(() => {
    if (show) {
      setCurrentStep(1);
      setTdata({
        "command": "requestServiceAuth",
        "bizReqType": "server",
        "svcTrId": getSvcTrId(),
        "siteId": data.siteId,
        "svcId": data.svcId,
        "loginId": data.userId,
        "verifyType": "1023"
      })
    }
  }, [show, data]);

  useEffect(() => {
    if (tdata) {
      register();
    }
  }, [tdata])

  useEffect(() => {
    // 타이머 작동 로직
    let timerId;
    if (clock !== null && clock > 0) {
      setIsQrExpire(false);
      timerId = setInterval(() => {
        setClock((prevClock) => prevClock - 1);
      }, 1000);
    } else if (clock === 0) {
      clearTimeout();
      // qr이미지가 사라지도록 처리 
      //setQrImg('');
      setIsQrExpire(true);
    }

    // 컴포넌트 언마운트 시 타이머 해제
    return () => clearInterval(timerId);
  }, [clock]);

  const register = () => {
    fetch(`${API.UAF}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tdata),
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if(data.resultCode === "000") {

          if(isMobile) {
            callbackForApp(data.resultData.qrImageUrl);
          } else {
            const qrImgStream = data.resultData.qrImage;
            if (qrImgStream) {
              setQrImg('data:image/png;base64,' + qrImgStream);
            }

            const expiretime = data.resultData.expireTime;
            if (expiretime) {
              setClock(expiretime);
            }
          }
        } else {
          openSubmitAlert("FAIL", "[" + data.resultCode + "] " + data.resultMsg);
          close();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        openSubmitAlert("FAIL", `error! ${error}`);
      });
  };

  function callbackForApp(qrImgStream) {
    var token = qrImgStream.split('?');
    var enc = token[1].split('=');
    var qrData = hexToByteString(enc[1]);
    const encodeQrString = btoa(qrData);

    setClock(1000 * 10);
  
    switch (getOsType()) {
      case OS_VERSION.OS_ANDROID:
        setAppStoreUrl("intent://digitaltrust?data=" + encodeQrString + "#Intent;scheme=onepassfido;package=com.dtn.onepassfido.trial;end");
        break;
  
      case OS_VERSION.OS_IOS:
        setAppStoreUrl("onepassfido://digitaltrust/data?" + encodeQrString);
        break;
  
      default:
    }
  }

  // 타이머 시간 형식 변환 함수
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds
      }`;
  };

  const completeAuth = () => {
    fetch(`${API.UAF}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        command: "trResultConfirm",
        svcTrId: tdata.svcTrId,
        protocolFamily: "uaf"
      })
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if(data.resultCode === "000"){
          const trStatus = data.resultData.trStatus;

          if (trStatus === "1") {
            setCurrentStep(currentStep + 1);
          } else if (trStatus !== "1"){
            openSubmitAlert("FAIL", "["+data.resultCode+"] Authentication has not been completed.");
          }
        } else {
          openSubmitAlert("FAIL", "["+data.resultCode+"] " + data.resultMsg);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        openSubmitAlert("FAIL", `error! ${error}`);
      });
  };


  const login = () => {
    let member = { "siteId": data.siteId, "svcId": data.svcId, "userId": data.userId };
    sessionStorage.setItem("member", JSON.stringify(member));
    sessionStorage.setItem("userId", data.userId);

    close();
    navigate("/authenticators");
  }

  return (
    <div id="DialogFidoAuth" className={`modal ${show ? "open" : ""}`}>
      {modalContext.map((modal) =>
        modal.step === currentStep && (
          <div className="modal-dialog" key={modal.step}>
            <button className="gnb gnb-close pc-only" onClick={close}>
              <img src={GnbClose} alt="닫기" />
            </button>

            <button className="gnb gnb-close mo-only" onClick={close}>
              <img src={GnbCloseWhite} alt="닫기" />
            </button>

            <div className="modal-dialog-inner">
              <div className="modal-header">
                <h5>{modal.title}</h5>
              </div>

              <span className="line"></span>

              {modal.isShowQr ? (
                  <div className="modal-body">
                    <div className="text-group">
                      <p>{modal.context}</p>
                    </div>
                    {!isMobile ? (
                      <div className="img-item">
                        <div className="qr-img">
                        {qrImg && <img
                            id="qrImg"
                            src={qrImg}
                            style={{ filter: isQrExpire ? 'blur(4px)' : 'none' }}
                          />}
                        </div>
                      </div>
                    ) : (
                      <p className="open-the-app" onClick={() => window.open(appStoreUrl)}>Open the App</p>
                    )}
                    {isQrExpire ? (
                          <span className="color-error">Authentication timeout. Please try again.</span>
                        ) : (
                        <span className="timer color-error">{formatTime(clock)}</span>
                      )}
                  </div>
              ) : (
                  <div className="modal-body">
                    <div className="img-item">
                      <img
                        src={ImgIconCheck}
                        alt="스텝이미지05"
                        className="step-img"
                      />
                    </div>
                    <div className="text-group">
                      <p>{modal.context}</p>
                    </div>
                  </div>
              )}
              {modal.isShowQr ? (
                <div className="modal-footer">
                <div className="button-group">
                  <button disabled={isQrExpire} className="btn-lg btn-primary-fill" onClick={completeAuth}>
                    {modal.button}
                  </button>
                </div>
                </div>
              ) : (
                <div className="modal-footer">
                    <div className="button-group">
                      <button className="btn-lg btn-primary-fill" onClick={login}>
                        {modal.button}
                      </button>
                    </div>
                  </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};
