import React, { createElement, useState, useEffect } from "react";

import GnbClose from "../assets/images/svg/gnb-close.svg";
import GnbCloseWhite from "../assets/images/svg/gnb-close-w.svg";
import ImgIconQR from "../assets/images/popup/img-icon-qr.svg";
import ImgIconEmail from "../assets/images/popup/img-icon-email.svg";
import ImgIconLock from "../assets/images/popup/img-icon-lock.svg";
import IconCheck from "../assets/images/svg/icon-check.svg";
import ArrowRightWhite from "../assets/images/svg/arrow-right-w.svg";
import { Link } from "react-router-dom";
import $ from "jquery";
import { DEMO_SITE_ID, DEMO_SVC_ID, OS_VERSION } from "../config";
import { BASE_URL, API } from "../config";
import { hexToByteString, getOsType } from "../js/util"


export const ModalFidoSign = ({ show, close, isMobile, openSubmitAlert }) => {

  let [currentStep, setCurrentStep] = useState(1);

  // 이메일 검증 로직 
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [otpValid, setOtpValid] = useState(false);
  const [notAllow, setNotAllow] = useState(true);
  const [isQrShow, setIsQrShow] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [svcTrId, setSvcTrId] = useState(''); // 등록 svcTrId 값 생성
  const [svcTrId2, setSvcTrId2] = useState(''); //인증 svcTrId 값 생성
  const [qrImg, setQrImg] = useState('');
  const [qrImg2, setQrImg2] = useState('');
  const [appStoreUrl, setAppStoreUrl] = useState('');
  const [clock, setClock] = useState(null);
  const [expired, setExpired] = useState(false);

  const handleNext = () => {
    if (currentStep === 1) {
      setCurrentStep(currentStep + 1);
      setIsComplete(false); // Passkey demo 재시도 시 Success 모달창 노출을 위한 필요
    } else if (currentStep === 2) {
      callStep2API(); // step 2 : 메일 전송
    } else if (currentStep === 3) {
      callStep3API(); // step 3: otp 검증 
    } else if (currentStep === 4) {
      completeRegister(); // step 4 : trResultConfirm
      setSvcTrId2(getSvcTrId()); // 인증을 위한 svcTrId 생성 
    } else if (currentStep === 5) {
      completeAuthenticate(); // step 4: 인증 API 호출 
    }
  }

  useEffect(() => {
    if(currentStep === 3) {
      setOtpValid(false);
    } else if (currentStep === 4) {
      transJSON(); // step 4: 등록 API 호출 
    } else if (currentStep === 5) {
      transJSON2(); // step 4: 인증 API 호출 

    }
    console.log(`step before : ${currentStep}`);
  }, [currentStep]);

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
    setEmail('');
    setOtp('');
    setEmailValid(false);

    // step이 5가 되면 인증을 위한 svcTrId 재성정 
    if (currentStep === 5) {
      setSvcTrId(getSvcTrId());
    }
  };

  const closeModal = () => {
    setCurrentStep(1);
    setEmail('');
    setOtp('');
    setEmailValid(false);
    close();
  };

  useEffect(() => {
    if (isComplete) {
      $("#DialogSuccess").addClass("open");
    } else {
      $("#DialogSuccess").removeClass("open");
    }
  }, [isComplete]);

  useEffect(() => {
    setNotAllow(true);
    setSvcTrId(getSvcTrId());
  }, [show]);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const regex =
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (regex.test(e.target.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };
  // OTP 검증로직 
  const handleOtp = (e) => {
    setOtp(e.target.value);
    const regex = /^\d{6}$/;
    if (regex.test(e.target.value)) {
      setOtpValid(true);
    } else {
      setOtpValid(false);
    }
  };

  // 인증장치 타이머 
  useEffect(() => {
    if (currentStep === 3) {
      setClock(300); // 5 Minutes
    }
  }, [currentStep]);

  useEffect(() => {
    // 타이머 작동 로직
    let timerId;
    if (clock !== null && clock > 0) {
      timerId = setInterval(() => {
        setClock((prevClock) => prevClock - 1);
      }, 1000); 
      setExpired(false);
    } else if (clock === 0) {
      clearInterval(timerId);
      setQrImg('');
      setQrImg2('');
      setExpired(true);
    }
    return () => clearInterval(timerId);
  }, [clock]);

  // 타이머 시간 형식 변환 함수
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  const getSvcTrId = () => {
    var timestamp = (new Date().getTime() % (60 * 60 * 1000)).toString();
    for (var i = timestamp.length; i < 20; i++) {
      timestamp = timestamp + Math.floor(Math.random() * 10);
    }
    return timestamp;
  }
  // QR 이미지 사라졌을 경우 disabled
  useEffect(() => {
    setIsQrShow(!qrImg);
  }, [qrImg]);

  useEffect(() => {
    setIsQrShow(!qrImg2);
  }, [qrImg2]);

  // STEP 2 : 이메일 전송 
  const callStep2API = async () => {
    fetch(`${API.DEMO_REQUEST}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email })
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        console.log(response);
        return response.json();
      })
      .then(data => {
        console.log(data);
        if (data.resultCode === "000") {
          setRequestId(data.resultData.reqId);
          setCurrentStep(currentStep + 1);
        } else if(data.resultCode === "200"){
          openSubmitAlert("EMAIL SEND FAIL", "[" + data.resultCode + "] " + data.resultMsg);
        } else if(data.resultCode === "201"){
          openSubmitAlert("CONNECTED_TIMED_OUT", "[" + data.resultCode + "] " + data.resultMsg);
        } else if(data.resultCode === "202"){
          openSubmitAlert("MAIL_SERVER_CONNECTION_FAILED", "[" + data.resultCode + "] " + data.resultMsg);
        } else {
          console.log(data);
          openSubmitAlert("FAIL", "[" + data.resultCode + "] " + data.resultMsg);
        }
      })
      .catch((error) => {
        console.error('Error Fetching data: ', error);
        openSubmitAlert("FAIL", `Error Fetching data: ${error}`);
      });
  }

  // STEP 3 : OTP 검증
  const callStep3API = async () => {
    fetch(BASE_URL + '/freetrial/demo/confirm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ otp: otp, id: requestId })
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        console.log(response);
        return response.json();
      })
      .then(data => {
        console.log(data);
        if (data.resultCode === "000") {
          setCurrentStep(currentStep + 1);
        } else {
          openSubmitAlert("FAIL", "[" + data.resultCode + "] Verification of the OTP you entered failed.");
        }
      })
      .catch((error) => {
        console.error('Error Fetching data : ', error);
        openSubmitAlert("FAIL", `Error Fetching data : ${error}`);
      });
  };
  // UAF 인증장치 등록 데이터 
  const tdata = {
    "command": "requestServiceRegist",
    "bizReqType": "server",
    "svcTrId": svcTrId,
    "siteId": DEMO_SITE_ID,
    "svcId": DEMO_SVC_ID,
    "loginId": email,
    "verifyType": "1023"
  }
  const transJSON = () => {
    fetch(`${API.UAF}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tdata),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.resultCode === "113") {
          openSubmitAlert("FAIL", "This is transaction information that already exists. Please close the modal and try again.");
        } else if (data.resultCode === "000") {
          if (isMobile) {
            callbackForApp(data.resultData.qrImageUrl);
            setQrImg(data.resultData.qrImage);
            // qr데이터로 바로 앱실행
            window.open(appStoreUrl);
          } else {
            const qrImgStream = data.resultData.qrImage;

            if (qrImgStream) {
              setQrImg('data:image/png;base64,' + qrImgStream);
              const updatedContext = [...modalContext];
              const step4Index = updatedContext.findIndex(step => step.step === 4);
              if (step4Index !== -1) {
                updatedContext[step4Index].qr = 'data:image/png;base64,' + qrImgStream;
              }
            }

            const expiretime = data.resultData.expireTime;
            if (expiretime) {
              setClock(expiretime);
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function callbackForApp(qrImgStream) {
    var token = qrImgStream.split('?');
    var enc = token[1].split('=');
    var qrData = hexToByteString(enc[1]);
    const encodeQrString = btoa(qrData);

    setClock(300);

    switch (getOsType()) {
      case OS_VERSION.OS_ANDROID:
        setAppStoreUrl("intent://digitaltrust?data=" + encodeQrString + "#Intent;scheme=onepassfido;package=com.dtn.onepassfido.trial;end");
        break;

      case OS_VERSION.OS_IOS:
        setAppStoreUrl("onepassfido://digitaltrust/data?" + encodeQrString);
        break;

      default:
    }
  }

  // trResultConfirm register
  const completeRegister = async () => {
    fetch(`${API.UAF}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        command: "trResultConfirm",
        svcTrId: tdata.svcTrId,
        protocolFamily: "uaf"
      })
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const trStatus = data.resultData.trStatus;

        if (trStatus !== "1") {
          openSubmitAlert("FAIL", "[" + data.resultCode + "] Authentication has not been completed.");
        }
        else if (data.resultCode === "000") {
          setCurrentStep(currentStep + 1);
        }
      })
      .catch((error) => {
        console.error('Error : ', error);
        openSubmitAlert("FAIL", `Error : ${error}`);
      });
  };
  // trResultConfirm Authenticate
  const completeAuthenticate = async () => {
    fetch(`${API.UAF}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        command: "trResultConfirm",
        svcTrId: tdata2.svcTrId,
        protocolFamily: "uaf"
      })
    })
      .then(response => {
        if (!response.ok) {
          openSubmitAlert("FAIL", `HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const trStatus = data.resultData.trStatus;

        if (trStatus === "1") {
          console.log(trStatus);
          setIsComplete(true);
          closeModal();
        } else if(trStatus === "3") {
          openSubmitAlert("FAIL", "Wait for transaction.");
        }
      })
      .catch((error) => {
        console.error('Error : ', error);
        openSubmitAlert("FAIL", `Error : ${error}`);
      });
  };

  // FIDO 인증장치 서비스 인증 요청 
  const tdata2 = {
    "command": "requestServiceAuth",
    "bizReqType": "server",
    "svcTrId": svcTrId2,
    "siteId": DEMO_SITE_ID,
    "svcId": DEMO_SVC_ID,
    "loginId": email,
    "verifyType": "1023"
  }

  const transJSON2 = () => {
    fetch(`${API.UAF}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tdata2),
    })
      .then((response) => response.json())

      .then((data) => {
        console.log(data);
        if (data.resultCode === "000") {
          if (isMobile) {
            callbackForApp(data.resultData.qrImageUrl);
            setQrImg2(data.resultData.qrImage);

            // qr데이터로 바로 앱실행
            window.open(appStoreUrl);
          } else {
            const qrImgStream = data.resultData.qrImage;

            const expiretime = data.resultData.expireTime;
            if (expiretime) {
              setClock(expiretime);
            }
            if (qrImgStream) {
              setQrImg2('data:image/png;base64,' + qrImgStream);
              const updatedContext = [...modalContext];
              const step5Index = updatedContext.findIndex(step => step.step === 5);
              if (step5Index !== -1) {
                updatedContext[step5Index].qr = 'data:image/png;base64,' + qrImgStream;
              }
            }
          }
        } else {
          openSubmitAlert("FAIL", "[" + data.resultCode + "] " + data.resultMsg);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  const modalStepIndicator = [
    {
      step: 1,
      active: true,
      success: false,
    },
    {
      step: 2,
      active: false,
      success: false,
    },
    {
      step: 3,
      active: false,
      success: false,
    },
    {
      step: 4,
      active: false,
      success: false,
    },
    {
      step: 5,
      active: false,
      success: false,
    },
  ];

  const modalContext = [
    {
      step: 1,
      context01: "Download the App",
      context02: "Download the TouchEn OnePass Free Trial app.",
      img: ImgIconQR,
      tabs: true,
      button: "Continue",
    },
    {
      step: 2,
      context01: "Get Email authentication code",
      context02: "Enter an Email address to get the authentication code.",
      img: ImgIconEmail,
      input: createElement("input", {
        type: "email",
        placeholder: "Enter your email address",
        value: email,
        onChange: handleEmail

      }),
      errorMessage: "Invalid Email address",
      button: "Enter the code",
      link: "Re-register Authenticator",
    },
    {
      step: 3,
      context01: "Enter the authentication code",
      context02: "We sent the code to your email",
      userEmail: email,
      img: ImgIconLock,
      input: createElement("input", {
        type: "number",
        placeholder: "Enter the 6-digit code",
        name: "otp",
        value: otp,
        onChange: handleOtp
      }),
      errorMessage: "Invalid authentication code",
      timer: formatTime(clock),
      button: "Authenticate",
      link: "Re-enter Email",
    },
    {
      step: 4,
      context01: "Add new Authenticator",
      context02:
        "Use the TouchEn OnePass Free Trial app to scan the QR code. Add your authenticator.",
      img: ImgIconQR,
      timer: formatTime(clock),
      qr: qrImg,
      button: "Authenticate with the App",
      link: "Re-enter Authenticate code",
    },
    {
      step: 5,
      context01: "Authenticate",
      context02:
        "Use the TouchEn OnePass Free Trial app to scan the QR code. Proceed with authentication",
      img: ImgIconQR,
      timer: formatTime(clock),
      qr: qrImg2,
      button: "Get the Result",
      link: "Add Authenticator to the App",
    },
  ];

  const [modalTabs, setModalTabs] = useState([
    {
      name: "Google Play",
      active: true,
      qr: "/img/qr/QR-android.jpg",
    },
    {
      name: "App Store",
      active: false,
      qr: "/img/qr/QR-iOS.jpeg",
    },
  ]);

  const handleTabs = (name) => {
    const updatedTabsChange = modalTabs.map((btn) => {
      if (btn.name === name) {
        return { ...btn, active: true };
      } else {
        return { ...btn, active: false };
      }
    });

    setModalTabs(updatedTabsChange);
  };

  const handleMobileStoreButton = (name) => {
    if (name === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.dtn.onepassfido.trial&pcampaignid=web_share');
    } else {
      window.open('https://apps.apple.com/us/app/onepassfido/id6499198391');
    }
  }

  return (
    <div id="DialogFidoSign" className={`modal ${show ? "open" : ""}`}>
      {modalContext.map(
        (modal) =>
          modal.step === currentStep && (
            <div className="modal-dialog" key={modal.step}>
              <button className="gnb gnb-close pc-only" onClick={closeModal}>
                <img src={GnbClose} alt="닫기" />
              </button>

              <button className="gnb gnb-close mo-only" onClick={closeModal}>
                <img src={GnbCloseWhite} alt="닫기" />
              </button>
              <div className="modal-dialog-inner">

                <div className="modal-header">
                  <h5>TouchEn OnePass FIDO1.0</h5>
                </div>

                <span className="line"></span>

                <div className="modal-body">
                  <div className="steps">
                    <ul>
                      {modalStepIndicator.map((step) => (
                        // 스탭이 넘어갈때 className 변경
                        <li
                          className={`
                              ${modal.step > step.step ? "success" : ""}
                              ${modal.step === step.step ? "active" : ""}
                            `}
                          key={step.step}
                        >
                          {modal.step > step.step ? (
                            // 스탭이 지나가면 IconCheck, 스텝이 맞으면 숫자
                            <i>
                              <img src={IconCheck} alt="" />
                            </i>
                          ) : (
                            `0${step.step}`
                          )}
                        </li>
                      ))}
                    </ul>

                  </div>

                  <div className="img-item">
                    <img
                      src={modal.img}
                      alt="스텝이미지01"
                      className="step-img"
                    />
                  </div>

                  <div className="text-group">
                    <p>{modal.context01}</p>
                    <span>{modal.context02}</span>
                  </div>

                  {isMobile && modal.tabs ? (
                    <div className="button-group">
                      <button className="btn-lg btn-primary-line" onClick={() => handleMobileStoreButton('android')}>
                        Google Play
                      </button>
                      <button className="btn-lg btn-primary-line" onClick={() => handleMobileStoreButton('ios')}>
                        Apple Store
                      </button>
                    </div>
                  ) : !isMobile && modal.tabs ? (
                    <>
                      <div className="tabs">
                        <div className="button-group">
                          {modalTabs.map((btn, idx) => (
                            <div
                              className={`tab-item ${btn.active ? "active" : ""
                                }`}
                              onClick={() => handleTabs(btn.name)}
                              key={idx}
                            >
                              {btn.name}
                            </div>
                          ))}
                        </div>
                      </div>

                      {modalTabs.map((tabs, idx) => (
                        <>
                          {tabs.active && (
                            <div className="qr-img" key={idx}>
                              <img src={tabs.qr} alt="qr 이미지" />
                            </div>
                          )}
                        </>
                      ))}
                    </>
                  ) : null}

                  {modal.userEmail && (
                    <p className="user-email">{modal.userEmail}</p>
                  )}

                  {modal.input && (
                    <div className="input-group">
                      <div className="input-item error">
                        <div className="input">
                          {modal.input}

                          <span className="focus-border"></span>

                          {modal.timer && (
                            <span className="timer color-error">
                              {modal.timer}
                            </span>
                          )}
                        </div>
                        {!emailValid && email.length > 0 && (
                          <span className="error-message">
                            {modal.errorMessage}
                          </span>
                        )}
                        {!otpValid && otp.length > 0 && (
                          <span className="error-message">
                            {modal.errorMessage}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {isMobile && modal.qr ? (
                    <p className="open-the-app" onClick={() => window.open(appStoreUrl)}>Open the App</p>
                  ) : !isMobile && modal.qr ? (
                    <div className="qr-img">
                      <img
                        src={modal.qr}
                        alt="qr 이미지"
                      />
                    </div>
                  ) : null}

                  {!modal.input && modal.timer ? (
                    <span className="timer color-error">{modal.timer}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="modal-footer">
                  <div className="button-group flex-col">
                    {modal.button && (
                      <button
                        className="btn-lg btn-primary-fill btn-icon"
                        onClick={handleNext}
                        disabled={(currentStep === 2 && !emailValid)||(currentStep === 3 && !otpValid)||(currentStep === 3 && expired)||(currentStep === 4 && isQrShow)||(currentStep === 5 && isQrShow)}
                      >
                        {modal.button}
                        <i>
                          <img src={ArrowRightWhite} alt="ArrowRightWhite" />
                        </i>
                      </button>
                    )}
                    {modal.link && <Link onClick={handlePrev}>{modal.link}</Link>}
                  </div>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};
